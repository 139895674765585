import React, { useState } from 'react'
import { profileimages, RedDownloadIcon } from "../Kalyanlite/assets/images";
import { useDispatch } from 'react-redux';
import { saveCustomerResponse } from '../../Store/KalyanLite/Action';
import { USER_PDF } from '../../Helpers/EndPoints';
import config from '../../config';
// import { configPost } from '../../Helpers/api_helpers';
import * as Images from "../Kalyanlite/assets/images" 
import axios from 'axios';
import Constants from '../../Constants/constants';
import EndPoints from '../../Constants/EndPoints';
import NotificationReceivedSound from "../../sounds/notification-received.mp3";

const DownLoadPdf = ({ profileData, pdfData }) => {

  const [selectedId, setSelectedId] = useState({label : null, key : null});
  const [submitStatus, setSubmitStatus] = useState(false);
  const dispatch = useDispatch()
  const [isDownloadPdf, setIsDownloadPdf] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const audio = new Audio(NotificationReceivedSound);

  const feeadBackButtons = [
    {
      id : 1,
      key : "Accepted",
      label : "👍 Yes, Proceed",
      defaultClass : "flex items-center justify-center w-full py-2 text-[#4E4E4E] text-[14px] px-5",
      activeClass : "bg-[#039511] !text-[#ffffff] flex items-center justify-center w-full py-2 text-[14px]"
    },
    {
      id : 2,
      key : "Rejected",
      label : "👎 Could be Better",
      defaultClass : "flex items-center justify-center w-full py-2 text-[#4E4E4E] text-[14px] px-5",
      activeClass : "bg-[#D10A11] !text-[#ffffff] flex items-center justify-center w-full py-2 text-[14px]"
    },
    {
      id : 3,
      key : "Pending",
      label : "✋ Hold",
      defaultClass : "flex items-center justify-center w-full py-2 text-[#4E4E4E] text-[14px] px-5",
      activeClass : "bg-[#FF6600] !text-[#ffffff] flex items-center justify-center w-full py-2 text-[14px]"
    }
  ]


  const handleSubmitResponse = ()=>{
    audio?.play();
    setIsSubmitting(true)
    const request = {
      "response": selectedId?.key,
      "logId":profileData?.logId,
      // "kp_profileId":pdfData?.pdfKpProfileId,
      "kp_profileId":profileData?.key == "to_kp_customer" ? (pdfData?.pdfKey == "to_prospect customer" ? pdfData?.pdfSourceProfileId : pdfData?.pdfKpProfileId) : profileData?.profileId,
      "source_profileId":profileData?.key == "to_kp_customer" ? profileData?.profileId : (pdfData?.pdfKey == "to_prospect customer" ? pdfData?.pdfSourceProfileId : pdfData?.pdfKpProfileId),
      "key":profileData?.key,
      "admUserId":profileData?.agentId
    }
  dispatch(saveCustomerResponse({
    request,
    onSuccess : (res)=>{
      if(res?.data?.data?.statusCode == 200){
        setIsSubmitting(true)
        setSubmitStatus(true)
      }
    }
  }))
  }

  const handlePdfDownload = async () => {
    setIsDownloadPdf(true)
      try {
          const response = await axios.post(
          `${config.api.chat_api}${EndPoints.userPdfUrl()}`,
          {
                  // "user_id": 1216200,
                  "user_id": profileData?.profileUserId,
                  "view_mode": "mobile"
              }, {
                  responseType: 'blob',
              });
            

            if (response.status === 200) {
              setIsDownloadPdf(false)
                // Create a blob URL and set filename
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const contentDisposition = response.headers['content-disposition'];
                const filename = profileData?.profileName; // Customize the filename for each user

                // Create a download link and trigger the download
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${filename || 'file'}.pdf`);
                document.body.appendChild(link);
                link.click();

                // Clean up
                link.remove();
                window.URL.revokeObjectURL(url);

            }
    } catch (error) {
          console.error('Download error:', error);
      } finally {
          // setIsDownloading(false);
      }
    }
  const profilePic = profileData?.profileImage?.includes("femaleavatar") ? Images.WomanIcon : profileData?.profileImage?.includes("maleavatar") ? Images.ManIcon : config.image_base_url+profileData?.profileImage

  const isAvatar = profileData?.profileImage?.includes("femaleavatar") || profileData?.profileImage?.includes("maleavatar") ? true :  false

  return (
    <div className='mb-6 w-full mx-auto'>
    <div className="p-6 space-y-4 lg:space-y-6 bg-[#FFFFFF] shadow-[0px_0px_8px_#00000029] rounded-md">
    <div className="flex items-center gap-5">
      <img src={profilePic} className={`w-[4rem] h-[4rem] lg:w-[3.7rem] lg:h-[3.7rem] rounded-full ${isAvatar ? "border border-[#8292A8]" : ""}`} alt="profile img" />
      <div className="">
        <p className='text-[#4C5B71] text-[16px]'>{profileData?.profileName}</p>
        <p className='text-[#8292A8] text-[13px]'>
        {profileData?.profileAge?.split("years")[0]}{"yrs"},{" "}
        {profileData?.profileHeight.replace(/(\d+)ft (\d)in/, (_, ft, inch) => `${ft}'${inch}''`)},{" "}
        {profileData?.profileCaste},{" "}
        {profileData?.profileEducation},
        </p>
        <p className='text-[#8292A8] text-[13px]'>
        {profileData?.profileOccupation},{" "}
        {profileData?.profileLivesIn?.split(",")[0]?.trim()}
        </p>
      </div>
    </div>
    <button 
    onClick={handlePdfDownload} 
    disabled={isDownloadPdf}
    className={`w-max mx-auto px-3 py-1 flex items-center justify-center gap-2 border border-[#D10A11] rounded-2xl ${isDownloadPdf ? "cursor-not-allowed" : "cursor-pointer"}`}
    >
      <img src={RedDownloadIcon} alt="download icon" className='w-5 h-5' />
      <p className='text-[14px] text-[#D10A11]'>{isDownloadPdf ? "Downloading..." : "Download PDF"}</p>
    </button>
    {!submitStatus && profileData?.profileResponse == null &&
    <>
    <div className="shadow-[0px_0px_5px_#0B57D09C]">
      {feeadBackButtons?.map((btn, i)=>(
        <>
        <button
        key={btn.id}
        onClick={() =>  {
          audio?.play();
          setSelectedId({label:btn.label, key:btn.key})
        }}
        className={`relative ${btn.defaultClass} ${selectedId?.key === btn.key ? btn.activeClass : ""} ${i!=2 && "border-b border-[#0B57D0]"}`}
       >
        {btn.label}
        {/* {i!=2 &&
        <span className="absolute bottom-0 left-1/2 w-[90%] border-b border-[#0B57D0] -translate-x-1/2"></span>
        } */}
       </button>
        </>
      ))}
    </div>
    {selectedId?.key !== null &&
    <button onClick={()=>{
      handleSubmitResponse()
      }} 
      className={`bg-[#0B57D0E5] text-[#ffffff] flex items-center justify-center w-full py-2 text-[14px] rounded-md ${isSubmitting ? "cursor-not-allowed" : "cursor-pointer"}`}>
        {isSubmitting ? "submitting" : "Submit"}
      </button>
      }
      </>
    }
    </div>
    {(submitStatus || profileData?.profileResponse !== null) && (
    <p className='bg-[#2468D5] text-white text-center py-1 text-[12px] xl:text-[14px]'>
        Thanks for your feedback! Your feedback:{" "}
        {profileData?.profileResponse !== null 
            ? profileData?.profileResponse === "Accepted" 
                ? "👍 Yes, Proceed" 
                : profileData?.profileResponse === "Rejected" 
                    ? "👎 Could be Better" 
                    : profileData?.profileResponse === "Pending" 
                        ? "✋ Hold" 
                        : "" 
            : selectedId?.label}
          </p>
    )}

   </div>
  )
}

export default DownLoadPdf
