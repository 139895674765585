import React, { useContext, useEffect, useRef, useState } from 'react'
import { useAnalytics } from '../../../Hooks/usePageChange';
import { NavBarContext } from '../../../Contexts/NavBarContext';
import { useTranslation } from 'react-i18next';
import Constants, { EditProfileMenu, EventName } from '../../../Constants/constants';
import { RouteHelper } from '../../../Routes/RouteSegment';
import ImageCropper from '../../Edit Profile/ImageCropper';
import LoaderGif from "../../../Gif/loader.gif";
import { CommonDangerPopup, CommonSuccessPopup, NotificationPopup } from '../../CommonPopup';
import config from '../../../config';
import EndPoints from '../../../Constants/EndPoints';
import { LoginContext } from '../../../Contexts/LoginContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { getDeviceInfo, getGroupAuthId } from '../utils';
import axios from 'axios';
import { useSelector } from 'react-redux';
import * as Images from "../../../Components/Kalyanlite/assets/images"
import CameraPopup from '../Common/CameraPopup';
import { validateImage } from '../../../Utils';
import { imageSizeReducer } from '../../ImageSizeReducer';
import Loader from '../../Loader';
import Compressor from "compressorjs";
import whatsappIcon from "../assets/Images/whatsappIcon.svg";
import CloseIcon from "../../../Images/Group 761@2x.png";
import { POST } from '../../../Services/api_services';

const ClcAddPhotoPopup = (props) => {
    const closePopupRef = useRef();
    const { onClickTrack } = useAnalytics();
    const { setMenuSelect ,successPopup, setSuccessPopup ,photopopupOpen, setPhotoPopupOpen} = useContext(NavBarContext);
    const { t:trans } = useTranslation();
  
    const isViewProfile = props?.buttonName == "WhoViewedMyprofile";
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  
    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
  
      window.addEventListener('resize', handleResize);
  
      // Cleanup the event listener on component unmount
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  



  
    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
  
      window.addEventListener('resize', handleResize);
  
      // Cleanup the event listener on component unmount
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  
  
    let textArray = props?.buttonName == "WhoViewedMyprofile" && props?.message ? props?.message?.split(".") : "";
  
    const highlightext = textArray != "" ? textArray[0] + "." : "";
  
    const remaintext = textArray != "" ? textArray[1] + "." + textArray[2] : "";
  
    const [successMessage, setsuccessMessage] = useState("");
  
  
    const [showCrop, setShowCrop] = useState(false);
    const [currentimage, setCurrentImage] = useState();
    const [originalImage, setOriginalImage] = useState();
    const [showSuccessPopup, setshowSuccessPopup] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const { loginDetail, logout } = useContext(LoginContext);
    const [notification, setNotification] = useState({
      show: false,
      title: "",
      message: "",
    });
    const navigate = useNavigate();
    const [isUploading, setIsUploading] = useState(false);
    const [compressing, setCompressing] = useState(false);
    const [photoId, setPhotoId] = useState();
    const location = useLocation();
    const [showCameraPopup, setShowCameraPopup] = useState(false);
  console.log(isUploading,compressing,"isUploading");
  
  
    useEffect(() => {
      console.log(props, "contentResponseData");
    }, [showCrop, currentimage, originalImage]);
  
    const showNotification = ({ description = "", title = "Status!" }) => {
      setNotification({ show: true, title: title, message: description });
    };
  
    const userData = useSelector(
      (state) => state?.DashBoardAPI?.ProfileData?.data
    );


const [editMyProfielData,setEditMyProfielData]  = useState([])

    const apiCallEditProfile = async () => {
      let request = {
        email: loginDetail()[0],
        userId: loginDetail()[1],
        loginFrom: localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
      };
    
  
  
  
      let { statusCode, data } = await POST(
        `${config.api.API_URL}${EndPoints.editMyProfileUrl()}`,
        request
      );
  
      if (statusCode === 200) {
        setEditMyProfielData(data?.data);
       
      } else if (statusCode === 401) {
        logout();
      }
    };

    useEffect(()=>{
      apiCallEditProfile()
    },[])
  
    const handleCrop = (file, type) => {
      console.log("handle Crop", file);
      validateImage({
        file,
        onSizeError: (img) => {
          showNotification({
            title: "Invalid image",
            description: "Height and Width must be more than 100px",
          });
        },
        onExtensionError: (fileType) => {
          showNotification({
            title: "Invalid image",
            description: "Invalid extension!",
          });
        },
        onFileSizeError: (img) => {
          showNotification({
            title: "Invalid image",
            description: "Image size should not be greater than 10mb",
          });
        },
        onSuccess: (img, readerStr) => {
          // handleOriginalImageUpload(file);
          imageSizeReducer(file, originalImageUpload);
          setCompressing(true);
          // handleImageSizeCompress(file,originalImageUpload,setCompressing)
          setCurrentImage(readerStr || "");
         
  
  
        },
      });
    };
    const cropedImage = (cropedImg) => {
      // handleUpload(originalImage, cropedImg);
      imageSizeReducer(cropedImg, handleUpload);
      setShowCrop(false);
      
    };
  
    const handleOriginalImageUpload = async (file) => {
     
      const image = file;
      new Compressor(image, {
        quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          // compressedResult has the compressed file.
          // Use the compressed file to upload the images to your server.
          // setCompressedFile(res)
          console.log(compressedResult, "compressedResult");
          originalImageUpload(compressedResult);
  
        },
      });
    };
  
    console.log(userData?.popup[0]?.notificationId,'location?.state?.data?.notificationId');
    
    const NotificationId =   userData?.popup[0]?.notificationId ? userData?.popup[0]?.notificationId : location?.state?.notificationId 

    console.log(NotificationId,"NotificationIdmmnn");

    const originalImageUpload = async (compressedImageUrl) => {
      console.log(compressedImageUrl, "compressedImageUr");
      let notificationId;
      setOriginalImage(compressedImageUrl);
      let fileData = new FormData();
      fileData.append("email", loginDetail()[0]);
      fileData.append("userId", loginDetail()[1]);
      fileData.append("photoUploadFrom", getDeviceInfo());
      fileData.append("uploadfrom", "Gallery");
      fileData.append("original", compressedImageUrl);
      fileData.append("isContent", props?.Contentname ? props?.Contentname : "");
      fileData.append("contentId", props?.contentId ? props?.contentId : "");
      fileData.append("requestfulfilledvia", props?.isFromSms == "yes" ? "sms" : props?.isFrom == "chat" ? "chat" : "myprofile");
      fileData.append("photorequesttypeid", 85);
      fileData.append("requestfulfilledfrom", getDeviceInfo());
      fileData.append("recieverprofileid", getGroupAuthId());
  
     
      notificationId = sessionStorage.getItem(
        Constants.loginLocalStorageKeys.notificationId
      )
        fileData.append("notificationId", notificationId ? notificationId : props?.KMNotificationId ? props?.KMNotificationId: NotificationId);
      
   
      setIsUploading(true);
      let data = await axios.post(
        `${config.api.API_URL}${EndPoints.profileOriginalImageUploadUrl()}`,
        fileData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        }
      );
  
      setIsUploading(false);
      console.log(data, "datadatadatadata");
      if (data.data.statusCode === 200) {
        setCompressing(false);
        if (data.data.data.status === "Success") {
          setPhotoId(data.data.data.photoId);
          if (props?.isFrom == 'TrailPack') {
            props.postData();
          }
          setShowCrop(true);
        } else {
          props.closePopup();
        }
      } else {
        props.closePopup();
      }
    };
  const [showphoto,setShoPhoto] = useState(false)
    
  
    const handleUpload = async (file, cropedFile) => {

      //console.log("file.size", file.size);
      if (file.size / 1024 / 1024 < 10) {
        let notificationId = null;
        const loginUrl = sessionStorage.getItem(
          Constants.loginLocalStorageKeys.loginUrl
        );
  
        if (loginUrl?.includes("photo") || props?.isFromInitialPopup || props?.from || props.PrivacyOptionTitle) {
          notificationId = sessionStorage.getItem(
            Constants.loginLocalStorageKeys.notificationId
          );
          // sessionStorage.removeItem(
          //   Constants.loginLocalStorageKeys.notificationId
          // );
        }

        
        const NotificationId =   userData?.popup[0]?.notificationId ? userData?.popup[0]?.notificationId : 
        location?.state?.notificationId  ? location?.state?.notificationId :location?.state?.data?.notificationId 


        console.log(NotificationId,"NotificationIdmm");


        let fileData = new FormData();
        fileData.append("email", loginDetail()[0]);
        fileData.append("userId", loginDetail()[1]);
        fileData.append("photoUploadFrom", getDeviceInfo());
        fileData.append("uploadfrom", "Gallery");
        fileData.append("photoId", photoId);
        fileData.append("croped", file);
        // fileData.append("notificationId", location?.state?.data?.notificationId);


        notificationId = sessionStorage.getItem(
          Constants.loginLocalStorageKeys.notificationId
        )
        fileData.append("notificationId", notificationId ? notificationId : props?.KMNotificationId ? props?.KMNotificationId: NotificationId);

        fileData.append("requestfulfilledvia", props?.isFromSms == "yes" ? "sms" : props?.isFrom == "chat" ? "chat" : "myprofile");
        fileData.append("photorequesttypeid", 85);
        fileData.append("requestfulfilledfrom", getDeviceInfo());
        fileData.append("recieverprofileid", getGroupAuthId());
  
        setIsUploading(true);
        let data = await axios.post(
          `${config.api.API_URL}${EndPoints.croppedPhotoUploadUrl()}`,
          fileData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
  
        setIsUploading(false);
        if (data.status === 200) {
          if (data.data.data.status === "SUCCESS") {   
            
            if(props?.isFrom=="photopreview"){
              props?.setUploadPhotoSuccessPopup(true)
              props?.closePopup()
            }
          
           
            if(props?.title == trans('clcprivacy_popup.add_photo_with_privacy_options')) {
              props.setshowUploadSuccessPopup(true);
            }else{
              setshowSuccessPopup(true)
            }
  
            props?.onPhotoPopupClose(true , "PhotoPreview");
           
            if (props.isFrom == "contentcard") {
              props?.setUploadPhotoSuccessPopup(true)
              props?.closePopup()
              props?.callBackReload();
            }   
            setShoPhoto(false)
            // props?.closePopup()
            // props?.callBack();
            
          } else {
            props.closePopup();
          }
        } else {
          alert("Please upload file less than 10 mb");
          props.closePopup();
        }
      } else {
        alert("Please upload file less than 10 mb");
        props.closePopup();
      }
    };
  
  
  
    useEffect(() => { }, [props?.menu]);
  
    const closeNotification = () => {
      setNotification({ show: false, message: "", title: "" });
      if (props.isFrom == 'TrailPack') {
        return;
      } else {
        props.close();
      }
    };
  
    const captureImage = () => {
  
      navigator.permissions.query({ name: 'push' }).then(function (permissionStatus) {
        if (permissionStatus.state === "granted") {
          setShowCameraPopup(true)
        } else if (permissionStatus.state === "denied") {
          showWarningMessage("Please grant camera permission for capture image from your webcam")
        } else {
          navigator.mediaDevices.getUserMedia({ audio: true })
            .then(function (stream) {
              setShowCameraPopup(true)
            })
            .catch(function (err) {
              showWarningMessage("Please grant camera permission for capture image from your webcam")
            });
        }
      })
  
      navigator.permissions.query({ name: 'camera' }).then(function (permissionStatus) {
        if (permissionStatus.state === "granted") {
          setShowCameraPopup(true)
        } else if (permissionStatus.state === "denied") {
          showWarningMessage("Please grant camera permission for capture image from your webcam")
        } else {
          navigator.mediaDevices.getUserMedia({ audio: true })
            .then(function (stream) {
              setShowCameraPopup(true)
            })
            .catch(function (err) {
              showWarningMessage("Please grant camera permission for capture image from your webcam")
            });
        }
      })
  }
  
    const [dangerPopupData, setdangerPopupData] = useState({ show: false, content: "" })
  
    const showWarningMessage = (message) => {
      setdangerPopupData({ show: true, content: message })
    }
  
    const closeWarning = () => {
      setdangerPopupData({ show: false, content: "" })
    }
  
    const onImageConfirm = async (img) => {
  
      const response = await fetch(img);
  
      if (response.ok) {
        const imageBlob = await response.blob();
        const file = new File([imageBlob], 'image.jpg', { type: 'image/jpeg' });
        const compressedFile = await compressImage(file);
        handleUpload(compressedFile,"horoscope","via_camera")
      }
  
      setShowCameraPopup(false);
    };
  
    const compressImage = (file) => {
      console.log("compressImage", file);
  
      return new Promise((resolve, reject) => {
        new Compressor(file, {
          quality: 0.8,
          maxWidth: 800,
          mimeType: 'image/jpeg',
          success(result) {
            var tempFile = new File([result], file.name);
            resolve(tempFile);
          },
          error(err) {
            reject(err);
          },
        });
      });
    };
  
    return (
    <>
  
        {showCameraPopup && 
        <CameraPopup 
            onImageConfirm={onImageConfirm} 
            close={() => {
                setShowCameraPopup(false);
                onClickTrack(EventName.chatCameraButtonCancelClick);
            }} 
            track={() => {}}
            from="horoscope" 
            />
        }
  
        {dangerPopupData.show &&
        <CommonDangerPopup
            close={closeWarning}
            content={dangerPopupData.content}
        />
        }
  
        {showSuccessPopup && (
        <CommonSuccessPopup
            close={() => {
            setshowSuccessPopup(false);
            }}
            title={"Success!"}
            content={"Image upload success"}
        />
        )}
      
        {notification.show && (
            <NotificationPopup
              close={closeNotification}
              title={notification.title}
              content={notification.message}
            />
        )}

        {showCrop && (
        
        <ImageCropper
            onCropClick={cropedImage}
            image={currentimage}
            onClose={(isCropped) => {
            if (isCropped) {
                setShowCrop(false);
            } else {
                props?.closePopup();
                // setShowCrop(false)
            }

            if (props?.callBackReload) {
                props?.callBackReload();
            }
            }}
        />
        )}
  
         
  
     
        <div className={`flex justify-center items-center md:items-center overflow-x-hidden  fixed inset-0 z-50 outline-none focus:outline-none scrollbar-none ${props?.title == 'Add Photo with Privacy Options' && windowWidth < 500 ? 'overflow-y-hidden ' : 'overflow-y-auto bg-opacityblack '}`}>
            <div
            className={`relative  modal-container md:my-2 mx-6 max-h-[95vh] overflow-y-auto overflow-x-hidden scrollbar-none
                
                ${props?.title == 'Add Photo with Privacy Options' && windowWidth < 500 ? 'w-full lg:w-[40vw] md:w-[70vw] pt-[5rem]'
                : isViewProfile ? 'xl:w-[23vw] 2xl:w-[23vw] lg:w-[30vw] md:w-[42vw] w-[71vw]' : 'w-full lg:w-[45vw] md:w-[70vw]'}`}
            ref={closePopupRef}
            >
                <div className={`border-0 shadow-lg relative mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none mt-[0.5rem] rounded-3xl`}>
                    <div className="">
                        <button
                        className=" bg-transparent z-40 border-0 text-black  absolute top-[-0.1rem] right-0 md:left-0"
                        onClick={() => {
                            props?.closePopup();
                            props?.close()
        
                        }}
                        >
                        <img className="h-6 w-6 " src={CloseIcon} alt="" />
                        </button>
                    </div>
                    <div className={`px-4 pb-4 sm:p-6 sm:pb-5 rounded-2xl relative py-8 flex-auto pt-5`}>
                        <div className={` justify-center mb-2  items-center `}>
                            <div className="text-[#D10A11] flex border-b-2 border-dashed border-[#D10A11] justify-center items-center text-center font-bold text-[16px] md:text-[18px] lg:text-[21px] pb-4">
                                <h3>{props?.title}</h3>
                            </div>

                            <p className="text-[#000000] font-semibold text-[13px] md:text-[16px] lg:text-[13px] xl:text-[16px] mt-2 text-center px-3 md:px-8">
                              {props?.subcontent ? props?.subcontent :"Here's how your profile looks without a photo. Adding a photo to your profile increases your chances of getting more responses."}
                            </p>
                        </div>

                        <div>
                            <p className='py-3 mt-2 text-[#D10A11] font-bold text-[13px] md:text-[16px] px-3 md:px-8'>Your Profile among others:</p>
                            <div className='relative w-full flex items-center justify-center mb-[2rem]'>
                                <img src={Images.ClcAddPhotoImg} className=' w-[70%] md:w-[45%] lg:w-[48%] xl:w-[32%] ' alt='ClcAddPhotoImg' />

                                

                      <div class="absolute bg-white px-4  w-[93%] max-w-[93%] md:max-w-[60%] xl:w-[42%] xl:max-w-[60%] lg:max-w-[70%] lg:w-[93%]  border border-gray-200 rounded-lg shadow-sm  dark:bg-gray-800 dark:border-gray-700">

                      <div class="flow-root">
                          <ul role="list" class="divide-y divide-gray-200 dark:divide-gray-700">
                              <li class="py-1">
                                  <div class="flex items-center gap-3">
                                      <div class="shrink-0">

                                          <img class="w-8 h-8 rounded-full" src={editMyProfielData?.profilepic} alt="avatar image"/>
                                      </div>
                                      <div class="flex-1 min-w-0 ms-4">
                                          <p class="text-[12px] md:text-[13px] font-medium text-gray-900 truncate dark:text-white">
                                             {userData?.username}
                                          </p>
                                          <p className='text-[#8292A8] text-[10px] md:text-[11px] whitespace-pre-line'>
                                                                        {editMyProfielData.age},{" "}
                                                                        {editMyProfielData?.height?.at(0)?.name.replace("ft", "'").replace("in", '"')}, {" "}
                                                                        {editMyProfielData?.caste?.at(0)?.name}{" "},
                                                                    </p>
                                                                    <p className='text-[10px] md:text-[11px] text-[#8292A8] text-[11px]'>
                                                                        {" "}
                                                                        {editMyProfielData?.education?.at(0).name}{" "},
                                                                        {editMyProfielData?.occupation?.at(0).name}{" "},
                                                                        {editMyProfielData?.state?.at(0).name}{" "}

                                                                    </p>
                                          
                                      </div>
                                    
                                  </div>
                              </li>
                              
                              
                              
                          </ul>
                      </div>
                      </div>

                            </div>
                        </div>
        
                            {
                            !showCrop && showLoader ? (
                                <Loader
                                className="loaderTransperancy"
                                loaderClassName="loaderTransperancySpin"
                                />
                            )  : (
                            <>
                        
                            {
                            compressing ? (
                                <div className="p-6 flex-auto flex items-center flex-col  justify-center">
                                <img alt="loader" src={LoaderGif} className={`w-16 h-16`} />
                                <div className="text-[16px] font-semibold my-2">
                                    Uploading...
                                </div>
                                </div>
                            )
                            :
                            isUploading ? (
                            <div className="p-6 flex-auto flex items-center flex-col  justify-center">
                                <img
                                alt="loader"
                                src={LoaderGif}
                                className={`w-16 h-16`}
                                />
                                <div className="text-[16px] font-semibold my-2">
                                Uploading...
                                </div>
                            </div>
                            ) : (
                            
                            <>
                            <div class="border-t border-dashed border-red-500 flex-grow"></div>

                            <p className={`py-3 mt-2 text-[#000000] font-bold text-[13px] md:text-[16px] px-3 md:px-8`}>Upload Photo Via :</p>
                                <div className="w-[80%] xl:w-[70%] 2xl:w-[60%] mx-auto mb-4">
                                <div className="hidden md:grid grid-cols-2 lg:gap-5 md:gap-6 md:mx-[1rem]">
        
                        
                                        <label>
                                        <div
                                        className="flex flex-col space-y-2 items-center justify-center  py-[1rem] md:px-[1rem]  bg-white shadow-md rounded-lg cursor-pointer"
                                        style={{
                                            boxShadow: "0px 0px 5px #0000004F",
                                        }}
                                        onClick={() => {
                                            if (props.isFrom == "sign out") {
                                            setMenuSelect("2");
                                            navigate(
                                                RouteHelper.getEditProfileUrl(
                                                EditProfileMenu[2].urlPath
                                                ),
                                                { state: { tab: "3" } }
                                            );
                                            props?.closePopup();
                                            }
        
                                            onClickTrack(EventName.CLC_Gallery)
                                            props?.onPhotoPopupClose?.(true, "PhotoPreview");
                                        }}
                                        >
                                        <img
                                            src={Images.GallaryIcon}
                                            className="w-[3rem] h-[3rem] object-none"
                                            loading="lazy"
                                            alt=""
                                        />
                                        <div className="flex items-center justify-center bg-white cursor-pointer border border-[#D10A11]  w-full py-1.5 rounded-[14px]">
                                            <p className="text-[#D10A11] font-bold font-Poppins text-[14px] ">
                                            <a className="cursor-pointer text-[10px]" 
                                                >
                                                Add Photos Now!
                                            </a>
        
                                            {props.isFrom === "sign out" ? (
                                                <></>
                                            ) : (
                                                <input
                                                style={{ display: "none" }}
                                                type="file"
                                                onChange={(event) => {
                                                    handleCrop(event.target.files[0]);
                                                }}
                                                accept="image/png, image/jpg, image/jpeg"
                                                data-max-size="10485760"
                                                />
                                            )}
                                            </p>
                                        </div>
                                        </div>
                                        </label>
        
                                        
                                    {/* <SocialMediaUpload
                                    closeModal={() => props.close()}
                                    callBackImage={(e) => props?.callBackReload(e)}
                                    isFrom={'addphotoclc'}
                                    onPhotoPopupClose={props?.onPhotoPopupClose}
                                    /> */}
                                    <div
                                        className="flex flex-col space-y-2 items-center justify-center  py-[1rem] md:px-[1rem] bg-white shadow-md rounded-lg cursor-pointer"
                                        style={{
                                        boxShadow: "0px 0px 5px #0000004F",
                                        }}
                                        onClick={() => {
                                        window.open(
                                            Constants.kalyanSupportWhatsappUrl(
                                            userData?.smProfileId
                                            )
                                        );
                                        onClickTrack(EventName.CLC_Whatsapp)
                                        props?.onPhotoPopupClose(true, "AddWhatsapp");
                                        }}
                                    >
                                        <img
                                        src={whatsappIcon}
                                        className="w-[3rem] h-[3rem] object-cover"
                                        loading="lazy"
                                        alt=""
                                        />
                                        <div className="flex items-center justify-center bg-white cursor-pointer border border-[#4AC958] w-full py-1.5  rounded-[14px]">
                                        <p className="text-[#47C757] font-bold font-Poppins py-0.5 text-[10px]">
                                        Add Photos via WhatsApp!
                                        </p>
                                        </div>
                                    </div>
        
                                    
                                
                                </div>
                                </div>
        
                                <div className="md:hidden  gap-4 grid grid-cols-2 mx-[2rem] mb-2">
        
        
                                
                                    {/* <div className="flex">
                                        <button
                                        onClick={() => { captureImage() }}
                                        className="flex flex-col items-center w-full text-white cursor-pointer">
                                        <img className="w-[2rem] h-[2rem] object-contain" src={Images.cameraimage} alt="" />
                                        <p className="text-[#7A7A7A] font-medium   font-Poppins pt-2 text-[14px]">{trans('my_profile.camera')}</p>
                                        </button>
                                    </div> */}
        
                                    <label>
                                    <div
                                    className="flex flex-col space-y-2 items-center justify-center  cursor-pointer"
                                    
                                    onClick={() => {
                                        onClickTrack(EventName.CLC_Gallery)
                                        props?.onPhotoPopupClose(true, "PhotoPreview");
                                    }}
                                    >
                                    <img
                                        src={Images.GallaryIcon}
                                        className="w-[2rem] h-[2rem] object-contain"
                                        loading="lazy"
                                        alt=""
                                    />
                                    <div className="flex items-center justify-center bg-white cursor-pointer">
                                        <p className="text-[#000000] font-medium font-Poppins text-[10px]">
                                        <a className="text-[#7A7A7A] font-medium font-Poppins text-[14px]" >
                                            Gallery
                                        </a>
        
                                        {props.isFrom === "sign out" ? (
                                            <></>
                                        ) : (
                                            <input
                                            style={{ display: "none" }}
                                            type="file"
                                            onChange={(event) => {
                                                handleCrop(event.target.files[0]);
                                            }}
                                            accept="image/png, image/jpg, image/jpeg"
                                            data-max-size="10485760"
                                            />
                                        )}
                                        </p>
                                    </div>
                                    </div>
                                    </label>
        
        
                                <div
                                className="flex flex-col space-y-2 items-center justify-center  cursor-pointer"
                                
                                onClick={() => {
                                    window.open(
                                    Constants.kalyanSupportWhatsappUrl(
                                        userData?.smProfileId
                                    )
                                    );
                                    onClickTrack(EventName.CLC_Whatsapp)
                                    props?.onPhotoPopupClose(true, "AddWhatsapp");                        }}
                                >
                                <img
                                    src={whatsappIcon}
                                    className="w-[2rem] h-[2rem] object-contain"
                                    loading="lazy"
                                    alt=""
                                />
                                <div className="flex items-center justify-center  bg-white cursor-pointer">
                                    <p className="text-[#7A7A7A] font-medium font-Poppins text-[14px]">
                                    WhatsApp
                                    </p>
                                </div>
                                </div>
        
                                </div>
                                
                                </>
                            
                            )}
                            </>
                        )
                    
                        }              
                    
                    </div>
                </div>
            </div>
        </div>
  
    
    </>
    );
  };

export default ClcAddPhotoPopup
