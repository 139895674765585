import React, { useEffect, useRef, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { configPost } from '../Helpers/api_helpers';
import {USER_PDF} from '../Helpers/EndPoints';
import config from '../config';
import axios from 'axios';
import AllSharedProfiles from '../Components/PdfView/AllSharedProfiles';
import PdfHeader from '../Components/PdfView/PdfHeader';
import { useDispatch } from 'react-redux';
import { isKpCustomer, saveCustomerResponse } from '../Store/KalyanLite/Action';
import { GreenRoundTick, cancellgrayicon } from '../Components/Kalyanlite/assets/images'
import Loader from '../Components/Loader';
import { useLocation } from 'react-router-dom';
import Constants from '../Constants/constants';
import kalyanLogo from '../Images/KM logo@2x.png'
import LoaderGif from '../Gif/loader.gif'
import EndPoints from '../Constants/EndPoints';
import NotificationReceivedSound from "../sounds/notification-received.mp3";


function ProfilePdfView() {
    const [pdfUrl, setPdfUrl] = useState(null);
    const [selected, setSelected] = useState(null);
    const [showSharedProfiles, setShowSharedProfiles] = useState(false);
    const [selectedData, setSelectedData] = useState(null);
    const [submitStatus, setSubmitStatus] = useState(false)
    const [isKPCustomerData, setIsKPCustomerData] = useState()
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const [isAlreadySubmitting, setIsAlreadySubmitting] = useState(false)
    const location = useLocation(); // Get the URL
    const searchParams = new URLSearchParams(location.search);
    const page = searchParams.get("page");
    const [scale, setScale] = useState(1); // Default scale

    const audio = new Audio(NotificationReceivedSound);
    const pdfContainerRef = useRef(null);

    useEffect(() => {
        const handleTouchMove = (e) => {
            if (pdfContainerRef.current && pdfContainerRef.current.contains(e.target)) {
                return; // Allow zooming inside the PDF container
            }
            e.preventDefault(); // Prevent zooming everywhere else
        };

        document.addEventListener("gesturestart", handleTouchMove, { passive: false });
        document.addEventListener("gesturechange", handleTouchMove, { passive: false });

        return () => {
            document.removeEventListener("gesturestart", handleTouchMove);
            document.removeEventListener("gesturechange", handleTouchMove);
        };
    }, []);

    useEffect(() => {
        const updateScale = () => {
            const screenWidth = window.innerWidth;

            if (screenWidth < 768) {
                setScale(0.85); // Small scale for mobile
            } else {
                setScale(0.9); // Medium scale for tablets
            }
        };

        updateScale(); // Set initial scale
        window.addEventListener("resize", updateScale); // Listen for screen resizing

        return () => window.removeEventListener("resize", updateScale);
    }, []);

    useEffect(()=>{
        fetchIsKpCustomer();
        if(page == "profile"){
            setShowSharedProfiles(true)
            setSelectedData("allShared")
        }else{
            fetchPdf();
        }
    },[page])

  // Extract values
    const pdfData = {
        pdfLogId: localStorage.getItem("pdfLogId"),
        pdfKpProfileId: localStorage.getItem("pdfKpProfileId"),
        pdfKey: localStorage.getItem("pdfKey"),
        pdfSourceProfileId: localStorage.getItem("pdfSourceProfileId"),
        pdfAdmUserId: localStorage.getItem("pdfAdmUserId"),
        pdfUserId: localStorage.getItem("pdfUserId"),
        pdfSourceUserId: localStorage.getItem("pdfSourceUserId"),
        pdfKPUserId: localStorage.getItem("pdfKPUserId"),
        pdfAutoCusId: localStorage.getItem("pdfAutoCusId"),
    };

    console.log(page,'local states');

    // useEffect(() => {
    //     fetchPdf();
    //     fetchIsKpCustomer();
    // }, []);

    const fetchPdf = async () => {
        try {
            const response = await axios.post(
                `${config.api.chat_api}${EndPoints.userPdfUrl()}`,
                {
                    user_id: pdfData?.pdfKey == "to_prospect customer" ? pdfData?.pdfKPUserId : pdfData?.pdfSourceUserId,
                    view_mode: "mobile",
                },
                {
                    responseType: 'blob',
                }
            );

            if (response.status === 200) {
                const pdfBlob = new Blob([response.data], { type: "application/pdf" });
                const pdfObjectUrl = URL.createObjectURL(pdfBlob);
                setPdfUrl(pdfObjectUrl);
            }
        } catch (error) {
            console.error('Error fetching PDF:', error);
        }
    };

    const fetchIsKpCustomer = () =>{
        const request = {
            userId : pdfData?.pdfUserId,
            autoCusLogId:pdfData?.pdfAutoCusId,
            key: pdfData?.pdfKey
        }
        dispatch(isKpCustomer(
            {
                request,
                onSuccess : (res)=>{
                    if(res?.data?.statusCode == 200){
                        setIsKPCustomerData(res?.data?.data?.data)
                    }
                }
            }
        ))
    }

    const handleSelect = (option) => {
        setSelected(option);
    };

    const handleSubmit = () => {
        audio.play();
        setIsLoading(true)
        const request = {
            "response": selected == "Yes, Proceed" ? "Accepted" : selected == "Could be better" ? "Rejected" : "Pending",
            "logId":pdfData?.pdfLogId,
            "kp_profileId":pdfData?.pdfKpProfileId,
            "source_profileId":pdfData?.pdfSourceProfileId,
            "key":pdfData?.pdfKey,
            "admUserId":pdfData?.pdfAdmUserId
        }
        dispatch(saveCustomerResponse({
            request,
            onSuccess : (res)=>{
              if(res?.data?.data?.statusCode == 200){
                setIsLoading(false)
                setSubmitStatus(true)
                setSelected(null)
              }
            }
        }))
    };

    // const options = [
    //     { label: "Could be better", color: "#D10A11", borderColor : "#1A1A1A" },
    //     { label: "Hold", color: "#FF6600", borderColor : "#1A1A1A" },
    //     { label: "Yes, Proceed", color: "#039511", borderColor : "#039511" },
    // ];

    const options = [
        { label: "Yes, Proceed", color: "#039511", borderColor : "#039511" },
        { label: "Hold", color: "#FF6600", borderColor : "#1A1A1A" },
        { label: "Could be better", color: "#D10A11", borderColor : "#1A1A1A" },
    ];

    if (!pdfUrl && page != "profile") {
        // return <p>Loading PDF...</p>;
        return (
            <div className='h-[100vh] w-[100%] flex items-center justify-center'>
        <div className='flex flex-col items-center justify-center'>
          <img alt="kalyan" width="200" src={kalyanLogo} />
          <img alt="LoaderGif" width="50" src={LoaderGif} />
        </div>
      </div>
        )
    }

    return (
        (showSharedProfiles ?
        <AllSharedProfiles isKPCustomer={isKPCustomerData?.isKpCustomer} selectedTab = {selectedData} pdfData={pdfData} />
        :
        <div className='lite-scrollbar' style={{ height: "100vh", overflowY: "auto" }}
        >
            <PdfHeader isKPCustomer={isKPCustomerData?.isKpCustomer} setShowSharedProfiles= {setShowSharedProfiles} setSelectedData={setSelectedData} />
            <div className='mx-auto flex items-center justify-center' style={{ width: "fit-content", position: "relative" }}>
                <div
                ref={pdfContainerRef}
                 className='lite-scrollbar !w-full'
                    style={{
                        maxHeight: "calc(100vh - 120px)", // Leave space for the footer
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <Document file={pdfUrl} onLoadError={console.error}>
                        <Page pageNumber={1} scale={scale} />
                    </Document>
                </div>
    
                {
                isKPCustomerData?.response !== null
                ?
                <div className='fixed bottom-0 w-full md:w-[24rem] md:max-w-[90vw] bg-[#2468D5] text-white text-center py-2 text-[12px] xl:text-[14px]'>
                    Thanks for your feedback! Your feedback:{" "}
                    {isKPCustomerData?.response === "Accepted" ? "👍 Yes, Proceed" 
                    :
                    isKPCustomerData?.response === "Rejected" ? "👎 Could be Better" 
                    : 
                    isKPCustomerData?.response === "Pending" ? "✋ Hold" 
                    : 
                    ""}
                </div>
                :
                submitStatus ?
                <div className="fixed bottom-0 left-1/2 transform -translate-x-1/2 w-full md:w-[25rem] md:max-w-[90vw] bg-white py-6 px-4 shadow-[0_-2px_10px_rgba(0,0,0,0.1)] gap-2 z-[1000] space-y-2 rounded-t-[35px]">
                    <img onClick={()=>{
                        setSubmitStatus(false)
                        setIsAlreadySubmitting(true)
                    }} 
                    src={cancellgrayicon} 
                    className='w-2 h-2 md:w-3 md:h-3 ml-auto mr-2 cursor-pointer' 
                    alt="GreenRoundTick" />
                    <div className="flex items-center justify-center gap-2">
                        <img src={GreenRoundTick} className='w-6 h-6 md:w-8 md:h-8' alt="GreenRoundTick" />
                        <p className='max-w-full md:max-w-[20rem] text-[13px] text-[#000000] font-semibold'>
                        Submitted Successfully. Thanks for your feedback!
                        </p>
                    </div>
                </div>
                :
                isAlreadySubmitting ?
                <></>
                :
                <div
                className='w-full md:w-[25rem] md:max-w-[90vw] fixed bottom-0 rounded-t-[35px] px-4 py-5'
                    style={{
                        // position: "fixed",
                        // bottom: "2rem",
                        left: "50%",
                        transform: "translateX(-50%)",
                        backgroundColor: "white",
                        // padding: "15px 37px",
                        boxShadow: "0 -2px 10px rgba(0, 0, 0, 0.1)",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "5px",
                        // borderRadius: "25px",
                        // width: "fit-content",
                        // minWidth: "300px",
                        // maxWidth: "90vw",
                        zIndex: 1000,
                        borderBottomLeftRadius:0,
                        borderBottomRightRadius:0
                    }}
                >
                    {/* First row with three buttons */}
                    <div className='flex gap-3'>
                      {options.map((opt) => (
                            <label
                                key={opt.label}
                                className={`text-[10px] md:text-[12px] lg:text-[12px] px-3 py-2 bg-[${selected === opt.label ? opt.color : "#ffffff"}] text-${selected === opt.label ? "white" : "black"} rounded-[10px] flex items-center cursor-pointer`}
                                style={{ border: `1px solid ${opt?.color}` }}
                                // style={{
                                //     fontSize: "10px",
                                //     padding: "6px 10px",
                                //     borderRadius: "10px",
                                //     border: `1px solid ${opt.color}`,
                                //     cursor: "pointer",
                                //     transition: "all 0.3s ease",
                                //     backgroundColor: selected === opt.label ? opt.color : "white", // White background when selected
                                //     color: selected === opt.label ? "white" : "black", // Text color changes to match button color when selected
                                //     width: "fit-content",
                                //     display: "flex",
                                //     alignItems: "center",
                                // }}
                            >
                                <input
                                    type="radio"
                                    name="options"
                                    value={opt.label}
                                    checked={selected === opt.label}
                                    onChange={() => {
                                        audio.play();
                                        handleSelect(opt.label)
                                    }}
                                    style={{ marginRight: "8px", display: "none" }}
                                />
                                <span
                                    className="flex items-center justify-center rounded-full mr-2"
                                    style={{
                                    width: "14px",
                                    height: "14px",
                                    minWidth: "14px",
                                    borderRadius: "50%",
                                    border: selected === opt.label ? "1px solid white" : `1px solid ${opt.color}`,
                                    position: "relative",
                                    }}
                                >
                                    {/* Inner Dot */}
                                    {selected === opt.label && (
                                    <span
                                        className="absolute bg-white rounded-full"
                                        style={{
                                        width: "7px",
                                        height: "7px",
                                        }}
                                    />
                                    )}
                                </span>
                                {opt.label}
                            </label>
                      ))}
                    </div>
                    
                    {/* Second row with the Submit button */}
                    <div>
                        <button
                            onClick={handleSubmit}
                            style={{
                                fontSize:"11px",
                                marginTop: "5px",
                                padding: "5px 30px",
                                borderRadius: "9px",
                                backgroundColor: "#0B57D0",
                                color: "white",
                                width: "200px",
                                border: "none",
                                cursor: "pointer",
                            }}
                        >
                            {isLoading ? "Submitting..." : "Submit"}
                            
                        </button>
                    </div>
                </div>
                }

            </div>
        </div>
        )         
    );
    
}

export default ProfilePdfView;