import React, { useContext, useState, useEffect, useRef } from "react";
import { MultiSelectView } from "./PartnerPreferenceUtils";

import { LoginContext } from "../../../Contexts/LoginContext";
import EndPoints from "../../../Constants/EndPoints";
import { IP_Address } from "../../../Constants/IpAddress";
import { POST, GET } from "../../../Services/api_services";
import config from "../../../config";
import { useSelector } from "react-redux";
import Constants from "../../../Constants/constants";
import { useTranslation } from "react-i18next";

const ageList = []
for (let i = 18; i <= 75; i++) {
  ageList.push(i);
}

const BasicPeference = (props) => {
  const { loginDetail, logout } = useContext(LoginContext);
  const [stateDropDown, setstateDropDown] = useState([]);
  const [cityDropDown, setcityDropDown] = useState([]);
  const [castDropDown, setCastDropDown] = useState([]);
  const [subCastDropDown, setSubCastDropDown] = useState([]);
  const ageFromValue = ageList;
  const [ageToValue, setAgeToValue] = useState(ageList);
  const initialRef = useRef(true);
  const isChristian = props.data.values.religion?.includes(Constants.christialId)
  const { denominationList } = useSelector((state) => ({
    denominationList: state?.Home?.denominationList?.data?.domain,
  }));
  // useEffect(() => {}, [ageToValue]);

  const { t: trans } = useTranslation();

  console.log(props.data.values,"props.data.values.cast");

  const apiCallState = async (countryID,initial) => {
    let { statusCode, data } = await GET(
      `${config.api.API_URL}${EndPoints.getStateUrl(countryID)}`
    );
    //console.log("dropdown data ", data);
    if (statusCode === 200) {
      // setDropDownData(data.data, type);
      if (data.status == "Success") {
        setstateDropDown(data.data.states);

       if(initial === false){
        const temp = [];
        data?.data?.states?.forEach(state => {
          if(props?.data?.values?.state?.includes(state.id)){
            temp.push(state.id)
          }
        });
        props.data.setFieldValue("state", temp);
        apiCallCity(temp,false);
       }
      }
    } else if (statusCode === 401) {
      logout();
    }
  };

  const apiCallCity = async (stateID,initial) => {
    let { statusCode, data } = await GET(
      `${config.api.API_URL}${EndPoints.getCityUrl(stateID)}`
    );
    //console.log("dropdown data ", data);
    if (statusCode === 200) {
      // setDropDownData(data.data, type);
      if (data.status == "Success") {
        setcityDropDown(data.data.cities);

       if(initial === false){
        const temp = [];
        data?.data?.cities?.forEach(city => {
          if(props?.data?.values?.city?.includes(city.id)){
            temp.push(city.id)
          }
        });
        props.data.setFieldValue("city", temp);
       }
      }
    } else if (statusCode === 401) {
      logout();
    }
  };
  const apiCallCast = async (mothertonge, religion, initial) => {
    // getCastUrl

    let { statusCode, data } = await GET(
      `${config.api.API_URL}${EndPoints.getCastUrl(mothertonge, religion)}`
    );
    //console.log("dropdown data ", data);
    if (statusCode === 200) {
      // setDropDownData(data.data, type);
      if (data.status == "Success") {
        setCastDropDown(data.data.castes);

        if (initial === false) {
          const temp = [];
          data?.data?.castes?.forEach(caste => {
            if (props?.data?.values?.cast?.includes(caste.id)) {
              temp.push(caste.id)
            }
          });
          props.data.setFieldValue("cast", temp);
          apiCallSubCast(temp, false)
        }
      }
    } else if (statusCode === 401) {
      logout();
    }
  };

  const apiCallSubCast = async (id,initial) => { 
    let { statusCode, data } = await GET(
      `${config.api.API_URL}${EndPoints.getSubCastUrl(id)}`
    );
    //console.log("dropdown data ", data);
    if (statusCode === 200) {
      // setDropDownData(data.data, type);
      if (data.status == "Success") {
        setSubCastDropDown(data.data.subcastes);

       if(initial === false){
        const temp = [];
        data?.data?.subcastes?.forEach(caste => {
          if(props?.data?.values?.subcast?.includes(caste.id)){
            temp.push(caste.id)
          }
        });
        props.data.setFieldValue("subcast", temp);
       }
      }
    } else if (statusCode === 401) {
      logout();
    }
  };

  const setAge = (from) => {
    let temp = [];
    for (let i = from; i <= 75; i++) {
      temp.push(i);
    }
    setAgeToValue(temp);
    //console.log("age to value");
  };

  useEffect(() => {
    if(props?.editbasicinfofreshData == null || !initialRef.current) return;
    //console.log('props?.editbasicinfofreshData',props?.editbasicinfofreshData == null);

    initialRef.current = false

    const initialContryIds = props?.editbasicinfofreshData?.country
      .filter((e) => e.selected === "Y")
      .map((e, i) => e.id)
    apiCallState(initialContryIds,true);

    const initialStates = props?.editbasicinfofreshData?.state
    .filter((e) => e.selected === "Y")
    .map((e, i) => e.id)
    apiCallCity(initialStates,true)

    const initialMotherTongue = props?.editbasicinfofreshData?.motherTongue
      ?.filter((e) => e.selected === "Y")
      .map((e, i) => e.id);

    const initialReligion = props?.editbasicinfofreshData?.religion
      ?.filter((e) => e.selected === "Y")
      .map((e, i) => e.id);

    apiCallCast(initialMotherTongue, initialReligion,true);

    const initialCaste = props?.editbasicinfofreshData?.caste
      ?.filter((e) => e.selected === "Y")
      .map((e, i) => e.id);
    apiCallSubCast(initialCaste,true)

  }, [props?.editbasicinfofreshData]);
  

  useEffect(() => {
    setAge(props.data.values.agefrom)
  }, [props.data.values.agefrom]);

const clearCaste = ()=>{
  props.data.setFieldValue("cast", []);
  apiCallSubCast([])
}
  
const isV3User = localStorage.getItem("isV3User")

const OnChangeBottomData =()=>{
  if(props?.isFrom=="basicPE"){
    props?.ShowBottomTab(true)
  }else{
    props?.showPEBottomTab(true)
    props?.ShowBottomTab?.(false)
  }
}

useEffect(() => {
  return () => {
    props?.ShowBottomTab?.(false); // ✅ Reset to false when unmounting
  };
}, []);


// useEffect(()=>{
//   if(props.data.values){
//     props?.apicallsearchpagenewcount(props.data.values)

//   }
// },[props.data.values])

  return (
    <div className={props.tab ? "p-5" :''}>
      <MultiSelectView
        title={trans('my_profile.marital_status')}
        placeholder={trans('my_profile.any_marital_status')}
        inputID={"maritalStatus"}
        inputName={"maritalStatusId"}
        value={props.data.values.maritalStatus}
        defalutValue={[props.data.values.maritalStatus]}
        onChange={(e) => {
          props?.handleSelectInputChange?.(e);
          props?.apicallsearchpagenewcount?.(props.data.values );  
          OnChangeBottomData()
          props.data.setFieldValue("maritalStatus", Array.from(e));
          // props.data.handleChange
          if(props.isTrailPack == 'Y'){
            props.setOnchangeData(false)
          }
        }}
        error={props.data.errors.maritalStatus}
        options={props?.editbasicinfofreshData?.maritalStatus}
        isDisabled={props.editfromadmin == 'Y'}
      />
      <div className={` ${isV3User? "pl-[.5rem] md:pl-[1.5rem] lg:pl-[1.5rem] mt-4 md:mt-[1rem]": "md:mt-[2rem] mt-4"} justify-center items-center grid grid-cols-9 `}>
        <div className="md:col-span-3 lg:col-span-4 col-span-9">
          <p className= {isV3User?"  md:text-[12px] lg:text-[14px] flex text-[#7A7A7A] font-semibold   ":" md:text-[12px] lg:text-[14px] flex text-[#575556] font-semibold  "}>
          {trans('my_profile.age_range')}
            {/* <!-- <span className="text-[#D10A11]">*</span> --> */}
          </p>
        </div>
        <div className="md:col-span-6 lg:col-span-5 col-span-9 md:pr-[1rem] lg:pr-[4rem]  md:pl-[1rem]">          
            <div className="flex space-x-2 ">
              <label className=" select font-bold text-[#575556] ">
                <select
                  name="agefrom"
                  id="agefrom"
                  value={props.data.values.agefrom}
                  onChange={(e) => {
                    props.data.handleChange(e);
                    props?.apicallsearchpagenewcount?.(props.data.values);  
                    OnChangeBottomData()
                    setAge(e.target.value);
                    if(parseInt(e.target.value) > parseInt(props.data.values?.ageTo)){
                      props.data?.setFieldValue("ageTo",e.target.value)
                    }
                    if(props.isTrailPack == 'Y'){
                      props.setOnchangeData(false)
                    }
                    // props.updateFromData(e);
                  }}
                  disabled={props.editfromadmin == 'Y'}
                  className={`w-full md:p-[0.4rem] lg:p-2.5  border border-[#8F8F8F] text-[#8F8F8F] font-semibold md:text-[12px] lg:text-[14px] bg-white rounded-[8px] ${props.editfromadmin == 'Y' ? 'cursor-not-allowed':''}  `}
                >
                  {ageFromValue.map((e, i) => (
                    <option key={i} value={e}>
                      {e}
                    </option>
                  ))}
                </select>
              </label>
              <p className="flex justify-center items-center font-bold text-[#575556] md:text-[12px] lg:text-[14px] ">
              {trans('my_profile.to')}
              </p>
              <label className=" select font-bold text-[#575556] ">
                <select
                  name="ageTo"
                  id="ageTo"
                  value={props.data.values?.ageTo}
                  onChange={(e) => {
                    props.data.handleChange(e);
                    props?.apicallsearchpagenewcount?.(props.data.values);  
                    OnChangeBottomData()
                    if(props.isTrailPack == 'Y'){
                      props.setOnchangeData(false)
                    }
                    // props.updateToData(e);
                  }}
                  disabled={props.editfromadmin == 'Y'}
                  className={` w-full md:p-[0.4rem] lg:p-2.5  border border-[#8F8F8F] text-[#8F8F8F] font-semibold md:text-[12px] lg:text-[14px] bg-white rounded-[8px]  ${props.editfromadmin == 'Y' ? 'cursor-not-allowed' : ''} `}
                >
                  {ageToValue.map((e, i) => (
                    <option key={i} value={e}>
                      {e}
                    </option>
                  ))}
                </select>
              </label>
            </div>         
        </div>
      </div>
      <MultiSelectView
        title={trans('my_profile.country')}
        placeholder={trans('my_profile.any_country')}
        inputID={"country"}
        inputName={"country"}
        value={props.data.values.country}
        defalutValue={[props.data.values.country]}
        onChange={(e) => {
          props?.handleSelectInputChange?.(e);
          props?.apicallsearchpagenewcount?.(props.data.values);  
          OnChangeBottomData()
          props.data.setFieldValue("country", Array.from(e));
          apiCallState(Array.from(e),false);
          if(props.isTrailPack == 'Y'){
            props.setOnchangeData(false)
          }
        }}        
        error={props.data.errors.country}
        options={props?.editbasicinfofreshData?.country}
        isDisabled={props.editfromadmin == 'Y'}
      />
      <MultiSelectView
        title={trans('my_profile.state')}
        placeholder={trans('my_profile.any_state')}
        inputID={"state"}
        inputName={"state"}
        value={props.data.values.state}
        defalutValue={[props.data.values.state]}
        onChange={(e) => {
          props?.handleSelectInputChange?.(e);
          props?.apicallsearchpagenewcount?.(props.data.values);  
          OnChangeBottomData()
          props.data.setFieldValue("state", Array.from(e));
          apiCallCity(Array.from(e),false);
          if(props.isTrailPack == 'Y'){
            props.setOnchangeData(false)
          }
          // props.data.handleChange
        }}
        error={props.data.errors.state}
        options={stateDropDown}
        isDisabled={props.editfromadmin == 'Y'}
      />
      <MultiSelectView
        title={trans('my_profile.city')}
        placeholder={trans('my_profile.any_city')}
        inputID={"city"}
        inputName={"city"}
        value={props.data.values.city}
        defalutValue={[props.data.values.city]}
        onChange={(e) => {
          props?.handleSelectInputChange?.(e);
          props?.apicallsearchpagenewcount?.(props.data.values);  
          OnChangeBottomData()
          props.data.setFieldValue("city", Array.from(e));
          // props.data.handleChange
          if(props.isTrailPack == 'Y'){
            props.setOnchangeData(false)
          }
        }}
        error={props.data.errors.city}
        options={cityDropDown}
        isDisabled={props.editfromadmin == 'Y'}
      />
      <MultiSelectView
        title={trans('my_profile.mother_tongue')}
        placeholder={trans('my_profile.any_mother_tongue')}
        inputID={"motherTongue"}
        inputName={"motherTongue"}
        value={props.data.values.motherTongue}
        defalutValue={[props.data.values.motherTongue]}
        onChange={(e) => {
          props?.handleSelectInputChange?.(e);
          props?.apicallsearchpagenewcount?.(props.data.values);  
          OnChangeBottomData()
          props.data.setFieldValue("motherTongue", Array.from(e));

          apiCallCast(Array.from(e), props.data.values.religion,false);
          clearCaste()
          if(props.isTrailPack == 'Y'){
            props.setOnchangeData(false)
          }
          // props.data.handleChange
        }}
        error={props.data.errors.motherTongue}
        options={props?.editbasicinfofreshData?.motherTongue}
        isDisabled={props.editfromadmin == 'Y'}
      />
      <MultiSelectView
        title={trans('my_profile.religion')}
        placeholder={trans('my_profile.any_religion')}
        inputID={"religion"}
        inputName={"religion"}
        value={props.data.values.religion}
        defalutValue={[props.data.values.religion]}
        onChange={(e) => {
          props?.handleSelectInputChange?.(e);
          props?.apicallsearchpagenewcount?.(props.data.values);  
          OnChangeBottomData()
          props.data.setFieldValue("religion", Array.from(e));
          apiCallCast(props.data.values.motherTongue, Array.from(e),false);
          clearCaste()
          if(props.isTrailPack == 'Y'){
            props.setOnchangeData(false)
          }
          // if(!e?.includes(Constants.christialId) && props.data.values.denomination?.length !== 0){
          //   props.data.setFieldValue("denomination", []);
          // }
          // props.data.handleChange
        }}
        error={props.data.errors.religion}
        options={props?.editbasicinfofreshData?.religion}
        isDisabled={props.editfromadmin == 'Y'}
      />
       {isChristian && <MultiSelectView
        title={trans('my_profile.denomination')}
        placeholder={trans('set_your_partner_expectations.any_denomination')}
        inputID={"denomination"}
        inputName={"denomination"}
        value={props.data.values.denomination??[]}
        defalutValue={[props.data.values.denomination]}
        onChange={(e) => {
          props?.handleSelectInputChange?.(e);
          props?.apicallsearchpagenewcount?.(props.data.values);  
          OnChangeBottomData()
          props.data.setFieldValue("denomination", Array.from(e));
          if(props.isTrailPack == 'Y'){
            props.setOnchangeData(false)
          }
        }}
        error={props.data.errors.denomination}
        options={props?.editbasicinfofreshData?.domain}
        isDisabled={props.editfromadmin == 'Y'}
      />}
      <MultiSelectView
        title={trans('my_profile.caste')}
        inputID={"cast"}
        placeholder={trans('my_profile.any_caste')}
        inputName={"cast"}
        value={props.data.values.cast}
        defalutValue={[props.data.values.cast]}
        onChange={(e) => {
          props?.handleSelectInputChange?.(e);
          props?.apicallsearchpagenewcount?.(props.data.values);  
          OnChangeBottomData()
          props.data.setFieldValue("cast", Array.from(e));
          apiCallSubCast(Array.from(e),false);
          // props.data.handleChange
          if(props.isTrailPack == 'Y'){
            props.setOnchangeData(false)
          }
        }}
        error={props.data.errors.cast} 
        options={castDropDown}
        isDisabled={props.editfromadmin == 'Y'}
      />
      {
        subCastDropDown?.length !== 0
          ? <MultiSelectView
            title={trans('my_profile.subcaste')}
            placeholder={trans('my_profile.any_subcaste')}
            inputID={"subCast"}
            inputName={"subcast"}
            value={props?.data?.values?.subcast}
            defalutValue={[props?.data.values?.subcast]}
            onChange={(e) => {
              props?.handleSelectInputChange?.(e);
              props?.apicallsearchpagenewcount?.(props.data.values);  
              OnChangeBottomData()
              props.data.setFieldValue("subcast", Array.from(e));
              if(props.isTrailPack == 'Y'){
                props.setOnchangeData(false)
              }
            }}
            error={props.data.errors.subcast}
            options={subCastDropDown}
            isDisabled={props.editfromadmin == 'Y'}
          />
          : null
      }
    </div>
  );
};

export default BasicPeference;
