import React, { useEffect, useState, useContext, useMemo } from "react";
import {
  BasicEditTextView,
  BasicTextView,
  BasiSelectView,
  EditButton,
  SaveCancelButton,
  SelectInput,
  FloatingSelectInput,
  SelectCurrencyInput
} from "../EditProfileUtills";
import { useFormik } from "formik";
import { LoginContext } from "../../../Contexts/LoginContext";
import * as Yup from "yup";
import EndPoints from "../../../Constants/EndPoints";
import { IP_Address } from "../../../Constants/IpAddress";
import { POST } from "../../../Services/api_services";
// import Constants, { loginFrom } from "../../../Constants/constants";
import { EventKeys, blockInvalidCharForNumber, blockInvalidCharForText } from "../../../Constants/constants";
import config from "../../../config";
import { validateNumber } from "../../../Utils";
import Constants, {
  EventName,
} from "../../../Constants/constants";
import { useAnalytics } from "../../../Hooks/usePageChange";
import ActionRestrict from "../../Kalyanlite/Common/ActionRestrict";
import LoaderGif from '../../../Gif/loader.gif'
import { getDeviceInfo } from '../../Kalyanlite/utils';
import { getGroupAuthId } from "../../../Components/Kalyanlite/utils";
import { useDispatch, useSelector } from "react-redux";
import { getConversationList } from "../../../Store/Chats/Action";
import { ValidationNotificationPopup } from "../../CommonPopup";
import { NavBarContext } from "../../../Contexts/NavBarContext";
import { useTranslation } from "react-i18next";
import { familystatus, familytype, familyvalue, parenticon, IsdcodeIcon } from "../../Kalyanlite/assets/images";
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";
import AfterregistrationSelectInput from "./AfterregistrationSelectInput";
import MyProfileEditview from "./MyProfileEditview";

const FamilyComponent = (props) => { 
  const { loginDetail, logout } = useContext(LoginContext);

  const [isShowEdit, setEditView] = useState(true);
  const [familyStatusDropDown, setfamilyStatusDropDown] = useState([]);
  const [familyTypeDropDown, setfamilyTypeDropDown] = useState([]);
  const [familyValuesDropDown, setfamilyValuesDropDown] = useState([]);
  const [parentsIsdCodeDropDown, setparentsIsdCodeDropDown] = useState([]);
  const [currencyDropDown, setcurrencyDropDown] = useState([]);
  const [relationDropDown, setRelationDropDown] = useState([{ id: 5, name: "Mother" }, { id: 6, name: "Father" }]);

  const [submitStatus, setSubmitStatus] = useState(false)
  const [isParentNumberChanged, setIsParentNumberChanged] = useState(false)
  const [phoneNumberError, setPhoneNumberError] = useState("")
  const { setShowValidationMessage, setIsRequestKey } = useContext(NavBarContext);
  const [disablesave,setDisableSave] = useState(true)
  const [toastmsg,setToastmsg]=useState(false)


  const { t: trans } = useTranslation();

  const userData = useSelector(
    (state) => state?.DashBoardAPI?.ProfileData?.data,
  );
  const dispatch = useDispatch()

  const loggedInProfileStatus = userData?.status
  const receiverId = useSelector((state) => state?.Chats?.currentChat?.receiverId)

  const formik = useFormik({
    initialValues: {
      familyStatus: props?.data?.familyStatus
        ? props?.data?.familyStatus[0]?.id
        : "",
      familyType: props?.data?.familyType ? props?.data?.familyType[0]?.id : "",
      familyValues: props?.data?.familyValues
        ? props?.data?.familyValues[0]?.id
        : "",
      parentsIsdCode: props?.data?.parentsIsdCode
        ? props?.data?.parentsIsdCode
        : "",
      parentNumber: props?.data?.parentsMobileNumber
        ? props?.data?.parentsMobileNumber
        : "",
      relationshipId: props?.data?.relationshipId
        ? props?.data?.relationshipId
        : "",
      parentsMonthly: props?.data?.parentsMonthly
        ? props?.data?.parentsMonthly
        : "",
      currency: props?.data?.parentsCurrency?.currency ? props?.data?.parentsCurrency?.currency : "",
      descriptionEdit: props?.data?.descriptionEdit,
      descriptionContentId: props?.data?.descriptionContentId,
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      familyStatus: Yup.string(),
      familyType: Yup.string(),
      familyValues: Yup.string(),
      parentsIsdCode: Yup.string(),
      relationshipId: Yup.string().when("familyStatus", (familyStatus, schema) => {

        if (props?.isFrom == "requestFamilyDetails") {
          return schema;
        } else {
          // return schema.required(trans('family.please_select_relation'));
        }
      }),
      // relationshipId: Yup.string().required("Please Select Relation"),
      // parentNumber: Yup.string().matches(/^\+?[1-9][0-9]{9,19}$/, 'Please enter valid phone number'),
      // parentNumber: Yup.string().min(10, "Please enter valid mobile number")
      // .test("india", "Mobile number should have 10 digits", function (value) {
      //   const { parentsIsdCode } = this.parent;
      //   //console.log(parentsIsdCode, 'countryId');
      //   if (parentsIsdCode === '+91') {
      //     return !value || value.length === 10 ? true : this.createError({ message: "Mobile number should have 10 digits", path: "parentNumber" });
      //   }
      //   return true;
      // })
      //   .test("other", "Mobile number should have 20 digits", function (value) {
      //     const { parentsIsdCode } = this.parent;
      //     if (parentsIsdCode !== '+91') {
      //       return !value || value.length < 21 ? true : this.createError({ message: "Please enter valid mobile number", path: "parentNumber" });
      //     }
      //     return true;
      //   }),
      parentNumber: Yup.string()
        .test('valid-number', 'Please enter a valid Mobile Number', function (value) {
          const { parentsIsdCode } = this.parent;
          if (parentsIsdCode === '+91') {
            // Validate for India (+91) specific rules
            if (value) {
              if (value?.length !== 10) {
                return this.createError({ message: trans('family.please_enter_a_valid_mobile_nu'), path: 'parentNumber' });
              }
            }
          } else {
            // Validate for other countries
            if (value) {
              if (value?.length < 7 || value?.length > 15) {
                return this.createError({ message: trans('family.please_enter_a_valid_mobile_nu'), path: 'parentNumber' });
              }
            }
          }
          return true;
        }).when("familyStatus", (familyStatus, schema) => {
          if (props?.isFrom == "requestFamilyDetails") {
            return schema;
          } else {
            // return schema.required(trans('family.please_enter_mobile_number'));
          }
        }),
      parentsMonthly: Yup.string(),
    }),

    onSubmit: async(values) => {
      setSubmitStatus(true)
      // apiCallFamily(values);
      try {
        await apiCallFamily(values); // Call your API
      } catch (error) {
        console.error("Error while saving family details:", error);
      } finally {
        setSubmitStatus(false); // Set it back to false after API handling
      }
      setIsRequestKey('')
    },
  });

  useEffect(() => {
    setfamilyStatusDropDown(props?.dropDown?.familyStatusData || []);
    setfamilyTypeDropDown(props?.dropDown?.familyTypeData || []);
    setfamilyValuesDropDown(props?.dropDown?.familyValuesData || []);
    setparentsIsdCodeDropDown(props?.dropDown?.isdcode || []);
    setcurrencyDropDown(props?.dropDown?.currencyData || []);
  }, [props?.dropDown]);

  useEffect(() => { }, [
    familyStatusDropDown,
    familyTypeDropDown,
    familyValuesDropDown,
    parentsIsdCodeDropDown,
    currencyDropDown,
    relationDropDown
  ]);

  const requestType = ((props.fieldType >= 27 && props.fieldType <= 30) || props.fieldType == 44)

  const apiCallFamily = async (value) => {
    console.log(value,'9088');
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      loginFrom: localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
      FamilyStatusId: value.familyStatus,
      familyTypeId: value.familyType,
      familyValuesId: value.familyValues,
      parentsMobileNumber: isParentNumberChanged ? value.parentNumber : "",
      // parentsMobileNumber: value.parentNumber,
      parentsIsdcode: value.parentsIsdCode,
      parentsMonthly: value.parentsMonthly,
      parentsCurrency: value.currency == selectedParentsCurrenyCode.name ? selectedParentsCurrenyCode.id : value.currency,
      relationshipId : value.relationshipId,
      ...(value?.familyStatus != "" && value?.familyStatus != undefined && {familystatusrequesttypeid:27}),
      ...(value?.familyType != "" && value?.familyType != undefined && {familytyperequesttypeid:28}),
      ...(value?.familyValues != "" && value?.familyValues != undefined && {familyvaluerequesttypeid:29}),
      ...(value?.parentsMonthly != "" && {parentmonthlyrequesttypeid:30}),
      ...(value?.parentNumber != "" && {parentnumberrequesttypeid:87}),
      requestfulfilledfrom:getDeviceInfo() ,
      requestfulfilledvia : props?.isFromSms == "yes" ? "sms" : props?.isFrom == "requestFamilyDetails" ? "chat" : 
      props?.isFrom=="familydetails" && localStorage.getItem("profiledot") != "true" ?"profile_preview":props?.isFrom=="editmyprofile" ?"Myprofile" :
      localStorage.getItem("profiledot") == "true" ?"profile_preview":"chat",
      recieverprofileid: getGroupAuthId(),
      descriptionEdit: value.descriptionEdit,
      descriptionContentId:value.descriptionContentId,

      "editFrom": props?.isFrom=="familydetails" && localStorage.getItem("profiledot") != "true" ?"profile_preview":props?.isFrom=="editmyprofile" ?"Myprofile" :
      localStorage.getItem("profiledot") == "true" ?"profile_preview_three_dots":"Myprofile",

    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.editfamilyinfosaveUrl()}`,
      request
    );

    if (statusCode === 200) {
      if (data.data.status === "SUCCESS") {
        setSubmitStatus(false)
        setDisableSave(true)
        setIsParentNumberChanged(false)
        if (props?.fieldType == 30) {
          setShowValidationMessage(true)
        }
        if (requestType) {
          props.close()
        } else {
          props.callBackReload();
          // setEditView(false);
          if(toastmsg){
            toast.success(
              <div className='flex items-center'>
                {"Detail added Successfully. The added detail will reflect in your profile after verification by Kalyan Matrimony."}
              </div>,
            
            );
  } 
        }
        if (props?.isFrom == "requestFamilyDetails") {
          onClickTrack(EventName.Chat_Request_fulfillment_popup_Save_Family_details_Button, { [EventKeys.requestFamilyDetails]: value })
        }
        else {
          onClickTrack(EventName.profileFamilySaveButton, { [EventKeys.profileFamily]: value })
        }
      }
      else {
        setSubmitStatus(false)
        setPhoneNumberError(data?.data?.statusMessage)
      }
    } else if (statusCode === 401) {
      logout();
    }
  };

  const selectedFamilyStatus = useMemo(() => {
    return familyStatusDropDown?.filter(option => option.id === formik.values.familyStatus)?.at(0)
  }, [familyStatusDropDown, formik.values.familyStatus])

  const selectedFamilyType = useMemo(() => {
    return familyTypeDropDown?.filter(option => option.id === formik.values.familyType)?.at(0)
  }, [familyTypeDropDown, formik.values.familyType])

  const selectedFamilyValues = useMemo(() => {
    return familyValuesDropDown?.filter(option => option.id === formik.values.familyValues)?.at(0)
  }, [familyValuesDropDown, formik.values.familyValues])

  const selectedParentsIsdCode = useMemo(() => {
    return parentsIsdCodeDropDown?.filter(option => option.isdcode === formik.values.parentsIsdCode)?.at(0)
  }, [formik.values.parentsIsdCode, parentsIsdCodeDropDown])

  const selectedParentsCurrenyCode = useMemo(() => {
    return currencyDropDown?.filter(option => option.name === formik.values.currency)?.at(0)
  }, [formik.values.currency, currencyDropDown])

  const selectedRelation = useMemo(() => {
    return relationDropDown?.filter(option => option.id == formik.values.relationshipId)?.at(0)
  }, [relationDropDown, formik.values.relationshipId])

  const { onClickTrack } = useAnalytics()
  const saveCallBack = (e) => {
    e.preventDefault();
  }

  const ComponentToRender = props.isFrom === 'familydetails' ? FloatingSelectInput : SelectInput;

  const isMobile = useMediaQuery({ maxWidth: 678 });


  return (
    <div>
      {requestType ? '' : isShowEdit ? (
        <></>
      ) : (
        <div className="p-5 md:mb-4 md:p-0">
          <ActionRestrict>
            <EditButton
              editCallBack={() => {
                setEditView(true);
              }}
            />
          </ActionRestrict>
          <BasicTextView
            title={trans('view_profile.family_status')}
            value={
              props?.data?.familyStatus
                ? props?.data?.familyStatus[0].name
                : trans('my_profile.not_specified')
            }
          />
          <BasicTextView
            title={trans('view_profile.family_type')}
            value={
              props?.data?.familyType
                ? props?.data?.familyType[0].name
                : trans('my_profile.not_specified')
            }
          />

          <BasicTextView
            title={trans('view_profile.family_values')}
            value={
              props?.data?.familyValues
                ? props?.data?.familyValues[0].name
                : trans('my_profile.not_specified')
            }
          />
          <BasicTextView
            title={trans('my_profile.parents_isd_code')}
            value={
              props?.data?.parentsIsdCode
                ? props?.data?.parentsIsdCode
                : trans('my_profile.not_specified')
            }
          />
          <BasicTextView
            title={trans('trial_pack_popup__family_detai.parents_mobile_number')}
            value={
              props?.data?.parentsMobileNumber
                ? props?.data?.parentsMobileNumber
                : trans('my_profile.not_specified')
            }
          />
          <BasicTextView
            title={trans('trial_pack_popup__family_detai.parents_relation')}
            value={
              props?.data?.relationshipName
                ? props?.data?.relationshipName
                : trans('my_profile.not_specified')
            }
          />
          <BasicTextView
            title={trans('view_profile.parents_income')}
            value={
              props?.data?.parentsMonthly && props?.data?.parentsCurrency?.currency
                ? (props?.data?.parentsCurrency?.currency) + " " + (props?.data?.parentsMonthly)
                : trans('my_profile.not_specified')
            }
          />
        </div>
      )}
      {/* <!-- edit part  --> */}
      {props?.isFrom=="familydetails"?
       <form onSubmit={formik.handleSubmit}>
       {isShowEdit || (props.fieldType >= 27 && props.fieldType <= 30) || props.fieldType == 44 ? (
         <>
         <div className={`${(props.fieldType >= 27 && props.fieldType <= 30) || props.fieldType == 44 ? 'grid grid-cols-1 md:grid-cols-2 lg:mt-2' : 'p-0 lg:px-4'}`}>
           <AfterregistrationSelectInput
             name="familyStatus"
             title={trans('view_profile.family_status')}
             value={selectedFamilyStatus}
             onChange={(selected, ev) => {
               formik.setFieldValue("familyStatus", selected.id)
               setDisableSave(false)
             }}
             error={formik.errors.familyStatus}
             defaultValue={formik.values.familyStatus}
             options={[{ id: "", name: trans('trial_pack_popup__family_detai.select'), isDisabled: true  }, ...familyStatusDropDown]}
             getOptionLabel={(option) => option.name}
             getOptionValue={(option) => option.id}
             fieldType={props.fieldType}
             disabled={props.editfromadmin == 'Y'}
             isFrom={"familydetail"}
             icon={familystatus}
             customClassName={"mt-[1rem]"}
           />
          
             <AfterregistrationSelectInput
               name="familyType"
               title={trans('view_profile.family_type')}
               value={selectedFamilyType}
               onChange={(selected, ev) => {
                 formik.setFieldValue("familyType", selected.id)
                 setDisableSave(false)
               }}
               error={formik.errors.familyType}
               defaultValue={formik.values.familyType}
               options={[{ id: "", name: trans('trial_pack_popup__family_detai.select'), isDisabled: true }, ...familyTypeDropDown]}
               getOptionLabel={(option) => option.name}
               getOptionValue={(option) => option.id}
               fieldType={props.fieldType}
               isFrom={"familydetail"}
               icon={familytype}
             />
            
             <AfterregistrationSelectInput
               name="familyValues"
               title={trans('view_profile.family_values')}
               value={selectedFamilyValues}
               onChange={(selected, ev) => {
                 formik.setFieldValue("familyValues", selected.id)
                 setDisableSave(false)
               }}
               error={formik.errors.familyValues}
               defaultValue={formik.values.familyValues}
               options={[{ id: "", name: trans('trial_pack_popup__family_detai.select'), isDisabled: true }, ...familyValuesDropDown]}
               getOptionLabel={(option) => option.name}
               getOptionValue={(option) => option.id}
               fieldType={props.fieldType}
               isFrom={"familydetail"}
               icon={familyvalue}
             />
            
             <AfterregistrationSelectInput
               name="parentsIsdCode"
               // title="Parent's Isd Code"
               title={trans('my_profile.parents_isd_code')}
               value={selectedParentsIsdCode}
               onChange={(selected, ev) => {
                 formik.setFieldValue("parentsIsdCode", selected.isdcode)
                 setDisableSave(false)
               }}
               error={formik.errors.parentsIsdCode}
               defaultValue={formik.values.parentsIsdCode}
               options={[{ id: "", name: trans('trial_pack_popup__family_detai.select'), isDisabled: true }, ...parentsIsdCodeDropDown]}
               getOptionLabel={(option) => `${option.name ? option.name : option.isdcode + " - " + option.country}`}
               getOptionValue={(option) => option.isdcode}
               fieldType={props.fieldType}
               isFrom={"familydetail"}
               icon={IsdcodeIcon}
             />
             
           <MyProfileEditview
             title={trans('my_profile.parents_mobile_number')}
             inputID={"parentNumber"}
             inputName={"parentNumber"}
             value={formik.values.parentNumber}
             onChange={(e)=>{
               if(validateNumber(e.target.value)){
                 setPhoneNumberError("")
                 setIsParentNumberChanged(true)
                 formik.handleChange(e)
                 setDisableSave(false)
               }
             }}
             phoneNumberError={phoneNumberError}
             error={phoneNumberError ? phoneNumberError :formik.errors.parentNumber}
             inputType={"tel"}
             onKeyDown={blockInvalidCharForNumber}
             fieldType={props.fieldType}
             disabled={props?.editfromadmin == 'Y'}
             isFrom={"profileaddDetails"}
             RelationError ={'relationShip'}
             isFroms={"familydetail"}
             icon={IsdcodeIcon}
           />

           <AfterregistrationSelectInput
             name="relationshipId"
             title={trans('my_profile.parents_relation')}
             value={selectedRelation}
             onChange={(e) => {
               formik.setFieldValue("relationshipId", e.id)
               setDisableSave(false)
             }}
             error={formik.errors.relationshipId}
             defaultValue={formik.values.relationshipId}
             options={[{ id: "", name: trans('trial_pack_popup__family_detai.select'), isDisabled: true }, ...relationDropDown]}
             getOptionLabel={(option) => option.name}
             getOptionValue={(option) => option.id}
             fieldType={props.fieldType}
             disabled={props.editfromadmin == 'Y'}
             isFrom={"familydetail"}
             icon={IsdcodeIcon}

           />
           

             <div className={`md:grid grid-cols-9  mt-[2rem] `}>
               <div className={`md:col-span-3 lg:col-span-3 xl:col-span-2 col-span-3 hidden md:flex items-center md:pl-[1rem] lg:pl-[2rem]`} >
                 <p className={`md:text-[12px] lg:text-[14px] gap-3 flex items-center text-[#7A7A7A]  font-semibold `}>
                  <img className="w-[2.5rem] h-[2.5rem]" src={parenticon}/> {trans('my_profile.parents_income')}
                 </p>
               </div>
               <div className={`col-span-9  lg:col-span-6 xl:col-span-4 md:col-span-6    md:pr-[1rem] xl:pr-[4rem] md:pl-[4rem]  lg:pr-[10rem] lg:pl-[3rem] xl:pl-[7rem]`}>
                   <div className="flex">
                     {/* <label className=" select font-bold text-[#575556] w-[8rem]"> */}
                     <img alt="" className="w-[2.5rem] h-[2.5rem] block md:hidden" src={parenticon}/> 
                     <SelectCurrencyInput
                       menu="familyincom"
                       name="currency"
                       id="currency"
                       title="currency"
                       error={formik.errors.currency}
                       defaultValue={formik.values.currency}
                       value={selectedParentsCurrenyCode}
                       // onChange={formik.handleChange}
                       icon={parenticon}
                       onChange={(selected, ev) => {
                         formik.setFieldValue("currency", selected.name)
                         setDisableSave(false)
                         setToastmsg(true)
                       }}
                       className={`md:p-[0.4rem] lg:p-2.5  border-[1px] border-[#8F8F8F] text-[#575556] font-semibold md:text-[12px] lg:text-[14px] bg-white
                         rounded-[8px] ${(props.fieldType >= 27 && props.fieldType <= 30) || props.fieldType == 44 ? 'w-[8rem]' : 'w-full'}`}

                       // {/* {currencyDropDown?.map((e, i) => (
                       //   <option key={i} value={e.name}>
                       //     {e.name}
                       //   </option>
                       // ))} */}
                       options={[{ id: "", name: trans('trial_pack_popup__family_detai.select'), isDisabled: true }, ...currencyDropDown]}
                       getOptionLabel={(option) => `${option.name ? option.name : option.currency}`}
                       getOptionValue={(option) => option.id}
                       isFrom={"familydetail"}
                       isDisabled={props.editfromadmin == 'Y'}
                       wrapperClassName="pl-[12px] md:pl-0 pr-[12px]"
                     />
                 
                 <div className="items-center w-[100%]  RegPhone">

                      <div className="relative">
                    <label
                      className={`absolute -top-3 left-3 bg-white px-1 text-[11px] text-[#575556] transition-all duration-200 
                      peer-placeholder-shown:top-2.5 peer-placeholder-shown:left-4 peer-placeholder-shown:bg-transparent 
                      peer-placeholder-shown:text-gray-400 peer-placeholder-shown:text-base 
                      peer-focus:-top-3 peer-focus:left-3 peer-focus:bg-white peer-focus:text-[#8F8F8F] md:hidden w-full`}
                    >
                      {trans('my_profile.parents_income')}
                    </label>
                   <p className={`${isMobile ? "pepeer block w-full bg-transparent text-gray-900" : ""} flex items-center w-[100%] pt-[1px] RegPhone`}>
                     <input
                     inputID={"parentsMonthly"}
                     inputName={"parentsMonthly"}
                       type="tel"
                       onChange={(e)=>{
                         if(validateNumber(e.target.value)){
                           formik.handleChange(e)
                           setDisableSave(false)
                           setToastmsg(true)
                         }
                       }}
                       id="parentsMonthly"
                       value={formik.values.parentsMonthly}
                       disabled={props.editfromadmin == 'Y'}
                       className={`RegPhone w-[100%] md:w-[10.5rem] lg:w-full border-[1px] border-[#8F8F8F] rounded-[3px] h-[37px] p-[0.4rem] block  md:p-[0.4rem] lg:p-2.5 text-[#575556] font-semibold md:text-[12px] lg:text-[14px] ${props.editfromadmin == 'Y' ? 'cursor-not-allowed' : ''}`}
                     />
                     </p>
                   </div>
                   {formik.errors.parentsMonthly && (
                     <p className="text-[red] text-[12px]">
                       {formik.errors.parentsMonthly}
                       </p>
                     )}
                     </div>
                   </div>
               </div>
             </div>
             
           {(props.fieldType >= 27 && props.fieldType <= 30) || props.fieldType == 44 ? '' : 
           <SaveCancelButton
           saveCallBack={(e) => {
            if (!disablesave) {
              e.preventDefault();
              setToastmsg(true)
              console.log("Toastmsg", toastmsg); // Check if the state is being set correctly

            }

          }}
             cancelCallback={() => {
               // setEditView(false);
               // props.refresh()
               formik.resetForm()
               setDisableSave(true)
               onClickTrack(EventName.profileFamilyCancelButton)
             }}
             submitStatus={submitStatus}
             editfromadmin={props.editfromadmin}
             setDisableSave={setDisableSave}
             disablesave={disablesave}
             isFrom="MyProfile"
              isFroms="profileaddDetails"
             
           />}
         </div>
         {requestType?
         <div className="flex justify-center gap-5 py-6">
             <button type="submit" 
               className="text-[12px] py-1.5 lg:px-[3rem] px-[2.5rem] text-[#FFFFFF] font-bold rounded-[4px] bg-red-600"
             >
               {submitStatus ? <div className='flex items-center justify-center p-1'><img src={LoaderGif} className="brightness-0 invert-[1] w-5 h-5" alt=""/><span>{trans('my_profile.saving')}</span></div> : <span>{trans('trial_pack_popup__family_detai.save')}</span>}
             </button>
         </div>: ''}
       </>
       ) : (
         <></>
       )}
     </form>
      :
      <form onSubmit={formik.handleSubmit}>
        {isShowEdit || (props.fieldType >= 27 && props.fieldType <= 30) || props.fieldType == 44 ? (
          <>
          <div className={`${(props.fieldType >= 27 && props.fieldType <= 30) || props.fieldType == 44 ? 'grid grid-cols-1 md:grid-cols-2 lg:mt-2' : 'p-5 md:p-0'}`}>
            <ComponentToRender
              name="familyStatus"
              title={trans('view_profile.family_status')}
              value={selectedFamilyStatus}
              onChange={(selected, ev) => {
                formik.setFieldValue("familyStatus", selected.id)
                props?.setShowBottom(false)
              }}
              error={formik.errors.familyStatus}
              defaultValue={formik.values.familyStatus}
              options={[{ id: "", name: trans('trial_pack_popup__family_detai.select'), isDisabled: true  }, ...familyStatusDropDown]}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              fieldType={props.fieldType}
              disabled={props.editfromadmin == 'Y'}
            />
            {/* <BasiSelectView
            <div className={`${(props.fieldType >= 27 && props.fieldType <= 30) || props.fieldType == 44 ? 'grid grid-cols-1 md:grid-cols-2 lg:mt-[-2rem]' : 'p-5 md:p-0'}`}>
              <ComponentToRender
                name="familyStatus"
                title="Family Status"
                value={selectedFamilyStatus}
                onChange={(selected, ev) => {
                  formik.setFieldValue("familyStatus", selected.id)
                }}
                error={formik.errors.familyStatus}
                defaultValue={formik.values.familyStatus}
                options={[{ id: "", name: trans('trial_pack_popup__family_detai.select'), isDisabled: true }, ...familyStatusDropDown]}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                fieldType={props.fieldType}
              />
              {/* <BasiSelectView
              title={trans('view_profile.family_status')}
              inputID={"familyStatus"}
              inputName={"familyStatus"}
              value={formik.values.familyStatus}
              onChange={formik.handleChange}
              error={formik.errors.familyStatus}
              options={familyStatusDropDown}
            /> */}
              <ComponentToRender
                name="familyType"
                title={trans('view_profile.family_type')}
                value={selectedFamilyType}
                onChange={(selected, ev) => {
                  formik.setFieldValue("familyType", selected.id)
                  props?.setShowBottom(false)
                }}
                error={formik.errors.familyType}
                defaultValue={formik.values.familyType}
                options={[{ id: "", name: trans('trial_pack_popup__family_detai.select'), isDisabled: true }, ...familyTypeDropDown]}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                fieldType={props.fieldType}
              />
              {/* <BasiSelectView
              title={trans('view_profile.family_type')}
              inputID={"familyType"}
              inputName={"familyType"}
              value={formik.values.familyType}
              onChange={formik.handleChange}
              error={formik.errors.familyType}
              options={familyTypeDropDown}
            /> */}
              <ComponentToRender
                name="familyValues"
                title={trans('view_profile.family_values')}
                value={selectedFamilyValues}
                onChange={(selected, ev) => {
                  formik.setFieldValue("familyValues", selected.id)
                  props?.setShowBottom(false)
                }}
                error={formik.errors.familyValues}
                defaultValue={formik.values.familyValues}
                options={[{ id: "", name: trans('trial_pack_popup__family_detai.select'), isDisabled: true }, ...familyValuesDropDown]}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                fieldType={props.fieldType}
              />
              {/* <BasiSelectView
              title={"{trans('view_profile.family_values')}"}
              inputID={"familyValues"}
              inputName={"familyValues"}
              value={formik.values.familyValues}
              onChange={formik.handleChange}
              error={formik.errors.familyValues}
              options={familyValuesDropDown}
            /> */}
              <ComponentToRender
                name="parentsIsdCode"
                // title="Parent's Isd Code"
                title={trans('my_profile.parents_isd_code')}
                value={selectedParentsIsdCode}
                onChange={(selected, ev) => {
                  props?.setShowBottom(false)
                  formik.setFieldValue("parentsIsdCode", selected.isdcode)
                }}
                error={formik.errors.parentsIsdCode}
                defaultValue={formik.values.parentsIsdCode}
                options={[{ id: "", name: trans('trial_pack_popup__family_detai.select'), isDisabled: true }, ...parentsIsdCodeDropDown]}
                getOptionLabel={(option) => `${option.name ? option.name : option.isdcode + " - " + option.country}`}
                getOptionValue={(option) => option.isdcode}
                fieldType={props.fieldType}
              />
              {/* <BasiSelectView 
              title={"Parent's IsdCode"}
              inputID={"parentsIsdCode"}
              inputName={"parentsIsdCode"}
              value={formik.values.parentsIsdCode}
              onChange={formik.handleChange}
              error={formik.errors.parentsIsdCode}
              options={parentsIsdCodeDropDown}
            /> */}
            <BasicEditTextView
              title={trans('my_profile.parents_mobile_number')}
              inputID={"parentNumber"}
              inputName={"parentNumber"}
              value={formik.values.parentNumber}
              onChange={(e)=>{
                if(validateNumber(e.target.value)){
                  setPhoneNumberError("")
                  setIsParentNumberChanged(true)
                  formik.handleChange(e)
                  props?.setShowBottom(false)
                }
              }}
              phoneNumberError={phoneNumberError}
              error={formik.errors.parentNumber}
              inputType={"tel"}
              onKeyDown={blockInvalidCharForNumber}
              fieldType={props.fieldType}
              disabled={props?.editfromadmin == 'Y'}
              isFrom={"requestPopup"}
              RelationError ={'relationShip'}
            />

            <ComponentToRender
              name="relationshipId"
              title={trans('my_profile.parents_relation')}
              value={selectedRelation}
              onChange={(e) => {
                formik.setFieldValue("relationshipId", e.id)
                props?.setShowBottom(false)
              }}
              error={formik.errors.relationshipId}
              defaultValue={formik.values.relationshipId}
              options={[{ id: "", name: trans('trial_pack_popup__family_detai.select'), isDisabled: true }, ...relationDropDown]}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              fieldType={props.fieldType}
              disabled={props.editfromadmin == 'Y'}
            />
            {/* {(props.fieldType >= 27 && props.fieldType <= 30) || props.fieldType == 44 ? '' : 
            <div className="flex justify-center pt-4">
              <p className="text-[12px] text-[red]">* This number will be visible to Matches. Matches can also contact you on this number</p>
            </div>} */}
              {/* <BasicEditTextView
                title={"Parent's Mobile Number"}
                inputID={"parentNumber"}
                inputName={"parentNumber"}
                value={formik.values.parentNumber}
                onChange={(e) => {
                  if (validateNumber(e.target.value)) {
                    setPhoneNumberError("")
                    setIsParentNumberChanged(true)
                    formik.handleChange(e)
                  }
                }}
                phoneNumberError={phoneNumberError}
                error={formik.errors.parentNumber}
                inputType={"tel"}
                onKeyDown={blockInvalidCharForNumber}
                fieldType={props.fieldType}
              />

              <FloatingSelectInput
                name="relationshipId"
                title="Parent's Relation"
                value={selectedRelation}
                onChange={(e) => {
                  formik.setFieldValue("relationshipId", e.id)
                }}
                error={formik.errors.relationshipId}
                defaultValue={formik.values.relationshipId}
                options={[{ id: "", name: trans('trial_pack_popup__family_detai.select'), isDisabled: true }, ...relationDropDown]}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                fieldType={props.fieldType}
              />
              {(props.fieldType >= 27 && props.fieldType <= 30) || props.fieldType == 44 ? '' :
                <div className="flex justify-center pt-4">
                  <p className="text-[12px] text-[red]">* This number will be visible to Matches. Matches can also contact you on this number</p>
                </div>} */}

              <div className={`${(props.fieldType >= 27 && props.fieldType <= 30) || props.fieldType == 44 ? 'mt-[1rem]' : 'grid grid-cols-9  mt-[2rem]'} `}>
                <div className={`md:col-span-4 col-span-9 flex items-center ${(props.fieldType >= 27 && props.fieldType <= 30) || props.fieldType == 44 ? 'md:pl-[1rem] lg:pl-[1rem]' : 'md:pl-[1rem] lg:pl-[2rem]'}`} >
                  <p className={`md:text-[12px] lg:text-[14px] flex  font-semibold ${(props.fieldType >= 27 && props.fieldType <= 30) || props.fieldType == 44 ? 'text-[#7A7A7A]' : 'text-[#575556]'} `}>
                    {/* Parent's Myonthly Income */}
                    {trans('my_profile.parents_income')}
                    {/* <span className="text-[#D10A11]">*</span> */}
                  </p>
                </div>
                <div className={`md:col-span-5 col-span-9 md:pr-[1rem] ${(props.fieldType >= 27 && props.fieldType <= 30) || props.fieldType == 44 ? 'lg:pr-[1rem]  md:pl-[1rem] pt-2' : 'lg:pr-[4rem]  md:pl-[1rem]'}`}>
                  <p className="">
                    <div className="flex space-x-3 ">
                      {/* <label className=" select font-bold text-[#575556] w-[8rem]"> */}
                      <SelectCurrencyInput
                        menu="familyincom"
                        name="currency"
                        id="currency"
                        title="currency"
                        error={formik.errors.currency}
                        defaultValue={formik.values.currency}
                        value={selectedParentsCurrenyCode}
                        // onChange={formik.handleChange}
                        onChange={(selected, ev) => {
                          formik.setFieldValue("currency", selected.name)
                          props?.setShowBottom(false)
                        }}
                        className={`md:p-[0.4rem] lg:p-2.5  border-[1px] border-[#8F8F8F] text-[#575556] font-semibold md:text-[12px] lg:text-[14px] bg-white rounded-[8px] ${(props.fieldType >= 27 && props.fieldType <= 30) || props.fieldType == 44 ? 'w-[8rem]' : 'w-full'}`}

                        // {/* {currencyDropDown?.map((e, i) => (
                        //   <option key={i} value={e.name}>
                        //     {e.name}
                        //   </option>
                        // ))} */}
                        options={[{ id: "", name: trans('trial_pack_popup__family_detai.select'), isDisabled: true }, ...currencyDropDown]}
                        getOptionLabel={(option) => `${option.name ? option.name : option.currency}`}
                        getOptionValue={(option) => option.id}
                        isDisabled={props.editfromadmin == 'Y'}
                      />
                  
                    <p className=" flex items-center w-[100%] pt-[1px] RegPhone">
                      <input
                      inputID={"parentsMonthly"}
                      inputName={"parentsMonthly"}
                        type="tel"
                        onChange={(e)=>{
                          if(validateNumber(e.target.value)){
                            formik.handleChange(e)
                            props?.setShowBottom(false)
                          }
                        }}
                        id="parentsMonthly"
                        value={formik.values.parentsMonthly}
                        disabled={props.editfromadmin == 'Y'}
                        className={`RegPhone  w-[100%] border-[1px] border-[#8F8F8F] rounded-[3px] h-[35px] p-[0.4rem] block  md:p-[0.4rem] lg:p-2.5 text-[#575556] font-semibold md:text-[12px] lg:text-[14px] ${props.editfromadmin == 'Y' ? 'cursor-not-allowed' : ''}`}
                      />
                    </p>
                    {formik.errors.parentsMonthly && (
                      <p className="text-[red] text-[12px]">
                        {formik.errors.parentsMonthly}
                        </p>
                      )}
                    </div>
                    </p>  
                </div>
              </div>
              
            {(props.fieldType >= 27 && props.fieldType <= 30) || props.fieldType == 44 ? '' : 
            <SaveCancelButton
              saveCallBack={(e) => {
                e.preventDefault();
              }}
              cancelCallback={() => {
                // setEditView(false);
                // props.refresh()
                formik.resetForm()
                onClickTrack(EventName.profileFamilyCancelButton)
              }}
              submitStatus={submitStatus}
              editfromadmin={props.editfromadmin}
              isFrom="MyProfile"
            />}
          </div>
          {requestType?
          <div className="flex justify-center gap-5 py-6">
              <button type="submit" 
                className="text-[12px] py-1.5 lg:px-[3rem] px-[2.5rem] text-[#FFFFFF] font-bold rounded-[4px] bg-red-600"
              >
                {submitStatus ? <div className='flex items-center justify-center p-1'><img src={LoaderGif} className="brightness-0 invert-[1] w-5 h-5" alt=""/><span>{trans('my_profile.saving')}</span></div> : <span>{trans('trial_pack_popup__family_detai.save')}</span>}
              </button>
          </div>: ''}
        </>
        ) : (
          <></>
        )}
      </form>
}
    </div>
  );
};

export default FamilyComponent;
