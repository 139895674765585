import React, { useEffect, useRef, useState } from 'react'
import PdfHeader from './PdfHeader'
import DownLoadPdf from './DownLoadPdf';
import { useDispatch } from 'react-redux';
import { getCustomerList } from '../../Store/KalyanLite/Action';
import Loader from '../Loader';

const AllSharedProfiles = ({ selectedTab, kpProfileId, isKPCustomer, pdfData }) => {

  const [showSharedProfiles, setShowSharedProfiles] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [customerListData, setCustomerListData] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(null);
  const isDataLoadingRef = useRef(false)
  const [pageno, setPageno] = useState(1); // Track the page number
  const [totalCount, setTotalCount] = useState(0); // Track the page number
  const pageLimit = 10;
  const dispatch = useDispatch()

  const handleIncreasePageNo = () => {
    const totalPages = Math.ceil(totalCount / pageLimit);
    const nextPageIndex = pageno;
  
    if (nextPageIndex < totalPages) {
      isDataLoadingRef.current = true;
      setPageno((prev)=> prev + 1); 
    }
  };

  useEffect(()=>{
    setPageno(1)
    setCustomerListData([])
    setCurrentPage(selectedData ? selectedData : selectedTab)
  },[selectedData, selectedTab])

  useEffect(() => {

    const handleScroll = () => {
        if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 100 && !isDataLoadingRef.current) {
          handleIncreasePageNo()
        }
    }

    // Add scroll event listener
      window.addEventListener('scroll', handleScroll);

      // Cleanup on unmount
      return () => window.removeEventListener('scroll', handleScroll);
  }, [handleIncreasePageNo]);

  useEffect(() => {
      isDataLoadingRef.current = true;
      setIsLoading(true);
  
      const request = {
        kp_profileId: pdfData?.pdfKey == "to_prospect customer" ? pdfData?.pdfSourceProfileId : pdfData?.pdfKpProfileId,
        // kp_profileId: 1216200,

        filter: selectedData !== null ? selectedData : selectedTab,
        key: pdfData?.pdfKey,
        // key: "to_prospect_customer",
        
        limit: pageLimit,
        pageno, // Use dynamic page number
      };
  
      dispatch(
        getCustomerList({
          request,
          onSuccess: (res) => {
            if (res?.data?.statusCode === 200) {
              setIsLoading(false);
              setTotalCount(res?.data?.data?.sharedProfileCount)
              setCustomerListData((prevData) => [
                ...prevData,
                ...(res?.data?.data?.profileList || []),
              ]);
              isDataLoadingRef.current = false; // Allow next request
            }
          },
        })
      );
  
  }, [pageno, selectedTab, selectedData]);

  return (
    <div className='bg-white mx-auto relative'>
    <PdfHeader isKPCustomer={isKPCustomer} setShowSharedProfiles={setShowSharedProfiles} setSelectedData={setSelectedData} className={"px-3 sticky top-0 z-50"} />
    <div className='bg-[#EFF6FA] min-h-screen h-full overflow-y-auto lite-scrollbar w-full md:w-[60%] lg:w-[40%] mx-auto lite-scrollbar'>
      <div className="w-full mx-auto p-8 ">
        <h1 className='text-[18px] font-bold mb-4 text-center'>{currentPage == "yetToResponse" ? "Yet To Respond Profiles" : "All Shared Profiles"}</h1>
        <div className="">
          {customerListData?.map((data, i)=>(
            <DownLoadPdf profileData={data} pdfData={pdfData} />
          ))}
          {isLoading &&
            <Loader className={"fixed bottom-0 left-[50%] z-50"} />
          }

        </div>
      </div>
    </div>
    </div>
  )
}

export default AllSharedProfiles
