import * as actionTypes from "./ActionTypes"

export const getMatches = (payload) => {
    return {
        type: actionTypes.GET_MATCHES,
        payload,
    };
};

export const getMatchesSuccess = (payload) => {
    return {
        type: actionTypes.GET_MATCHES_SUCCESS,
        payload,
    };
};

export const getMatchesFail = (payload) => {
    return {
        type: actionTypes.GET_MATCHES_FAIL,
        payload,
    };
};


/**
 * The function `getRecommendedMatches` returns an action object with a type of
 * `GET_RECOMMENDED_MATCHES` and a payload.
 * @param payload - The payload parameter is an object that contains the data needed to get the
 * recommended matches. It could include information such as user preferences, location, or any other
 * relevant data that is used to determine the recommended matches.
 * @returns an object with a type property set to "GET_RECOMMENDED_MATCHES" and a payload property set
 * to the value of the payload parameter.
 */
export const getRecommendedMatches = (payload, logout) => {
    return {
        type: actionTypes.GET_RECOMMENDED_MATCHES,
        payload,
        logout
    };
};

/**
 * The function returns an action object with a payload for a successful recommended data retrieval.
 * @param payload - The payload parameter is the data that will be passed along with the action. It can
 * be any type of data, such as an object, array, or string, that contains the necessary information
 * for the action to be processed.
 * @returns an object with a type property set to "GET_RECOMMENDED_SUCCESS" and a payload property set
 * to the value of the payload parameter.
 */
export const getRecommendedSuccess = (payload) => {
    return {
        type: actionTypes.GET_RECOMMENDED_SUCCESS,
        payload,
    };
};

/**
 * The function returns an action object with a type of GET_RECOMMENDED_FAIL and a payload.
 * @param payload - The payload parameter is an object that contains any additional data or information
 * that you want to include with the action. It can be used to pass any relevant data that is needed
 * for the action to be processed correctly.
 * @returns an object with a type property set to "GET_RECOMMENDED_FAIL" and a payload property set to
 * the value of the payload parameter.
 */
export const getRecommendedFail = (payload) => {
    return {
        type: actionTypes.GET_RECOMMENDED_FAIL,
        payload,
    };
};




export const getSelectedMatches = (payload, logout) => {
    return {
        type: actionTypes.GET_SELECTED_MATCHES,
        payload,
        logout
    };
};

export const getSelectedMatchesSuccess = (payload, logout) => {
    return {
        type: actionTypes.GET_SELECTED_MATCHES_SUCCESS,
        payload,
    };
};

export const getSelectedMatchesFail = (payload, logout) => {
    return {
        type: actionTypes.GET_SELECTED_MATCHES_FAIL,
        payload,
    };
};


export const viewProfileById = (id, isChat, logout, onSuccess,loginFrom) => {
    return {
        type: actionTypes.VIEW_PROFILE_BY_ID,
        payload: { id, isChat,loginFrom },
        logout,
        onSuccess,
    };
};

export const viewProfileByIdSuccess = (payload) => {
    return {
        type: actionTypes.VIEW_PROFILE_BY_ID_SUCCESS,
        payload,
    };
};

export const viewProfileByIdFail = (payload) => {
    return {
        type: actionTypes.VIEW_PROFILE_BY_ID_FAIL,
        payload,
    };
};

export const searchMatchesByText = (payload, logout, onSuccess) => {
    return {
        type: actionTypes.SEARCH_MATCHES_BY_TEXT,
        payload,
        logout,
        onSuccess
    };
};

export const searchMatchesByTextSuccess = (payload) => {
    return {
        type: actionTypes.SEARCH_MATCHES_BY_TEXT_SUCCESS,
        payload,
    };
};

export const searchMatchesByTextFail = (payload) => {
    return {
        type: actionTypes.SEARCH_MATCHES_BY_TEXT_FAIL,
        payload,
    };
};


export const closeCurrentMatch = (payload) => {
    return {
        type: actionTypes.CLOSE_CURRENT_MATCH,
        payload: payload
    }
}

export const setCurrentMatch = ({ match, type, category, matchType,matchdata }) => {
    
    return {
        type: actionTypes.SET_CURRENT_MATCH,
        payload: {
            type,
            data: match,
            category,
            matchType,
            matchdata
        }
    }
}

export const previousCurrentMatch = (payload) =>{
    return{

        type: actionTypes.SET_PREVIOUS_CURRENT_MATCH,
        payload: payload 
        
       
        
    }
}
/**
 * The function `nextMatch` returns an action object with a type of `NEXT_MATCH` and a payload.
 * @param payload - The payload parameter is an object that contains the data you want to pass to the
 * action. It can be any type of data, such as a string, number, array, or object. The payload is
 * typically used to provide additional information or data to the action, which can then be used by
 * the
 * @returns an object with a `type` property set to `actionTypes.NEXT_MATCH` and a `payload` property
 * set to the value of the `payload` parameter.
 */
export const nextMatch = (payload) => {
    return {
        type: actionTypes.NEXT_MATCH,
        payload
    }
}
export const previousMatch = (payload) => {
    return {
        type: actionTypes.PREVIOUS_MATCH,
        payload
    }
}

export const getProfileMatch = (payload,logout) => {
    return {
        type: actionTypes.GET_MATCH_PROFILE,
        payload,
        logout,
    }
}

export const getProfileMatchSuccess = (payload) => {
    return {
        type: actionTypes.GET_MATCH_PROFILE_SUCCESS,
        payload,
    }
}

export const getProfileMatchFail = (payload) => {
    return {
        type: actionTypes.GET_MATCH_PROFILE_FAIL,
        payload,
    }
}


export const getProfileMatchPref = (payload) => {
    return {
        type: actionTypes.GET_MATCH_PROFILE_PREF,
        payload,
    }
}

export const getProfileMatchPrefSuccess = (payload) => {
    return {
        type: actionTypes.GET_MATCH_PROFILE_PREF_SUCCESS,
        payload,
    }
}

export const getProfileMatchPrefFail = (payload) => {
    return {
        type: actionTypes.GET_MATCH_PROFILE_PREF_FAIL,
        payload,
    }
}


export const matchRequest = ({ profileId, requestType }) => {
    return {
        type: actionTypes.MATCH_REQUEST,
        payload: { profileId, requestType }
    }
}


/**
 * The function `toggleProfileSelected` toggles the selected status of a profile.
 * @returns an object with a `type` property set to `actionTypes.TOGGLE_PROFILE_SELECTED` and a
 * `payload` property set to an object with `profileId` and `selectedStatus` properties.
 */
export const toggleProfileSelected = ({ profileId, selectedStatus, statusMessage }) => {
    return {
        type: actionTypes.TOGGLE_PROFILE_SELECTED,
        payload: {
            profileId,
            selectedStatus,
            statusMessage
        }
    }
}

export const toggleProfileSelectedSuccess = (profileId, selectedStatus, statusMessage) => {
    return {
        type: actionTypes.TOGGLE_PROFILE_SELECTED_SUCCESS,
        payload : { profileId, selectedStatus, statusMessage },
    }
}

export const toggleProfileSelectedFail = (profileId, selectedStatus, statusMessage) => {
    return {
        type: actionTypes.TOGGLE_PROFILE_SELECTED_FAIL,
        payload : {profileId, selectedStatus, statusMessage},
    }
}

// Action creator for resetting profile selected status
export const resetProfileSelectedStatus = profileId => ({
    type: 'RESET_PROFILE_SELECTED_STATUS',
    payload: {
      profileId
    }
  });

/**
 * The function `toggleProfileRejected` is used to toggle the rejected status of a profile.
 * @returns an object with a `type` property set to `actionTypes.TOGGLE_PROFILE_REJECTED` and a
 * `payload` property set to an object with `profileId` and `rejectedStatus` properties.
 */
export const toggleProfileRejected = ({ profileId, rejectedStatus }) => {
    return {
        type: actionTypes.TOGGLE_PROFILE_REJECTED,
        payload: {
            profileId,
            rejectedStatus,
        }
    }
}


export const toggleProfileBlock = ({ profileId, isBlocked }) => {
    return {
        type: actionTypes.TOGGLE_PROFILE_BLOCK,
        payload: {
            profileId,
            isBlocked,
        }
    }
}


export const viewPhone = ({ profileId,fromCallPage }) => {
    return {
        type: actionTypes.VIEW_PHONE,
        payload: {
            profileId,
            fromCallPage
        }
    }
}

export const viewPhoneSuccess = (payload) => {
    return {
        type: actionTypes.VIEW_PHONE_SUCCESS,
        payload,
    }
}

export const viewPhoneFail = (payload) => {
    return {
        type: actionTypes.VIEW_PHONE_FAIL,
        payload,
    }
}


export const applyFilter = (payload) => {
    return {
        type: actionTypes.APPLY_FILTER,
        payload,
    }
}


export const getFilterMatches = (payload) => {
    return {
        type: actionTypes.GET_FILTER_MATCHES,
        payload,
    }
}
export const getFilterMatchesSuccess = (payload) => {
    return {
        type: actionTypes.GET_FILTER_MATCHES_SUCCESS,
        payload,
    }
}
export const getFilterMatchesFail = (payload) => {
    return {
        type: actionTypes.GET_FILTER_MATCHES_FAIL,
        payload,
    }
}



export const searchMatches = (payload, logout) => {
    return {
        type: actionTypes.SEARCH_MATCHES,
        payload,
        logout,
    }
}

export const searchMatchesSuccess = (payload) => {
    return {
        type: actionTypes.SEARCH_MATCHES_SUCCESS,
        payload,
    }
}

export const searchMatchesFail = (payload) => {
    return {
        type: actionTypes.SEARCH_MATCHES_FAIL,
        payload,
    }
}

export const clearSearch = (payload) => ({ type: actionTypes.CLEAR_SEARCH, payload })

// Request field

export const storeRequestType = (payload) => {
    return {
        type: actionTypes.STORE_REQUEST_TYPE,
        payload: payload
    }
}

export const storeRequestTypeSuccess = (payload) => {
    return {
        type: actionTypes.STORE_REQUEST_TYPE_SUCCESS,
        payload : payload,
    }
}

export const storeRequestTypeFail = (payload) => {
    return {
        type: actionTypes.STORE_REQUEST_TYPE_FAIL,
        payload : payload,
    }
}

export const notificationClickedTracking = (payload) => {
    return {
        type: actionTypes.NOTIFICATION_CLICKED_TRACKING,
        payload: payload
    }
}


export const getCustomerList = (payload) => {
    return {
        type: actionTypes.GET_CUSTOMER_PROFILE_LIST,
        payload: payload
    }
}

export const saveCustomerResponse = (payload) => {
    return {
        type: actionTypes.SAVE_CUSTOMER_RESPONSE,
        payload: payload
    }
}

export const isKpCustomer = (payload) => {
    return {
        type: actionTypes.IS_KP_CUSTOMER,
        payload: payload
    }
}