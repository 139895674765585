import React, { useContext, useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive';
import ProcessImage from '../../Components/Kalyanlite/Common/ProcessImage';
import BasicDetails from '../../Components/Kalyanlite/BasicDetails';
import { useDispatch, useSelector } from 'react-redux';
import OverflowToolTip from '../../Components/utils/OverflowTooltip';

//Images
import horoscope from "../../Components/Kalyanlite/assets/Images/Group 1658.png";
import trustDocx from "../../Components/Kalyanlite/assets/Images/Group 1659.png";
import phonenum from "../../Components/Kalyanlite/assets/Images/Group 2497.png";
import parantnum from "../../Components/Kalyanlite/assets/Images/Group 2498@2x.png";

import phonenumicon from '../../Components/Kalyanlite/assets/Images/Group 1934.svg'
import requesticon from "../../Components/Kalyanlite/assets/Images/Group 2498.svg"

import requestnoicon from '../../Components/Kalyanlite/assets/Images/Group 428@3x.png'

import * as Images from "../../Components/Kalyanlite/assets/images"


import * as KalyanLiteSelector from "../../Store/KalyanLite/selectors";
import DownArrow from "../../Images/Polygon 22.svg"
import TrustmarkIdImage from '../../Images/Group 3785.svg'
import TrustMarkIncomeImage from '../../Images/Group 3784.svg'
import TrustMarkEducationImage from '../../Images/Group 3783.svg'
import grayicon from '../../Components/Kalyanlite/assets/Images/Group 2478.svg'
import KMLogo from "../../Images/KM logo@2x.png"
import { dashboardProfileData } from '../../Store/DashboardAPI/Action';
import config from '../../config';
import EndPoints from '../../Constants/EndPoints';
import { POST } from '../../Services/api_services';
import { LoginContext } from '../../Contexts/LoginContext';
import Constants, { EditProfileMenu } from '../../Constants/constants';
import { NavBarContext } from '../../Contexts/NavBarContext';

import { getIsLoginFromAdmin } from "../../Utils";
import { CommonHoroPopup, CommonPayNowPopup, CommonProfilePopup, CommonSuccessPopup, CommonValidPopup, UploadPhotoPopuplite } from '../../Components/CommonPopup';
import { viewPhone } from '../../Store/KalyanLite/Action';
import { useTranslation } from 'react-i18next';
import PhonePopupLite from '../../Components/DashboardComponents/PhonePopupLite';
import Loader from '../../Components/Loader';
import ImageHoroscopePopup from '../../Components/Edit Profile/ImageHoroscopePopup';
import Profilepreviewsection from '../../Components/Kalyanlite/Profilepreviewsection';
import { ImageViewer } from '../../Components/Kalyanlite/Common/CarouselWithNav';
import Phonenumberpopup from './Phonenumberpopup';
import ParentsPhonePopupLite from '../../Components/DashboardComponents/ParentsPhonePopupLite';
import HoroScopePopup from './HoroScopePopup';
import TrustDocPopup from './TrustDocPopup';
import { TrustDocumentPopup } from '../../Components/Edit Profile/TrustDocumentPopup';
import RouteSegments, { RouteHelper } from '../../Routes/RouteSegment';
import { useLocation, useNavigate } from 'react-router-dom';
import PreviewBasicDetails from '../../Components/Kalyanlite/PreviewBasicDetails';
import NavBar from '../../Components/NavBar';

function ProfilePreview() {
    const dispatch = useDispatch()

    const userData = useSelector(
        (state) => state?.DashBoardAPI?.ProfileData?.data,
    );

    const profiledata = useSelector(
        (state) => state?.profileData?.data,
    );
    const navigate = useNavigate();

    const { loginDetail, logout } = useContext(LoginContext);
    const [editMyProfielData, setEditMyProfielData] = useState('')
    const profileImages = editMyProfielData?.profileimages?.map(image => image.url) || []

    const isProfilePicAvailable = !editMyProfielData?.isAvatarPic;
    const isMobile = useMediaQuery({ maxWidth: 678 });
    const [imageViewerState, setImageViewerState] = useState({ show: false, initialIndex: 0 })
    const profileAvatars = profileImages?.filter(image => image !== userData?.profiledetails?.profileurl)
    const [showTrustPopup, setShowTrustPopup] = useState(false)
    const [addKjBanner, setAddKjBanner] = useState();
    const profileSelectedBy = userData?.profiledetails?.FamilySelect
    const { showbasicdetails, setShowBasicdetails } = useContext(NavBarContext)

    const [isEditProfileLoading, setIsEditProfileLoading] = useState(true);

    const profileData = useSelector(KalyanLiteSelector.getMatchProfileById);
    const [showUploadImagePopup, setShowUploadImagePopup] = useState(false);
    const [successMessage, setsuccessMessage] = useState("");
    const [showSuccessPopup, setshowSuccessPopup] = useState(false);



    console.log(profiledata, "profileDataprofileData1");

    const { t: trans } = useTranslation();
    const location = useLocation()

    const apiCallEditProfile = async (showHoroscope) => {
        setIsEditProfileLoading(true);

        let request = {
            email: loginDetail()[0],
            userId: loginDetail()[1],
            loginFrom: localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
        };

        let { statusCode, data } = await POST(
            `${config.api.API_URL}${EndPoints.editMyProfileUrl()}`,
            request
        );

        if (statusCode === 200) {
            setEditMyProfielData(data?.data);
            if (showHoroscope === true) {
                setgeneratedHoroscopeurl({ url: data?.data?.horoscope, flag: data?.data?.horoflag, show: true });
            }
        } else if (statusCode === 401) {
            logout();
        }
        setIsEditProfileLoading(false);

    };

    const openImagePreview = (isProfilePic) => {
        let initialIndex = 0;
        if (isProfilePic === true) {
            initialIndex = profileImages?.findIndex(data => data === editMyProfielData?.profiledetails?.profileurl)
        } else {
            initialIndex = profileImages?.findIndex(data => data === profileAvatars[0]);
        }
        setImageViewerState({ show: true, initialIndex })
    };

    const loggedInProfileStatus = userData?.status


    useEffect(() => {
        apiCallEditProfile()
        dispatch(dashboardProfileData())
    }, [])

    const OnClickBackToPreview = () => {
        setShowBasicdetails(false); // Return to the preview
        apiCallEditProfile()
    };

    const profileblockedByMe = profileData?.profiledetails?.profileblockedByMe === "Y";
    const [showUnBlockPopup, setShowUnBlockPopup] = useState(false)

    //for parents number
    const isParentsNumberAvailble =
        userData?.parentsNumberAvailable != "No";

    const isParentsNumberRequested =
        profileData?.profiledetails?.parentnumberrequestcheck === true;
    const [showAdminPopup, setShowAdminPopup] = useState(false)
    const [nonValidationPopupData, setNonValidationPopupData] = useState({ show: false, title: "", content: "" })
    const [showPhonePopup, setShowPhonePopup] = useState(false);
    const [phonePopupType, setPhonePopupType] = useState("")
    const [paynowPopupData, setPaynowPopupData] = useState({ show: false, title: "", content: "" })

    const viewPhoneData = profileData?.viewPhone;
    const loggedInUserPaid = userData?.isPaid === "Y"

    const matchProfiles = useSelector((state) => state.matchProfiles || {});
    const userPhoneData = matchProfiles[userData?.profileId]?.viewPhone;

    console.log(isParentsNumberAvailble, "isParentsNumberAvailble");
    const [phonedata, setphonedata] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const [phonenoData, setPhonenoData] = useState([])

    console.log(phonenoData, "phonenoData");


    const OnclickAddDetails = () => {
        setShowBasicdetails(true)
    }



    const onTrustDocumentClick = () => {
        if (editMyProfielData?.eduproof || editMyProfielData?.incomeproof || editMyProfielData?.idproof) {
            setShowTrustDataPopup(true)
            setShowTrustPopup(false)
        } else {
            setShowTrustPopup(true)
            // setShowTrustDataPopup(false)
        }


    }

    const apicallPhone = async (value) => {
        // const profileId = 1676459;
        setIsLoading(true)
        {
            let request = {

                toProfileId: userData?.profileId,
                "email": localStorage.getItem(Constants.loginLocalStorageKeys.loginName),
                "userId": localStorage?.getItem(Constants.loginLocalStorageKeys.loginId),
                "phoneViewFrom": localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
            };

            let { statusCode, data } = await POST(
                `${config.api.API_URL}${EndPoints.viewPhoneUrl()}`,
                request
            );


            if (statusCode === 200) {

                if (data.status === 'Success') {
                    setphonedata(data?.data)
                }
                setIsLoading(false)

            } else if (statusCode === 401) {
                logout();
            }

        }
    };


    const phoneviewdata = phonedata?.phoneNumberVieweeData

    console.log(phoneviewdata, phonedata?.statusmessage, "phonedata");

    const [showAddParentNo, SetShowAddParentNo] = useState(false)

    const onParentsNumberClick = () => {

        console.log(isParentsNumberAvailble, 'isParentsNumberAvailblev');

        if (isParentsNumberAvailble) {
            onPhoneViewClick("parents")
            return;
        }

        if (!isParentsNumberAvailble) {
            SetShowAddParentNo(true)
        }
        if (!isParentsNumberRequested) {
            if (getIsLoginFromAdmin()) {
                setShowAdminPopup(true)
                return false;
            }
            if (loggedInProfileStatus === "pending") {
                setNonValidationPopupData({
                    show: true,
                    title: trans('non_validation_popup.non_validated_user'),
                    content: trans('non_validation_popup__request.your_profile_is_under_validati')
                });
            }
            //   setParentsSuccessPopup(true)
            //   sendRequest("Parents Number");

            //   if ((isProfilePicAvailable == false && isProfilePicRequested == false) || profileData?.trustRequest === "N" || profileData?.horoscopeRequest === "N"){
            //     setShowPromptPopup(true)
            //   }
        }


    };
    const apiCallPhoneNumber = async () => {
        let request = {

            loginFrom: localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),

        };

        let { statusCode, data } = await POST(
            `${config.api.API_URL}${EndPoints.viewProfilesNumberUrl()}`,
            request
        );
        //console.log("statusCode", data?.data);
        if (statusCode === 200) {
            setPhonenoData(data?.data);
        } else if (statusCode === 401) {
            logout();
        }
    };


    const onPhoneViewClick = (type) => {


        if (profileblockedByMe) {
            setShowUnBlockPopup(true);
            return;
        }

        setPhonePopupType(type);

        if (viewPhoneData) {
            setShowPhonePopup(true);
        } else {
            apicallPhone();
            apiCallPhoneNumber()
            setShowPhonePopup(true);


        }
    };

    const [showHoroScopeLoader, setShowHoroScopeLoader] = useState(true)
    const [generatedHoroScopeUrl, setgeneratedHoroscopeurl] = useState({ url: "", flag: "", show: false });

    function formatLastSeen(lastSeenTimestamp) {
        const currentDate = new Date();
        const lastSeenDate = new Date(lastSeenTimestamp);

        console.log(lastSeenDate, "lastSeenDate");

        // Check if the user is online now
        if (lastSeenTimestamp === "Online Now") {
            return trans('view_profile.online');
        }

        // Check if the user was seen today
        if (
            currentDate.getFullYear() === lastSeenDate.getFullYear() &&
            currentDate.getMonth() === lastSeenDate.getMonth() &&
            currentDate.getDate() === lastSeenDate.getDate()
        ) {
            // Format time (e.g., Last seen today at 4:00 PM)
            return `Last seen today at ${lastSeenDate.toLocaleTimeString([], { hour: "numeric", hourCycle: "h12", minute: "2-digit" })}`;
        }

        // Check if the user was seen yesterday
        const yesterday = new Date(currentDate);
        yesterday.setDate(currentDate.getDate() - 1);

        if (
            yesterday.getFullYear() === lastSeenDate.getFullYear() &&
            yesterday.getMonth() === lastSeenDate.getMonth() &&
            yesterday.getDate() === lastSeenDate.getDate()
        ) {
            // Format time (e.g., Last seen yesterday at 6:00 PM)
            return `Last seen yesterday at ${lastSeenDate.toLocaleTimeString([], { hour: "numeric", hourCycle: "h12", minute: "2-digit" })}`;
        }

        // Format date as DD/MM/YY (e.g., Last seen at 2/7/23)
        const day = lastSeenDate.getDate().toString().padStart(2, "0");
        const month = (lastSeenDate.getMonth() + 1).toString().padStart(2, "0");
        const year = lastSeenDate.getFullYear().toString().slice(2);
        const lastseenText = trans('view_profile.last_seen_at');


        return `${lastseenText} ${day}/${month}/${year}`;
    }

    const [addHoroscope, SetAddHoroscope] = useState(false)


    const [horoscopeData, setHoroscopeData] = useState();

    useEffect(() => {
        console.log(horoscopeData, 'horoscopeDatab');

    }, [horoscopeData])

    const apiCallHoroscope = async () => {
        let request = {
            email: loginDetail()[0],
            userId: loginDetail()[1],
            loginFrom: localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
            frommanagePage: "Y",
        };

        let { statusCode, data } = await POST(
            `${config.api.API_URL}${EndPoints.getHoroscopeUrl()}`,
            request
        );
        //console.log("statusCode", data?.data);
        if (statusCode === 200) {
            setHoroscopeData(data?.data);
        } else if (statusCode === 401) {
            logout();
        }
    };






    console.log(editMyProfielData?.horoscope, "editMyProfielData?.horoscope");

    const callBackHoroscopePopup = () => {
        if (editMyProfielData?.horoscope !== "" && editMyProfielData?.horoscope !== undefined) {
            setgeneratedHoroscopeurl({ url: editMyProfielData?.horoscope, flag: editMyProfielData?.horoflag, show: true })
        }
        else {

            SetAddHoroscope(true)

            apiCallHoroscope();
        }
    }

    const onHoroScopeClick = () => {

        callBackHoroscopePopup()
    }
    const [showTrustDataPopup, setShowTrustDataPopup] = useState(false)

    const [notification, setNotification] = useState({ show: false, title: "", message: "" })

    const showNotification = ({ description = "", title = "Status!" }) => {
        setNotification({ show: true, title: title, message: description })
    }

    const callBackHoroscope = async (url, type) => {
        let request = {
            email: loginDetail()[0],
            userId: loginDetail()[1],
            imagePath: `${url}`,
            type: type
        };
        let { statusCode, data } = await POST(
            `${config.api.API_URL}${EndPoints.trustDoumentDeleteUrl()}`,
            request
        );
        if (statusCode === 200) {
            apiCallEditProfile();
            showNotification({ description: type == 'horoscope' ? "Horoscope deleted successfully" : "Document deleted successfully", title: trans('photos.success') })
        } else if (statusCode === 401) {
            logout();
        }
    }

    const closeNotification = () => {
        setNotification({ show: false, message: "", title: "" })
    }

    const closeImagePreview = () => {
        setImageViewerState({ show: false, initialIndex: 0 })
    };


    return (
        <>



            {imageViewerState?.show &&
                <ImageViewer
                    images={profileImages}
                    imageStatus={editMyProfielData?.profileimages?.map(image => image.status) || []}
                    closeModal={closeImagePreview}
                    isOpen={imageViewerState.show}
                    profileId={editMyProfielData?.profiledetails?.profileid}
                    initialIndex={imageViewerState.initialIndex}
                    from="profilePreviewPage"
                />}

            {showUploadImagePopup ? (
                <UploadPhotoPopuplite
                    close={(isCropped) => setShowUploadImagePopup(false)}
                    title={trans('photo_popup.add_photo')}
                    callBack={() => {
                        setsuccessMessage("Image uploaded successfully")
                        setshowSuccessPopup(true)
                        apiCallEditProfile();
                    }}
                    isFrom={"profilePreview"}
                    onCropClose={(isCloseByCrop) => {
                        if (isCloseByCrop === false) {
                            apiCallEditProfile()
                        }
                    }}
                />
            ) : (
                <></>
            )}

            {showSuccessPopup && (

                <CommonSuccessPopup
                    close={() => setshowSuccessPopup(false)}
                    title={trans('photos.success')}
                    content={successMessage}
                />
            )}

            {
                showTrustDataPopup && (
                    <TrustDocumentPopup
                        close={() => setShowTrustDataPopup(false)}
                        idproof={editMyProfielData?.idproof}
                        eduproof={editMyProfielData?.eduproof}
                        incomeproof={editMyProfielData?.incomeproof}
                        onButtonCallback={() => {

                            // navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[3].urlPath))
                        }}
                        callBackDelete={(url, selectedDoc) => {
                            let type;
                            if (selectedDoc == 'Add ID Proof') {
                                type = 'idproof';
                            }
                            else if (selectedDoc == 'Add Education Proof') {
                                type = 'educationproof';
                            }
                            else if (selectedDoc == 'Add Income Proof') {
                                type = 'incomeproof';
                            }
                            callBackHoroscope(url, type);
                        }}
                        deleteImage={true}
                        isFrom="ProfilePreview"
                    />
                )}


            {
                notification.show
                    ? <CommonSuccessPopup close={closeNotification} title={notification.title} content={notification.message} />
                    : null
            }

            {showTrustPopup &&
                <TrustDocPopup
                    editMyProfielData={editMyProfielData}
                    close={() => setShowTrustPopup(false)}
                    apiCallEditProfile={apiCallEditProfile}
                    isFrom="previewtrust"
                    setShowTrustPopup={setShowTrustPopup}

                />}

            {addHoroscope &&
                <HoroScopePopup from="trailpack" menu="addhoro"
                    apiCallEditProfile={(isGenerate, url) => {
                        SetAddHoroscope(false)
                        if (isGenerate === true) {
                            apiCallEditProfile(false).then(() => {
                                setEditMyProfielData(prev => ({ ...prev, horoscopeType: "GenerateHoroscope", horoscope: url }))
                                setgeneratedHoroscopeurl({ url, flag: "GenerateHoroscope", show: true });
                            })

                        } else {
                            apiCallEditProfile(true)
                        }
                    }}
                    data={editMyProfielData}
                    OnClose={() => SetAddHoroscope(false)}
                />
            }
            {showAddParentNo &&
                <ParentsPhonePopupLite close={() => SetShowAddParentNo(false)}
                    title={trans('clc_wizard_popup__parents_numb.add_your_parents_phone_number')}
                    callBackApply={() => { }}
                    parentsIsdCode={userData?.parentsIsdCode}
                    parentsMobileNumber={userData?.parentsMobileNumber}
                    isType="previewparent"
                />

            }
            {generatedHoroScopeUrl.show ? (

                <ImageHoroscopePopup
                    close={() => setgeneratedHoroscopeurl({ url: "", flag: "", show: false })}
                    title={trans('view_profile.view_horoscope')}

                    onButtonCallback={() => { }}
                    isShowEditBtn={false}>
                    <>
                        {editMyProfielData?.horoscopeType === "GenerateHoroscope" || editMyProfielData?.horoscopeType === "UploadHoroscope" ? (
                            <>
                                {showHoroScopeLoader === true ? (
                                    <div className='absolute maxabsolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                                        <Loader />
                                    </div>
                                ) : null}
                                <iframe
                                    title='Horoscope'
                                    onLoad={() => setShowHoroScopeLoader(false)}
                                    src={generatedHoroScopeUrl.url}
                                    className='w-[70vw] lg:w-[60vw] h-[70vh]'
                                />
                            </>
                        ) : (
                            <iframe
                                title='horoscope'
                                onLoad={() => setShowHoroScopeLoader(false)}
                                alt=''
                                src={generatedHoroScopeUrl.url}
                                className='w-[70vw] lg:w-[60vw] h-[70vh]'
                            />
                        )}


                    </>
                </ImageHoroscopePopup>
            ) : (
                <></>
            )}

            {showAdminPopup && <CommonProfilePopup
                close={() => setShowAdminPopup(false)}
                title={"Alert!"}
                content={"You cannot send message/request from Admin"} />
            }

            {nonValidationPopupData.show && <CommonValidPopup
                close={() => setNonValidationPopupData({ show: false })}
                title={nonValidationPopupData.title}
                content={nonValidationPopupData.content} />
            }

            {
                paynowPopupData.show && <CommonPayNowPopup
                    close={() => setPaynowPopupData({ show: false })}
                    title={paynowPopupData.title}
                    content={paynowPopupData.content}
                />
            }
            {showPhonePopup ?
                <Phonenumberpopup
                    phoneNumData={phoneviewdata}
                    phonedata={phonedata}
                    close={() => setShowPhonePopup(false)}
                    title={"View Phone Number"}
                    profileData={profileData}
                    isParents={phonePopupType === "parents"}
                    formatLastSeen={formatLastSeen}
                    isFrom="profilephone"
                    editMyProfielData={editMyProfielData}
                    isLoading={isLoading}
                    parentno={phonenoData?.parentsMobileNumber}
                    phoneno={phonenoData?.mobileNumber}

                /> : ""}

            <div className='bg-[#F2F1EF] '>
                <div className={`bg-white sticky top-0 z-10 shadow-[0_21px_11px_-15px_#BE363629] w-full bg-white lg:bg-white  md:mt-0 `}>

                    {localStorage.getItem("profiledot") === "true" ?
                        <div className=''>
                            <div className='md:hidden'>
                                <NavBar  isFrom="profilepreview"/>
                            </div>

                            <div className='hidden md:flex justify-between items-center mx-[2rem] md:ml-[4rem] md:mr-[5rem] lg:ml-[6rem] lg:mr-[10rem]'>
                                <a href="" className="flex items-center  py-3 ">
                                    <img src={KMLogo} className="h-12 md:h-[4rem]  lg:h-[3.5rem] " alt="" />

                                </a>
                                {showbasicdetails ? <div className=' justify-center md:justify-end  md:flex '>
                                    <button onClick={OnClickBackToPreview} className='bg-[#D10A11] cursor-pointer text-[#ffffff] flex gap-2 px-3 py-1 md:py-2 rounded-[5px] items-center font-bold'>
                                        <img className="w-6 h-6" src={Images.eyeicon} />Back To Preview</button></div>
                                    : 
                                        <div className=''><button onClick={OnclickAddDetails} className='flex rounded-[5px] gap-3 items-center text-[12px] bg-[#D10A11] px-3 py-1 md:py-2 md:text-[15px]   text-[#ffffff] font-bold cursor-pointer'>
                                            <img className='w-5 h-5' src={Images.addwhitegroup} />Add / Edit Details</button> </div>
                                }
                            </div>
                            </div> 
                            :
                            <div className='flex justify-between items-center mx-[2rem] md:ml-[4rem] md:mr-[5rem] lg:ml-[6rem] lg:mr-[10rem]'>
                                <a href="" className="flex items-center  py-3 ">
                                    <img src={KMLogo} className="h-12 md:h-[4rem]  lg:h-[3.5rem] " alt="" />

                                </a>
                                {showbasicdetails ? <div className=' justify-center md:justify-end  md:flex '>
                                    <button onClick={OnClickBackToPreview} className='bg-[#D10A11] cursor-pointer text-[#ffffff] flex gap-2 px-3 py-1 md:py-2 rounded-[5px] items-center font-bold'>
                                        <img className="w-6 h-6" src={Images.eyeicon} />Back To Preview</button></div>
                                    : localStorage.getItem("profiledot") === "true" ? "" :
                                        <div className=''><button onClick={OnclickAddDetails} className='flex rounded-[5px] gap-3 items-center text-[12px] bg-[#D10A11] px-3 py-1 md:py-2 md:text-[15px]   text-[#ffffff] font-bold cursor-pointer'>
                                            <img className='w-5 h-5' src={Images.addwhitegroup} />Add / Edit Details</button> </div>
                                }
                            </div>
                       

                    }


                    {localStorage.getItem("profiledot") === "true" &&

                        <div className='bg-[#D10A11] md:hidden'>
                            <div className='flex justify-around py-2 items-center mx-2'>
                                {showbasicdetails ?
                                    <button onClick={OnClickBackToPreview} className=''><img className='w-5 h-5' src={Images.whitearrowicon} /> </button>
                                    :
                                    <button onClick={() => {
                                        const prevPage = localStorage.getItem("previousPage");
                                        if (prevPage) {
                                        navigate(prevPage); // Navigates without reloading
                                        }
                                    }} className=''><img className='w-5 h-5' src={Images.whitearrowicon} /> </button>
                                }
                                <p className='text-[#ffffff] font-bold'>Your Profile Preview</p>
                                {showbasicdetails ? <div className=' justify-center md:justify-end  md:flex '>
                                    <button onClick={OnClickBackToPreview} className='flex rounded-[5px] gap-2 items-center text-[11px] bg-[#8292A8] px-3  py-[0.2rem] md:text-[15px]   text-[#ffffff] font-bold cursor-pointer'>
                                        <img className="w-6 h-6" src={Images.whiteeyeicon} />Back To Preview</button></div>
                                    :
                                    <div className=''><button onClick={OnclickAddDetails} className='flex rounded-[5px] gap-2 items-center text-[11px] bg-[#8292A8] px-3 py-[0.4rem] md:py-2 md:text-[15px]   text-[#FFFFFF] font-bold cursor-pointer'>
                                        <img className='w-[1rem] h-[1rem]' src={Images.blockplusicon} />Add / Edit Details</button> </div>}


                            </div>

                        </div>
                       

                    }





                </div>

                <div className='    bg-[#F2F1EF] pb-[1rem] md:pb-0  mt-2 md:mt-[1rem]       '>
                    <div className='bg-[#F2F1EF] px-[2rem] md:px-0 xl:mx-[10rem] md:mx-[5rem]     '>
                        <p className={`text-[#D10A11] text-[20px] ${localStorage.getItem("profiledot") === "true" ?"hidden md:flex":"flex"} md:text-[20px] font-bold` }>Your Profile Preview!</p>
                        <p className={`text-[#000000] ${localStorage.getItem("profiledot") === "true"? "pt-2":""} text-[13px] md:text-[15px]`}>Completing your profile increases your chances of getting more responses!</p>
                    </div>
                </div>


                <div className=" mx-[1.75rem] xl:mx-[10rem] md:mx-[5rem]  md:mt-[1rem]  ">
                    {isEditProfileLoading ? <Loader className="loaderTransperancy" loaderClassName="loaderTransperancySpin" /> : null}

                    {/* {showbasicdetails ?
                            <div className='  mb-4 md:hidden '><button onClick={OnClickBackToPreview} className='bg-[#D10A11] w-[100%] cursor-pointer text-[#ffffff] flex gap-2 px-3 py-2 justify-center items-center font-bold'>
                                <img className="w-6 h-6" src={Images.eyeicon} />Back To Preview</button></div> : ""} */}
                    {!editMyProfielData ? <Loader className="loaderTransperancy" loaderClassName="loaderTransperancySpin" /> :
                        <div className="">


                            <div className={`w-full h-full flex flex-col `}>
                                <div className='mb-[2rem] bg-white'>


                                    <div className=' mx-[1rem] md:mx-[5rem]  md:pt-[3rem]  relative'>
                                        <div className='flex'>
                                            {/* Content  */}
                                            <div className='w-full '>
                                                <div className='relative'>

                                                    <div className='grid lg:grid-cols-5 lg:bg-[#F9DADB] lg:shadow-[0_-1px_11px_-3px_#FC979A]'>
                                                        <div className={`flex col-span-3 w-[100%] relative ${isProfilePicAvailable ? "h-[18rem]" : "md:h-[18rem]"}`}>
                                                            <>
                                                                <div className={`w-[100%] ${!isProfilePicAvailable ? "flex justify-center bg-white lg:rounded-br-[40px] md:h-[100%] h-[9rem]" : "h-[100%]"}`}>
                                                                    <ProcessImage
                                                                        blurPhoto={userData?.blurPhoto}
                                                                        containerClassName={`w-full md:w-full lg:rounded-br-[3rem] ${isProfilePicAvailable ? "bg-white h-full" : "h-[9rem]"}`}
                                                                        lockClassName="h-[46px]"
                                                                        alreadyContacted={userData?.alreadyContected}
                                                                        passwordProtected={userData?.fromPasswordProtected}
                                                                        onClick={() => {
                                                                            if (isProfilePicAvailable) {
                                                                                openImagePreview(true)
                                                                            }
                                                                        }}
                                                                        imageClassName=" before:content-['kalyanmatrimony.com'] 
                                                                        before:absolute  before:origin-left before:origin-right  before:-translate-x-1/2 
                                                                        before:rotate-[-90deg] before:text-white before:text-lg md:before:text-base sm:before:text-sm
                                                                        before:font-bold before:opacity-90  before:px-2 before:py-1 before:rounded 
                                                                        before:pointer-events-none before:tracking-[4px] before:z-10 "
                                                                                                isFrom="profilesection"
                                                                        // onError={handleError}
                                                                        isAvatarPic={userData?.profiledetails?.isAvatarPic}
                                                                        className={`${isProfilePicAvailable ? "w-[100%] h-[100%] lg:rounded-br-[40px] object-contain" : " object-contain w-[10rem] md:w-[26rem] h-auto md:h-[18rem]   mx-auto lg:mt-[2px] lg:pr-[22px] pt-2"}`}
                                                                        src={editMyProfielData?.profilepic}
                                                                        alt=""
                                                                    />


                                                                    <div onClick={() => {
                                                                        setShowUploadImagePopup(true)
                                                                    }} className={` ${editMyProfielData?.profileimages ? "flex items-center cursor-pointer absolute bottom-[30%] right-0 justify-center left-0  mr-1" : " lg:mr-[2rem] flex items-center cursor-pointer absolute bottom-[30%] right-0 justify-center left-0"} `}>
                                                                        <div
                                                                            className={`shrink-0 border border-b-[#D52126] border-l-[#D52126] border-r-[#D52126] border-t-[#D52126] flex items-center justify-center z-0 rounded-[2rem] w-[34px]  bg-white border-green-500 h-[33px]`}
                                                                        >
                                                                            <img
                                                                                src={Images.RequestCameraIcon}
                                                                                className={`w-4 h-4 bg-white border-green-500`}
                                                                                alt='Select Icon'
                                                                            />
                                                                        </div>
                                                                        <button
                                                                            className={`ml-[-2rem] flex items-center w-max text-[#D52126] text-[13px] lg:text-[10px] xl:text-[13px] rounded-xl border border-[#D52126] lg:h-[33px] xl:h-[33px] bg-white`}
                                                                            onClick={() => {
                                                                                setShowUploadImagePopup(true)
                                                                            }}
                                                                        >

                                                                            <p
                                                                                className={`lg:pl-[0.5rem] pr-0 xl:pl-3  pl-3  py-1 mx-8  text-[#000000] w-[7rem]`}>
                                                                                {editMyProfielData?.profileimages ? 'Add More Photo' : 'Add Photo'}
                                                                            </p>
                                                                        </button>
                                                                    </div>
                                                                </div>

                                                            </>


                                                            <div className={`cursor-pointer flex absolute lg:bottom-[1rem] w-full  ${isProfilePicAvailable ? "mt-[13.8rem]" : "mt-0 md:mt-[13.8rem]"}`}>

                                                                {isProfilePicAvailable ? (
                                                                    <div className={`flex -space-x-4 rtl:space-x-reverse justify-end md:justify-center items-end md:items-center w-full pt-4 pb-3 pr-4 lg:pb-0 lg:pr-0 profile-avatar-group `}>
                                                                        {profileAvatars?.slice(0, 3)?.map((image, index) => {
                                                                            return (
                                                                                userData?.fromPasswordProtected == "Y" ?
                                                                                    <img
                                                                                        key={index}
                                                                                        alt=''
                                                                                        onClick={() => openImagePreview(false)}
                                                                                        className={`w-12 h-12 border-2 border-white rounded-full dark:border-gray-800  z-${9 - index * 10} `}
                                                                                        src={profileAvatars[index]}
                                                                                        style={{ filter: loggedInProfileStatus === "pending" ? 'blur(2px)' : 'cursor-pointer' }}
                                                                                    />
                                                                                    :
                                                                                    <img
                                                                                        key={index}
                                                                                        onClick={() => openImagePreview(false)}
                                                                                        alt=''
                                                                                        className={`w-12 h-12 border-2 border-white rounded-full dark:border-gray-800  z-${9 - index * 10} `}
                                                                                        src={profileAvatars[index]}
                                                                                        style={{ filter: loggedInProfileStatus === "pending" ? 'blur(2px)' : 'cursor-pointer' }}
                                                                                    />
                                                                            );
                                                                        })}

                                                                        {profileAvatars?.length > 3 && (
                                                                            <p
                                                                                onClick={openImagePreview}
                                                                                className={`flex items-center justify-center w-12 h-12 text-xs font-medium text-white bg-gray-700 border-2 border-white rounded-full hover:bg-gray-600 dark:border-gray-800  ${loggedInProfileStatus === "pending" ? 'blur-text' : 'cursor-pointer'}`}
                                                                            >
                                                                                {"+"} {profileAvatars.length - 3}
                                                                            </p>
                                                                        )}
                                                                    </div>
                                                                ) : null}
                                                            </div>

                                                            {/* {!isProfilePicAvailable ? (
                                                                <div className="absolute bottom-[2.5rem] lg:bottom-[4rem] left-[5px] right-0 w-full flex justify-center text-wrap">
                                                                    <div className='flex items-center gap-2 md:gap-4 lg:gap-2'>
                                                                        <div
                                                                            className="shrink-0 border border-[#D52126] flex items-center justify-center z-0 rounded-[2rem] w-[34px] bg-white h-[33px]"
                                                                        >
                                                                            <img
                                                                                src={Images.RequestCameraIcon}
                                                                                className="w-4 h-4 bg-white"
                                                                                alt=''
                                                                            />
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            ) : null} */}
                                                        </div>

                                                        <div className={"lg:bg-[#F9DADB] col-span-2  mt-[1rem] md:mt-0 lg:h-[18rem]"}>
                                                            <div className={'md:pt-4  lg:hidden md:block md:mr-5'}>

                                                                {
                                                                    <div className='flex pb-3 lg:pb-0 lg:mx-0 items-center md:pt-[1rem] lg:pt-0'>
                                                                        <p className='lg:mx-1 w-full text-[#1A1A1A] text-[16px] font-semibold'>
                                                                            {editMyProfielData?.name}
                                                                        </p>
                                                                    </div>
                                                                }
                                                                <div className='absolute    lg:mx-0 lg:mx-12 space-y-1' style={{ marginTop: '-0.8rem' }}>
                                                                    <p className='text-[#8292A8] text-[14px]'>{`Profile ID - ${editMyProfielData?.smProfileId}`}</p>
                                                                    <p className='text-[#8292A8] text-[14px] whitespace-pre-line'>
                                                                        {editMyProfielData.age},{" "}
                                                                        {editMyProfielData?.height?.at(0)?.name.replace("ft", "'").replace("in", '"')}, {" "}
                                                                        {editMyProfielData?.caste?.at(0)?.name}{" "},
                                                                    </p>
                                                                    <p className='text-[#8292A8] text-[14px]'>
                                                                        {" "}
                                                                        {editMyProfielData?.education?.at(0).name},{" "}
                                                                        {editMyProfielData?.occupation?.at(0).name},{" "}
                                                                    </p>
                                                                    <p className='text-[#8292A8] text-[14px] break-words w-[18rem] md:w-full'>

                                                                        {editMyProfielData?.city?.at(0).name},{" "}
                                                                        {editMyProfielData?.state?.at(0).name}{" "}
                                                                    </p>
                                                                </div>
                                                                {/*  */}


                                                            </div>


                                                            <p className="border-[#E9E9E9] border-b-[1px] w-full my-2  md:mx-0 lg:mx-7 mx-7 hidden lg:hidden md:block mt-[1rem]"></p>
                                                            <div className='hidden lg:block'>
                                                                <div className='flex flex-col mt-8 space-y-2 lg:mt-3'>

                                                                    <div className='relative place-self-end w-[114%] mb-2'>
                                                                        <div className='flex justify-between bg-white shadow shadow-[#FC979A] py-3 pr-4 pl-8 rounded-tl-full w-full'>
                                                                            <p className='text-[#1A1A1A] text-[13px] lg:text-[12px] xl:text-[13px]' >
                                                                                {editMyProfielData?.name}
                                                                            </p>


                                                                        </div>
                                                                    </div>
                                                                    {/* <p className='text-[#1A1A1A] font-Poppins-Regular px-5 text-[13px] xl:text-[14px] lg:text-[12px] mx-4'>

                                                                        {editMyProfielData?.name} {" "}, </p> */}
                                                                    <p className='text-[#1A1A1A] font-Poppins-Regular px-5 text-[13px] xl:text-[14px] lg:text-[12px] mx-4'>
                                                                        Profile  ID- {`${editMyProfielData?.smProfileId}`}</p>
                                                                    <p className='text-[#1A1A1A] font-Poppins-Regular px-5 text-[13px] xl:text-[14px] lg:text-[12px] mx-4'>
                                                                        {editMyProfielData.age},{" "}
                                                                        {editMyProfielData?.height?.at(0)?.name.replace("ft", "'").replace("in", '"')}, {" "}
                                                                        {editMyProfielData?.caste?.at(0)?.name}{" "},
                                                                    </p>
                                                                    <p className='text-[#1A1A1A] font-Poppins-Regular px-5 text-[13px] xl:text-[14px] lg:text-[12px] mx-4'>
                                                                        {" "}
                                                                        {editMyProfielData?.education?.at(0).name},{" "}
                                                                    </p>
                                                                    <p className='text-[#1A1A1A] font-Poppins-Regular px-5 text-[13px] xl:text-[14px] lg:text-[12px] mx-4'>
                                                                        {editMyProfielData?.occupation?.at(0).name},{" "}
                                                                    </p>
                                                                    <p className='text-[#1A1A1A] font-Poppins-Regular px-5 text-[13px] xl:text-[14px] lg:text-[12px] mx-4'>
                                                                        {editMyProfielData?.city?.at(0).name},{" "}
                                                                    </p>
                                                                    <p className='text-[#1A1A1A] font-Poppins-Regular px-5 text-[13px] xl:text-[14px] lg:text-[12px] mx-4'>
                                                                        {editMyProfielData?.state?.at(0).name} {" "}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='flex gap-3 w-full px-5 md:px-0 lg:gap-0 md:space-x-5 lg:space-x-1 mt-[4.5rem] pt-[2.25rem]  md:pt-[3.25rem] md:mt-[4.5rem] lg:pt-0 lg:mt-0 md:pb-3 lg:pb-0 lg:mt-[1rem] select-section'>

                                                        <div className='grid grid-flow-col  justify-start  md:space-x-5 space-x-3 text-[12px] lg:hidden'>

                                                            <div className='flex flex-col items-center'
                                                                onClick={(e) => { onPhoneViewClick("phone") }}
                                                            >
                                                                <div className='flex flex-col  lg:hidden justify-center items-center bg-[#E2E8F0] rounded-full w-[3rem] h-[3rem]'>
                                                                    <img
                                                                        src={Images.greencallicon}
                                                                        alt=''
                                                                        className='w-5 h-5'
                                                                    />
                                                                </div>
                                                                <div className=' flex-col hidden lg:flex justify-center items-center bg-[#E2E8F0] rounded-full w-[3rem] h-[3rem]'>
                                                                    <img
                                                                        src={phonenum}
                                                                        alt=''
                                                                        className='w-5 h-5'
                                                                    />
                                                                </div>
                                                                <p className='text-[#039511] pt-1 md:pt-0 text-center text-[10px] md:text-[14px] md:w-[5rem] flex-col justify-center flex items-center'>
                                                                    Your Phone Number
                                                                </p>
                                                            </div>


                                                            <div
                                                                onClick={() => { onParentsNumberClick() }}
                                                                className={`${isParentsNumberAvailble ? 'text-white bg-[#37B37F]' : isParentsNumberRequested ? 'text-white bg-[#37B37F]' : 'text-black bg-[#D5D5D5]'}cursor-pointer flex flex-col justify-center items-center select-gap`}>


                                                                {isParentsNumberAvailble ?

                                                                    <div className='flex flex-col justify-center items-center bg-[#E2E8F0] rounded-full w-[3rem] h-[3rem]'>
                                                                        <><img className='w-5 h-5 hidden lg:block' src={parantnum} alt="" />
                                                                            <img className='w-5 h-5 lg:hidden' src={Images.viewparentno} alt='viewicon' /></>


                                                                    </div>
                                                                    :
                                                                    <div className=' flex flex-col justify-center items-center bg-[#E2E8F0] rounded-full w-[3rem] h-[3rem]'>

                                                                        <div className="lg:hidden relative w-[3rem] h-[3rem] lg:w-[3rem] lg:h-[3rem] xl:w-[3.5rem] xl:h-[3.5rem]">
                                                                            <img
                                                                                className="absolute -top-[10px] right-0 w-[1.5rem] h-[1.5rem]"
                                                                                src={Images.addgroupicon}
                                                                                alt="Badge Icon"
                                                                            />
                                                                            <p className='flex justify-center items-center bg-[#E2E8F0] rounded-full w-[3rem] h-[3rem] lg:w-[3rem] lg:h-[3rem] xl:w-[3.5rem] xl:h-[3.5rem]'><img className='w-5 h-5' src={Images.graycallicon} alt='addicon' /></p></div>
                                                                        <img className='w-5 h-5 hidden lg:block' src={Images.graycallicon} alt='addicon' />
                                                                    </div>
                                                                }
                                                                <div className={`flex flex-col  pt-1 md:pt-0  md:w-[6rem]  lg:w-full text-[10px] md:text-[14px] text-center ${isParentsNumberAvailble ? "text-[#039511] " : "text-[#D10A11]"}  justify-center items-center`}>
                                                                    {isParentsNumberAvailble
                                                                        ? "Your Parent’s Number"
                                                                        : "Add Parent’s Number"}
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className='lg:hidden flex items-start lg:px-8 md:mr-5 space-x-4 md:space-x-9 select-horo'>

                                                            <div
                                                                onClick={onHoroScopeClick}
                                                                className='flex flex-col items-center justify-center cursor-pointer '>
                                                                {!editMyProfielData?.horoscope ?


                                                                    <div onClick={() => { onHoroScopeClick() }} className="relative w-[3rem] h-[3rem] lg:w-[3rem] lg:h-[3rem] xl:w-[3.5rem] xl:h-[3.5rem]">
                                                                        <img
                                                                            className="absolute -top-[10px] right-0 w-[1.5rem] h-[1.5rem]"
                                                                            src={Images.addgroupicon}
                                                                            alt="Badge Icon"
                                                                        />
                                                                        <p className='flex justify-center items-center bg-[#D5D5D5] rounded-full w-[3rem] h-[3rem] lg:w-[3rem] lg:h-[3rem] xl:w-[3.5rem] xl:h-[3.5rem]'>

                                                                            <img
                                                                                className="w-8 h-8"
                                                                                src={grayicon}
                                                                                alt='Green Icon'
                                                                            />

                                                                        </p></div>
                                                                    :
                                                                    <p className='flex justify-center items-center bg-[#D5D5D5] rounded-full w-[3rem] h-[3rem] lg:w-[3rem] lg:h-[3rem] xl:w-[3.5rem] xl:h-[3.5rem]'>
                                                                        <img
                                                                            className="w-8 h-8"
                                                                            src={Images.viewhoro}
                                                                            alt='Gray Icon'
                                                                        />

                                                                    </p>}
                                                                <p
                                                                    className={
                                                                        editMyProfielData?.horoscopeRequest === "" ? "pt-1  flex justify-center items-center text-center lg:text-[10px] xl:text-[12px] text-[12px]  text-[#039511]" : "pt-1  flex justify-center items-center text-center lg:text-[10px] xl:text-[12px] text-[12px]  text-[#333333]"
                                                                    }
                                                                >
                                                                    {!editMyProfielData?.horoscope ? (
                                                                        <div>
                                                                            <p className="block lg:pl-8 text-[#D10A11] text-[10px] md:text-[14px] lg:px-3">

                                                                                Add Horoscope
                                                                            </p>
                                                                        </div>
                                                                    ) : (
                                                                        <p className=" md:pl-0 lg:pl-0 lg:pr-[0.1rem] text-[#039511] text-[10px] md:text-[14px]">View Horoscope</p>

                                                                    )}
                                                                </p>
                                                            </div>
                                                            <div
                                                                onClick={onTrustDocumentClick}
                                                                className='flex flex-col items-center justify-center cursor-pointer '>

                                                                {editMyProfielData?.eduproof || editMyProfielData?.incomeproof || editMyProfielData?.idproof ?


                                                                    <p className='flex justify-center items-center bg-[#D5D5D5] rounded-full w-[3rem] h-[3rem] lg:w-[3rem] lg:h-[3rem] xl:w-[3.5rem] xl:h-[3.5rem]'>

                                                                        <img
                                                                            className={" w-8 h-8"
                                                                            }
                                                                            src={Images.viewtrust}
                                                                            alt='Green Icon' />

                                                                    </p> :
                                                                    <div onClick={onTrustDocumentClick} className="relative w-[3rem] h-[3rem] lg:w-[3rem] lg:h-[3rem] xl:w-[3.5rem] xl:h-[3.5rem]">
                                                                        <img
                                                                            className="absolute -top-[10px] right-0 w-[1.5rem] h-[1.5rem]"
                                                                            src={Images.addgroupicon}
                                                                            alt="Badge Icon"
                                                                        />

                                                                        <p className='flex justify-center items-center bg-[#D5D5D5] rounded-full w-[3rem] h-[3rem] lg:w-[3rem] lg:h-[3rem] xl:w-[3.5rem] xl:h-[3.5rem]'>

                                                                            <img
                                                                                className={" w-8 h-8"
                                                                                }
                                                                                src={Images.addtrust}
                                                                                alt='Green Icon'
                                                                            />

                                                                        </p> </div>}
                                                                <p
                                                                    className={
                                                                        userData?.trustRequest === "" ? "pt-1  flex justify-center items-center text-center lg:text-[10px] xl:text-[12px] text-[12px]  text-[#039511]"
                                                                            : "pt-1  flex justify-center items-center text-center lg:text-[10px] xl:text-[12px] text-[12px]  text-[#333333]"
                                                                    }
                                                                >

                                                                    {editMyProfielData?.eduproof || editMyProfielData?.incomeproof || editMyProfielData?.idproof ?
                                                                        <p className=" lg:whitespace-nowrap  text-[#039511] text-[10px] md:text-[14px]">
                                                                            View Trust Document</p>
                                                                        :
                                                                        <div>
                                                                            <p className=" text-[#D10A11] text-[10px] md:text-[14px] lg:whitespace-nowrap md:block">
                                                                                Add Trust Document
                                                                            </p>

                                                                        </div>
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className='hidden lg:block'>
                                                    <div className=''>
                                                        <div className='grid lg:grid-cols-12 w-full  py-6 mb-[-1rem] border-[#E9E9E9] border-b-[2px] '>
                                                            <div className='grid col-span-4 gap-4 lg:grid-rows-2'>
                                                                <button
                                                                    onClick={(e) => {
                                                                        onPhoneViewClick("phone")
                                                                    }}
                                                                    className='h-[40px] bg-[#37B37F] text-white xl:px-5 px-3 py-2 flex rounded-xl text-[13px] xl:text-[14px] lg:text-[13px] items-center'>
                                                                    {" "}
                                                                    {/* <img className='w-5 h-5' src={Images.greencallicon} alt="" /> */}

                                                                    <img
                                                                        src={Images.greencallicon}
                                                                        alt=''
                                                                        className='w-5 h-5 lg:hidden'
                                                                    />
                                                                    <img
                                                                        src={phonenum}
                                                                        alt=''
                                                                        className='w-5 h-5 hidden lg:block'
                                                                    />
                                                                    <p className='pl-2 truncate'>
                                                                        {/* <Profilepreviewsection/> */}
                                                                        Your Phone Number</p>
                                                                </button>

                                                                <button
                                                                    onClick={() => { onParentsNumberClick() }}
                                                                    className={`h-[40px] ${isParentsNumberAvailble
                                                                        ? 'text-white bg-[#37B37F]'
                                                                        : isParentsNumberRequested
                                                                            ? 'text-black bg-[#D5D5D5]'
                                                                            : 'text-black bg-[#D5D5D5]'
                                                                        } xl:px-5 px-3 py-2 flex rounded-xl text-[12px] xl:text-[14px] lg:text-[12px] items-center whitespace-nowrap md:pr-6`}
                                                                >
                                                                    {isParentsNumberAvailble ? (
                                                                        <>

                                                                            <img className='w-5 h-5 hidden lg:block' src={parantnum} alt="" />
                                                                            <img className='w-5 h-5 lg:hidden' src={Images.viewparentno} alt='viewicon' /></>
                                                                    ) :
                                                                        <img className='w-5 h-5' src={Images.graycallicon} alt='addicon' />
                                                                    }
                                                                    <OverflowToolTip as="p" className='pl-2 truncate'>
                                                                        {isParentsNumberAvailble
                                                                            ? "Your Parent's Number"
                                                                            : "Add Parent’s Number"}
                                                                    </OverflowToolTip>
                                                                </button>

                                                            </div>
                                                            <div className='col-span-2'>

                                                                <div className='flex justify-evenly space-x-8 items-start text-[11px] text-[#7A7A7A] w-full'>
                                                                    <div
                                                                        onClick={onHoroScopeClick}
                                                                        className='flex flex-col items-center justify-center cursor-pointer '>
                                                                        {!editMyProfielData?.horoscope ?
                                                                            <div onClick={onHoroScopeClick} className="relative w-[3rem] h-[3rem] lg:w-[3rem] lg:h-[3rem] xl:w-[3.5rem] xl:h-[3.5rem]">
                                                                                <img
                                                                                    className="absolute -top-[10px] right-0 w-[1.5rem] h-[1.5rem]"
                                                                                    src={Images.addgroupicon}
                                                                                    alt="Badge Icon"
                                                                                />

                                                                                <p className="flex justify-center items-center bg-[#D5D5D5] rounded-full w-[3rem] h-[3rem] lg:w-[3rem] lg:h-[3rem] xl:w-[3.5rem] xl:h-[3.5rem]">
                                                                                    <img className="w-8 h-8" src={grayicon} alt="Gray Icon" />

                                                                                </p>
                                                                            </div> :
                                                                            <div className="">


                                                                                <p className="flex justify-center items-center bg-[#D5D5D5] rounded-full w-[3rem] h-[3rem] lg:w-[3rem] lg:h-[3rem] xl:w-[3.5rem] xl:h-[3.5rem]">

                                                                                    <img className="w-8 h-8" src={Images.viewhoro} alt="Green Icon" />

                                                                                </p>
                                                                            </div>}

                                                                        <p
                                                                            className={
                                                                                editMyProfielData?.horoscopeRequest === "" ? "pt-1  flex justify-center items-center text-center lg:text-[10px] xl:text-[12px] text-[12px]  text-[#039511]" : "pt-1  flex justify-center items-center text-center lg:text-[10px] xl:text-[12px] text-[12px]  text-[#333333]"
                                                                            }
                                                                        >

                                                                            {!editMyProfielData?.horoscope ? (
                                                                                <>
                                                                                    <div>
                                                                                        <p className="hidden md:block md:pl-8 lg:px-3  text-[#D10A11] text-[10px] md:text-[14px] ">
                                                                                            Add Horoscope
                                                                                        </p>
                                                                                        <p className="block md:hidden  text-[#D10A11] text-[10px] md:text-[14px]">
                                                                                            Add Horoscope
                                                                                        </p>
                                                                                    </div>
                                                                                </>
                                                                            ) : (
                                                                                <p className="hidden md:block md:pl-8 lg:pl-0 lg:pr-[0.1rem] text-[10px] md:text-[14px]  text-[#039511]">View Horoscope</p>

                                                                            )}
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div className='col-span-2'>

                                                                <div className='relative flex flex-col items-center'>
                                                                    <div className='flex justify-evenly space-x-8 items-start text-[11px] text-[#7A7A7A] w-full'>
                                                                        <div
                                                                            onClick={onTrustDocumentClick}
                                                                            className='flex flex-col items-center justify-center cursor-pointer '>
                                                                            {editMyProfielData?.eduproof || editMyProfielData?.incomeproof || editMyProfielData?.idproof ?


                                                                                <p className='flex justify-center items-center bg-[#D5D5D5] rounded-full w-[3rem] h-[3rem] lg:w-[3rem] lg:h-[3rem] xl:w-[3.5rem] xl:h-[3.5rem]'>

                                                                                    <img
                                                                                        className={" w-8 h-8"
                                                                                        }
                                                                                        src={Images.viewtrust}
                                                                                        alt='Green Icon' />

                                                                                </p> :
                                                                                <div onClick={onTrustDocumentClick} className="relative w-[3rem] h-[3rem] lg:w-[3rem] lg:h-[3rem] xl:w-[3.5rem] xl:h-[3.5rem]">
                                                                                    <img
                                                                                        className="absolute -top-[10px] right-0 w-[1.5rem] h-[1.5rem]"
                                                                                        src={Images.addgroupicon}
                                                                                        alt="Badge Icon"
                                                                                    />

                                                                                    <p className='flex justify-center items-center bg-[#D5D5D5] rounded-full w-[3rem] h-[3rem] lg:w-[3rem] lg:h-[3rem] xl:w-[3.5rem] xl:h-[3.5rem]'>

                                                                                        <img
                                                                                            className={" w-8 h-8"
                                                                                            }
                                                                                            src={Images.addtrust}
                                                                                            alt='Green Icon'
                                                                                        />

                                                                                    </p> </div>}
                                                                            <p
                                                                                className={
                                                                                    userData?.trustRequest === "" ? "pt-1  flex justify-center items-center text-center lg:text-[10px] xl:text-[12px] text-[12px]  text-[#039511]" : "pt-1  flex justify-center items-center text-center lg:text-[10px] xl:text-[12px] text-[12px]  text-[#333333]"
                                                                                }
                                                                            >

                                                                                {editMyProfielData?.eduproof || editMyProfielData?.incomeproof || editMyProfielData?.idproof ?
                                                                                    <p className="hidden lg:whitespace-nowrap md:block  text-[10px] md:text-[14px] text-[#039511]">
                                                                                        View Trust Document</p>
                                                                                    :
                                                                                    <div>
                                                                                        <p className=" text-[#D10A11] text-[10px] md:text-[14px] lg:whitespace-nowrap md:block">
                                                                                            Add Trust Document
                                                                                        </p>

                                                                                    </div>
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                                {/*  */}
                                                <div className='lg:hidden md:hidden'>
                                                    {localStorage.getItem("profiledot") === "true" ? "" :

                                                        <div className={'py-3 mt-[1rem] md:mt-0'}>
                                                            <p className='border-[#E9E9E9] border-b-[1px] w-[90%] mx-5'></p>
                                                            {userData?.aboutprofile == null || userData?.aboutprofile === "Not Specified" || userData?.aboutprofile.trim() == "" ?
                                                                (<>
                                                                </>) :
                                                                <div className='px-4 py-5'>
                                                                    <p className='px-4 md:px-0 text-[#1A1A1A] text-[20px] tracking-wide font-Poppins font-semibold '>About Me</p>

                                                                    <p className='text-[#8292A8] text-[14px] mx-4 md:mx-3'>
                                                                        {userData?.aboutprofile || "Not Specified"}
                                                                    </p>
                                                                    <button><img src={Images.addgroupicon} />Add / Edit Details</button>
                                                                </div>}
                                                            <p className='border-[#E9E9E9] border-b-[1px] w-[90%] mx-5'></p>
                                                        </div>}
                                                    <div className='hidden md:flex items-start md:px-8 md:mr-5 space-x-9 select-horo'>

                                                        <div
                                                            onClick={onHoroScopeClick}
                                                            className='flex flex-col items-center justify-center cursor-pointer '>
                                                            {!editMyProfielData?.horoscope ?


                                                                <div className="relative w-[3rem] h-[3rem] lg:w-[3rem] lg:h-[3rem] xl:w-[3.5rem] xl:h-[3.5rem]">
                                                                    <img
                                                                        className="absolute -top-[10px] right-0 w-[1.5rem] h-[1.5rem]"
                                                                        src={Images.addgroupicon}
                                                                        alt="Badge Icon"
                                                                    />
                                                                    <p className='flex justify-center items-center bg-[#D5D5D5] rounded-full w-[3rem] h-[3rem] lg:w-[3rem] lg:h-[3rem] xl:w-[3.5rem] xl:h-[3.5rem]'>

                                                                        <img
                                                                            className="w-8 h-8"
                                                                            src={grayicon}
                                                                            alt='Green Icon'
                                                                        />

                                                                    </p></div>
                                                                :
                                                                <p className='flex justify-center items-center bg-[#D5D5D5] rounded-full w-[3rem] h-[3rem] lg:w-[3rem] lg:h-[3rem] xl:w-[3.5rem] xl:h-[3.5rem]'>
                                                                    <img
                                                                        className="w-8 h-8"
                                                                        src={Images.viewhoro}
                                                                        alt='Gray Icon'
                                                                    />

                                                                </p>}
                                                            <p
                                                                className={
                                                                    editMyProfielData?.horoscopeRequest === "" ? "pt-1  flex justify-center items-center text-center lg:text-[10px] xl:text-[12px] text-[12px]  text-[#039511]" : "pt-1  flex justify-center items-center text-center lg:text-[10px] xl:text-[12px] text-[12px]  text-[#333333]"
                                                                }
                                                            >
                                                                {!editMyProfielData?.horoscope ? (
                                                                    <div>
                                                                        <p className="hidden md:block  text-[#D10A11] ttext-[10px] md:text-[14px] md:pl-8 lg:px-3">
                                                                            Add Horoscope
                                                                        </p>
                                                                        <p className="block   text-[#D10A11] ttext-[10px] md:text-[14px]  md:hidden">
                                                                            Add Horoscope
                                                                        </p>
                                                                    </div>
                                                                ) : (
                                                                    <p className=" md:pl-8 lg:pl-0 lg:pr-[0.1rem] text-[#039511] text-[10px] md:text-[14px]">View Horoscope</p>

                                                                )}
                                                            </p>
                                                        </div>
                                                        <div
                                                            onClick={onTrustDocumentClick}
                                                            className='flex flex-col items-center justify-center cursor-pointer '>

                                                            {editMyProfielData?.eduproof || editMyProfielData?.incomeproof || editMyProfielData?.idproof ?


                                                                <p className='flex justify-center items-center bg-[#D5D5D5] rounded-full w-[3rem] h-[3rem] lg:w-[3rem] lg:h-[3rem] xl:w-[3.5rem] xl:h-[3.5rem]'>

                                                                    <img
                                                                        className={" w-8 h-8"
                                                                        }
                                                                        src={Images.viewtrust}
                                                                        alt='Green Icon' />

                                                                </p> :
                                                                <div className="relative w-[3rem] h-[3rem] lg:w-[3rem] lg:h-[3rem] xl:w-[3.5rem] xl:h-[3.5rem]">
                                                                    <img
                                                                        className="absolute -top-[10px] right-0 w-[1.5rem] h-[1.5rem]"
                                                                        src={Images.addgroupicon}
                                                                        alt="Badge Icon"
                                                                    />

                                                                    <p className='flex justify-center items-center bg-[#D5D5D5] rounded-full w-[3rem] h-[3rem] lg:w-[3rem] lg:h-[3rem] xl:w-[3.5rem] xl:h-[3.5rem]'>

                                                                        <img
                                                                            className={" w-8 h-8"
                                                                            }
                                                                            src={Images.addtrust}
                                                                            alt='Green Icon'
                                                                        />

                                                                    </p> </div>}
                                                            <p
                                                                className={
                                                                    userData?.trustRequest === "" ? "pt-1  flex justify-center items-center text-center lg:text-[10px] xl:text-[12px] text-[12px]  text-[#039511]"
                                                                        : "pt-1  flex justify-center items-center text-center lg:text-[10px] xl:text-[12px] text-[12px]  text-[#333333]"
                                                                }
                                                            >

                                                                {editMyProfielData?.eduproof || editMyProfielData?.incomeproof || editMyProfielData?.idproof ?
                                                                    <p className=" lg:whitespace-nowrap  text-[#039511] ">
                                                                        View Trust Document</p>
                                                                    :
                                                                    <div>
                                                                        <p className=" text-[#D10A11] lg:whitespace-nowrap md:block">
                                                                            Add Trust Document
                                                                        </p>

                                                                    </div>
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <p className='border-[#E9E9E9] border-b-[1px] w-[90%] mx-5 py-3 hidden md:block'></p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>




                                    <PreviewBasicDetails isFrom={"ProfilePreview"} editMyProfielData={editMyProfielData} apiCallEditProfile={apiCallEditProfile} />
                                </div>
                            </div>


                        </div>
                    }

                </div>

            </div>
        </>
    )
}

export default ProfilePreview
