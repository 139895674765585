import axios from "axios";
import React, { useState, useEffect, useContext, useRef } from "react";
import EndPoints from "../../Constants/EndPoints";
import { IP_Address } from "../../Constants/IpAddress";
import { POST } from "../../Services/api_services";
import Constants, { loginFrom , EventName} from "../../Constants/constants";
import { useAnalytics } from "../../Hooks/usePageChange";
import { LoginContext } from "../../Contexts/LoginContext";
import ImageCroperComponent from "./ImageCroperComponent";
import { CommonSuccessPopup, MessagePopup, NotificationPopup, UploadPhotoPopup, UploadPhotoPopuplite } from "../CommonPopup";
import {AiOutlineInfoCircle} from "react-icons/ai"
import ImageHoroscopePopup from "./ImageHoroscopePopup";
import { Popover } from "antd";
import ProfilePicturePopup from "./ProfilePicturePopup";
import ImageCropper from "./ImageCropper";
import { getFileExtension, validateImage } from "../../Utils";
import config from "../../config";
import LoaderGif from "../../Gif/loader.gif"
import PopoverImage from '../../Images/Group 943.png'
import UploadingImage from '../../Images/Group 946@2x.png'
import addMoreButton from '../../Images/Group 942@2x.png'
import { imageSizeReducer, handleImageSizeCompress } from "../ImageSizeReducer";
import { useLocation } from "react-router-dom";
import Loader from "../Loader";
import { useTranslation } from "react-i18next";
import { getDeviceInfo, getGroupAuthId } from "../Kalyanlite/utils";
import { NavBarContext } from "../../Contexts/NavBarContext";

const isV3User = localStorage.getItem("isV3User");

export const ProfileImageUpload = ({ children, filePickCount = 0, contentName, contentId, onFilePick }) => {

  const [notification, setNotification] = useState({ show: false, title: "", message: "" })
  const [currentimage, setCurrentImage] = useState(null);
  const { loginDetail, logout } = useContext(LoginContext);
  const [showCrop, setShowCrop] = useState(false);
  const [photoId, setPhotoId] = useState()
  const [showSuccessPopup, setshowSuccessPopup] = useState(false);
  const [successMessage, setsuccessMessage] = useState("");
  const [showLoader, setShowLoader] = useState(false)
  const fileInputRef = useRef(null)

  const { t:trans } = useTranslation();

  const showNotification = ({ description = "", title = "Status!" }) => {
    setNotification({ show: true, title: title, message: description })
  }

  const closeNotification = () => {
    setNotification({ show: false, message: "", title: "" })
  }

  const handleUpload = async (file, cropedFile) => {
    //console.log("file.size", file.size);
    if (file.size / 1024 / 1024 < 10) {
      let fileData = new FormData();
      fileData.append("email", loginDetail()[0]);
      fileData.append("userId", loginDetail()[1]);
      fileData.append("photoUploadFrom", getDeviceInfo());
      fileData.append("uploadfrom", 'Gallery');
      fileData.append("photoId", photoId);
      fileData.append("croped", file);
      fileData.append("requestfulfilledvia", "myprofile");
      fileData.append("photorequesttypeid", 85);
      fileData.append("requestfulfilledfrom", getDeviceInfo());
      fileData.append("recieverprofileid", getGroupAuthId());

      setShowLoader(true)
      let data = await axios.post(
        `${config.api.API_URL}${EndPoints.croppedPhotoUploadUrl()}`,
        fileData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setShowLoader(false)
      // //console.log(data, statusCode);
      if (data.status === 200) {
        if (data.data.data.status === "SUCCESS") {
          setsuccessMessage(trans('photos.image_uploaded_successfully'))
          setshowSuccessPopup(true)
        }
      }
    } else {
      alert("Please upload file less than 10 mb");
    }
  };

  const cropedImage = (cropedImg) => {
    console.log(cropedImg, 'cropedImg1');
    imageSizeReducer(cropedImg, handleUpload)
    // handleUpload(originalImage, cropedImg);
    setShowCrop(false);
  };

  const originalImageUpload = async (file) => {
    console.log("file.size", file);
    if (file.size / 1024 / 1024 < 10) {
      let fileData = new FormData();
      fileData.append("email", loginDetail()[0]);
      fileData.append("userId", loginDetail()[1]);
      fileData.append("photoUploadFrom", getDeviceInfo());
      fileData.append("uploadfrom", 'Gallery');
      fileData.append("original", file);
      fileData.append("requestfulfilledvia", "myprofile");
      fileData.append("photorequesttypeid", 85);
      fileData.append("requestfulfilledfrom", getDeviceInfo());
      fileData.append("recieverprofileid", getGroupAuthId());
      if (contentName) {
        fileData.append("isContent", contentName);
      }
      if (contentId) {
        fileData.append("contentId", contentId);
      }
      let data = await axios.post(
        `${config
          .api.API_URL}${EndPoints.profileOriginalImageUploadUrl()}`,
        fileData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(data, "datadatadatadata");
      if (data.data.statusCode === 200) {
        if (data.data.data.status === "Success") {
          setPhotoId(data.data.data.photoId)
          setShowLoader(false)
          setShowCrop(true);
        } else {
        }
      } else {
      }

    } else {
      alert("Please upload file less than 10 mb");
    }
  };


  const handleCrop = (file, index) => {
    validateImage({
      file,
      onSizeError: (img) => {
        showNotification({ title: "Invalid image", description: trans('upload_photos.height_and_width_must_be_more') })
      },
      onExtensionError: (fileType) => {
        showNotification({ title: "Invalid image", description: "Invalid extension!" })
      },
      onFileSizeError: (img) => {
        showNotification({ title: "Invalid image", description: trans('upload_photos.image_size_should_not_be_great') })
      },
      onSuccess: (img, readerStr) => {
        // handleOriginalImageUpload(file);  
        // imageSizeReducer(file,originalImageUpload)   
        setShowLoader(true)
        handleImageSizeCompress(file, originalImageUpload)
        setCurrentImage(readerStr || "")
        // setShowCrop(true);
      }
    })
  };

  useEffect(() => {
    if (filePickCount > 0) {
      fileInputRef.current.click()
    }
  }, [filePickCount])


  return (<>
    {
      notification.show
        ? <NotificationPopup close={closeNotification} title={notification.title} content={notification.message} />
        : null
    }
    {showSuccessPopup && (

      <CommonSuccessPopup
        close={() => setshowSuccessPopup(false)}
        title={trans('photos.success')}
        content={successMessage}
      />
    )}
    {showLoader && <Loader className="loaderTransperancy" loaderClassName="loaderTransperancySpin" />}
    {showCrop
      ? (
        <ImageCropper
          onCropClick={cropedImage}
          image={currentimage}
          onClose={(isCropped) => {
            if (isCropped) {
              setShowCrop(false)
            } else {
              setShowCrop(false)
            }

          }}
        />
        // <ImageCroperComponent
        //   close={() => setShowCrop(false)}
        //   image={currentimage}
        //   imgType={originalImage.type}
        //   callBackCroper={cropedImage}
        // />
      )
      : null}
    <input
      style={{ display: "none" }}
      type="file"
      ref={fileInputRef}
      onChange={(event) => {
        //console.log("file", URL.createObjectURL(event.target.files[0]));
        handleCrop(event.target.files[0]);
      }}
      accept="image/jpeg, image/png"
    />
    <div onClick={() => fileInputRef.current.click()}>{children}</div>
  </>)
}

const ProfilePhotoComponent = (props) => {
  const { loginDetail, logout } = useContext(LoginContext);
  const [imageList, setImageList] = useState([]);
  const [showCrop, setShowCrop] = useState(false);
  const [photoId, setPhotoId] = useState()
  const [currentimage, setCurrentImage] = useState(null);
  const [showSuccessPopup, setshowSuccessPopup] = useState(false);
  const [successMessage, setsuccessMessage] = useState("");
  const [showImagePopup, setShowImagePopup] = useState({show:false,image:""})
  const [notification, setNotification] = useState({show:false,title:"",message:""})
  const [fileUploadData, setFileUploadData] = useState({isUploading:false,index:null})

  const [showUploadImagePopup, setShowUploadImagePopup] = useState(false);
  const [showSendImagePopup, setShowSendImagePopup] = useState(false);
  const [showRequestPopup, setShowRequestpopup] = useState(false);
  const [isProfileImage, setIsProfileImage] = useState("");
  const { onClickTrack } = useAnalytics()

  const [originalImage, setOriginalImage] = useState();
  const imageobject = {
    isverified: "N",
    photos: "",
  };
  const {showbottomtab,setShowBottomTab} = useContext(NavBarContext)

  useEffect(() => {
    console.log(showbottomtab,"setPartnerExpextata");
    return () => {
      setShowBottomTab(false); // ✅ Reset to false when unmounting
    };
  }, []);

  const { t: trans } = useTranslation();

  useEffect(() => {
    if(props.data?.length <= 10){
      let remainCount =  10 - props.data?.length
      let temp = [...props.data]
      if(remainCount === 0){
        remainCount = 5;
      }
      let remainItem = [...Array(remainCount).keys()].map(key=>imageobject)
      temp = [...temp,...remainItem]
      setImageList(temp)
    }else{
      let generatedCount = (Math.ceil(props.data?.length / 5) * 5)
      if(props.data?.length%5 === 0 ){
        generatedCount = props.data?.length+5
      }
      let remainCount = generatedCount - props.data?.length

      let temp = [...props.data]
      let remainItem = [...Array(remainCount).keys()].map(key=>imageobject)
      temp = [...temp,...remainItem]

      setImageList(temp)
    }
    //console.log("image", props.data);
  }, [props.data]);

  useEffect(() => {
    //console.log("updated imageList", imageList);
  }, [imageList]);
  const handleAddMore = () => {
    let len = 5;

    for (var i = 0; i < len; i++) {
      setImageList((oldValue) => [...oldValue, imageobject]);
    }
  };

  const handleCrop = (file, index) => {
    setOriginalImage(file);
    validateImage({
      file,
      onSizeError: (img) => {
        showNotification({ title: "Invalid image", description: trans('upload_photos.height_and_width_must_be_more') })
      },
      onExtensionError: (fileType) => {
        showNotification({ title: "Invalid image", description: "Invalid extension!" })
      },
      onFileSizeError:(img)=>{
        showNotification({ title: "Invalid image", description: trans('upload_photos.image_size_should_not_be_great') })
      },
      onSuccess: (img, readerStr) => {
        // handleOriginalImageUpload(file);  
        // imageSizeReducer(file,originalImageUpload)   
        handleImageSizeCompress(file,originalImageUpload)           
        setCurrentImage(readerStr || "")
        // setShowCrop(true);
        setFileUploadData({isUploading:true,index})
      }
    })
  };

  const originalImageUpload = async (file) => {
    console.log("file.size", file);
    if (file.size / 1024 / 1024 < 10) {
      let fileData = new FormData();
      fileData.append("email", loginDetail()[0]);
      fileData.append("userId", loginDetail()[1]);
      fileData.append("photoUploadFrom", getDeviceInfo());
      fileData.append("uploadfrom", 'Gallery');
      fileData.append("original", file);
      fileData.append("requestfulfilledvia", props?.isFromSms == "yes" ? "sms" : props?.isFrom == "chat" ? "chat" : "myprofile");
      fileData.append("photorequesttypeid", 85);
      fileData.append("requestfulfilledfrom", getDeviceInfo());
      fileData.append("recieverprofileid", getGroupAuthId());
      // fileData.append("isContent", props?.contentCard?.name);
      // fileData.append("contentId", props?.contentCard?.contentId);
      let data = await axios.post(
        `${config
        .api.API_URL}${EndPoints.profileOriginalImageUploadUrl()}`,
        fileData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setFileUploadData(prev=>({...prev,isUploading:false}))
      console.log(data,"datadatadatadata");
      if (data.data.statusCode === 200) {
        if (data.data.data.status === "Success") {
          setPhotoId(data.data.data.photoId)
          setShowCrop(true);          
        } else {
          props.close();
        }
      } else {
        props.close();
      }
      
    } else {
      alert("Please upload file less than 10 mb");
      props.close();
    }
  };

  useEffect(() => {


    return () => {
      sessionStorage.removeItem(Constants.loginLocalStorageKeys.notificationId)
    }
  }, [])


  const handleUpload = async (file, cropedFile) => {
    //console.log("file.size", file.size);
    if (file.size / 1024 / 1024 < 10) {
      const loginUrl = sessionStorage.getItem(Constants.loginLocalStorageKeys.loginUrl)
      let notificationId = null;
      console.log("handleUpload", loginUrl);
      if (loginUrl?.includes("photo") || props?.isFromInitialPopup) {
        notificationId = sessionStorage.getItem(Constants.loginLocalStorageKeys.notificationId)
        sessionStorage.removeItem(Constants.loginLocalStorageKeys.notificationId)
      }

      let fileData = new FormData();
      fileData.append("email", loginDetail()[0]);
      fileData.append("userId", loginDetail()[1]);
      fileData.append("photoUploadFrom", getDeviceInfo());
      fileData.append("uploadfrom", 'Gallery');
      fileData.append("photoId", photoId);
      fileData.append("croped", file);
      fileData.append("requestfulfilledvia", props?.isFromSms == "yes" ? "sms" : props?.isFrom == "chat" ? "chat" : "myprofile");
      fileData.append("photorequesttypeid", 85);
      fileData.append("requestfulfilledfrom", getDeviceInfo());
      fileData.append("recieverprofileid", getGroupAuthId());

      if (notificationId) {
        fileData.append("notificationId", notificationId)
      }
      setFileUploadData(prev=>({...prev,isUploading:true}))
      let  data  = await axios.post(
        `${config.api.API_URL}${EndPoints.croppedPhotoUploadUrl()}`,
        fileData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // //console.log(data, statusCode);
      setFileUploadData(prev=>({index:null,isUploading:false}))
      if (data.status === 200) {
        if (data.data.data.status === "SUCCESS") {
          setsuccessMessage(trans('photos.image_uploaded_successfully'))
            setshowSuccessPopup(true)
          props.callBackReload();
        }
      }
    } else {
      alert("Please upload file less than 10 mb");
    }
  };
  function urltoFile(url, filename, mimeType) {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      });
  }
  const cropedImage = (cropedImg) => {
    console.log(cropedImg,'cropedImg1');
    imageSizeReducer(cropedImg,handleUpload)
    // handleUpload(originalImage, cropedImg);
    setShowCrop(false);
  };

  const showNotification = ({ description = "", title = `${trans('photos.status')}!` }) => {
    setNotification({ show: true, title: title, message: description })
  }

  const handleDelete = () => {
    // "{
    //     ""email"": ""nanciya.c@praniontech.com"",
    //     ""userId"": ""301666"",
    //     ""imagePath"": ""https://s3-us-west-2.amazonaws.com/kmuat/source/Images/194426/194426PHOTOImagesThuJan2615:38:11IST2023""
    // }"
  };
  const callBackSetProfile = async (image) => {
    //console.log("callBackSetProfile", image);
    // "{
    //     ""email"": ""nayekkarthikeyan12@gmail.com"",
    //     ""userId"": ""1631292"",
    //     ""imagePath"": ""https://s3-us-west-2.amazonaws.com/kmuat/source/Images/1210478/1210478PHOTOImagesWedSep1411:02:31UTC2022""
    // }"
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      imagePath: `${image}`,
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.makeProfileImageUrl()}`,
      request
    );
    //console.log("statusCode", statusCode);
    if (statusCode === 200) {
      // alert("Profile Image Changed");
      if (data?.data?.validated === true) {
        setsuccessMessage(trans('photos.profile_image_changed'))
        setshowSuccessPopup(true)
        props.onProfileUpdateCallBack();
        props.callBackReload();
      } else if (data?.data?.status === Constants.verificationStatusKeys.rejected) {
        showNotification({description:`${trans('photos.photo_added_by_you_is_rejected')}: <br><br><center>${data?.data?.reason}</center>`})
      } else {
        showNotification({description:`${trans('photos.wait_till_validation')}`})
      }
    } else if (statusCode === 401) {
      logout();
    }
  };
  const callBackDelete = async (image) => {
    //console.log("callBackDelete", image);
    if(isProfileImage == "Y"){

      props.onOpenCallback(image)
      
    }else {

    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      imagePath: `${image}`,
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.profilePhotoDeleteUrl()}`,
      request
    );
    //console.log("statusCode", statusCode);
    if (statusCode === 200) {
      // alert("Profile Image Deleted");
      showNotification({description:trans('photos.profile_image_deleted_successf'),title:trans('photos.success')})
      props.onProfileUpdateCallBack();
      props.callBackReload();
    } else if (statusCode === 401) {
      logout();
    }
  }
  };

  const groupPhotoCheck = async (image,close) => {

    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      imagePath: `${image}`,
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.makeProfileImageUrl()}`,
      request
    );
    if (statusCode === 200) {
      if(data?.data?.familyphotoresponse){
        showNotification({description:data?.data?.familyphotoresponse})
      }else if(data?.data?.status == Constants.verificationStatusKeys.rejected){
        showNotification({description:`${trans('photos.photo_added_by_you_is_rejected')}: <br><br><center>${data?.data?.reason}</center>`})
      }else if(data?.data?.status == Constants.verificationStatusKeys.notVerified || data?.data?.status == Constants.verificationStatusKeys.pending){
        showNotification({description:`${trans('photos.wait_till_validation')}`})
      }else if(data?.data?.status == "SUCCESS"){
        // callBackSetProfile(image)
        setsuccessMessage(trans('photos.profile_image_changed'))
        setshowSuccessPopup(true)
        props.onProfileUpdateCallBack();
        props.callBackReload();
        close()
      }
    } else if (statusCode === 401) {
      logout();
    }
  };

  const DropDown = (props) => {
    return (
      <div
        id="dropdown"
        className={`${
          props.isShow ? "" : "hidden"
        } z-10 absolute right-2 top-8 bg-white divide-y divide-gray-100 rounded-lg shadow  dark:bg-gray-700`}
      >
        <ul
          className="py-2 text-sm text-gray-700 dark:text-gray-200"
          aria-labelledby="dropdownDefaultButton"
        >
          <li>
            <span
              onClick={() => {
                props.callBackSetProfile();
              }}
              className="block px-1 py-1 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer"
            >
              {trans('photos.set_as_profile')}
            </span>
          </li>
          <li>
            <span
             onClick={() => {
                props.callBackDelete();
              }}
              className="block px-1 py-1 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer"
            >
              {trans('photos.delete')}
            </span>
          </li>
        </ul>
      </div>
    );
  };

  const ImageOptions = ({close,image,isProfile}) => {
    const { t:trans } = useTranslation();
    
    return <ul
      className="py-2 text-sm text-gray-700 dark:text-gray-200"
      aria-labelledby="dropdownDefaultButton"
    >
      <li>
        <span
          onClick={() => {
            groupPhotoCheck(image,close);   
            // callBackSetProfile(image)
            onClickTrack(EventName.profileSetAsProfileButton);
            // close()
          }}
          className="block px-1 py-1 hover:bg-gray-100 words-break w-[7rem] dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer"
        >
          {trans('my_profile.set_as_profile')}
        </span>
      </li>
      <li>
        <span
          onClick={() => {
            if(isProfile == "Y"){
              props.onOpenCallback(image);
            }else{
              callBackDelete(image);
              close();
            }
            onClickTrack(EventName.profileDeleteButton);
          }}
          className="block px-1 py-1 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer"
        >
          {trans('chat.delete')}
        </span>
      </li>
    </ul>
  }

  const getStatusTxt = (status)=>{
    let statusTxt = "";
    if (status === Constants.verificationStatusKeys.notVerified) {
      statusTxt = "Pending validation"
    } else if (status === Constants.verificationStatusKeys.rejected) {
      statusTxt = "Photo rejected"
    } else if (status === Constants.verificationStatusKeys.pending) {
      statusTxt = "Pending"
    }
    return statusTxt
  }

  const ImageView = (props) => {
    const [showDropdown, setDropdown] = useState(false);
    const statusTxt = getStatusTxt(props.status)
    return (
      <div className="relative  w-24 h-24 md:w-[7rem] md:h-[7rem] lg:w-28 lg:h-28 mx-auto rounded-[16px] bg-[#FFFFFF] md:rounded-[11px] lg:rounded-[17px] overflow-clip">
        <img
          alt=""
          onClick={() => { props.onOpenCallback() }}
          src={props.image} //"/Assets/Images/Mask Group 24@2x.png"
          className="w-24 h-24 md:w-[7rem] md:h-[7rem]  lg:w-28 lg:h-28 cursor-pointer"
          // loading="lazy"
        />
        <Popover
          trigger={"click"}
          placement="bottom"
          open={showDropdown}
          overlayInnerStyle={{padding:0,marginTop:"2rem",marginLeft:"9rem"}}
          onOpenChange={(val) => setDropdown(val)}
          className="absolute top-[6px] right-[6px] inline-flex items-center justify-center p-0"
          content={<ImageOptions close={() => setDropdown(false)} image={props.image} isProfile={props?.isProfile}/>}>
          <img
            alt=""
            onClick={() => {
              setDropdown(!showDropdown);
              onClickTrack(EventName.profilePhotoThreeDotButton)
            }}
            src={PopoverImage}
            className="md:w-[0.6rem] lg:w-[1rem] cursor-pointer"
            loading="lazy"
          />
        </Popover>

        {statusTxt && <div className="w-full h-7 flex items-center justify-center bg-black opacity-60 absolute bottom-0 ">
          <AiOutlineInfoCircle color="white" size={18} />
          <span className="text-[9px] text-white mx-1">{statusTxt}</span>
        </div>}
      </div>
    );
  };
  const ImageUploadView = (props) => {
    // md:w-[5rem] lg:w-[8rem]
    return (
      <div className="flex justify-center items-center" 
          onClick={() =>{
            setShowUploadImagePopup(true)
            // onClickTrack(EventName.profilePhotoAddButton)
          }}
      >
        <label className="bg-[#FFFFFF] w-24 h-24 md:w-[7rem] md:h-[7rem]  lg:w-28 lg:h-28 flex justify-center items-center md:rounded-[11px] lg:rounded-[17px]" onClick={()=> onClickTrack(EventName.profilePhotoAddButton)}>
          {
            fileUploadData.isUploading && fileUploadData.index === props.index
              ? <div className="flex flex-col items-center justify-center">
                <img alt="loader" src={LoaderGif} className={`w-16 h-16`} />
                <div className="text-[16px] font-semibold my-2">
                   {trans('upload_photos.uploading')}
                </div>
              </div>
              : <>
                <img
                  className=" md:w-[1rem] lg:w-[2rem]"
                  src={UploadingImage}
                  alt=""
                  
                />
                {/* <input
                  style={{ display: "none" }}
                  type="file"
                  onChange={(event) => {
                    //console.log("file", URL.createObjectURL(event.target.files[0]));
                    handleCrop(event.target.files[0], props.index);
                  }}
                  accept="image/jpeg, image/png"
                /> */}
              </>
          }
        </label>
      </div>
    );
  };

  const ImageViewMobile = (props) => {
    const [showDropdown, setDropdown] = useState(false);
    const statusTxt = getStatusTxt(props.status)
    return (
      <span className="col-span-1 mx-2 my-2 flex justify-center relative rounded-[16px] bg-[#FFFFFF] md:rounded-[11px] lg:rounded-[17px] overflow-clip">
        <img
          src={props.image}
          onClick={()=>{props.onOpenCallback()}}
          className=" h-[9rem]  md:h-[4rem]   lg:h-28 object-contain "
          alt=""
        //   className="w-[9rem] h-[9rem] md:w-[4rem] md:h-[4rem]  lg:w-28 lg:h-28  "
        />
        <Popover
          trigger={"click"}
          placement="bottom"
          open={showDropdown}
          overlayInnerStyle={{padding:0,marginTop:"0",marginRight:"-2rem"}}
          onOpenChange={(val) => setDropdown(val)}
          className="absolute top-[6px] right-[6px] inline-flex items-center justify-center p-0"
          content={<ImageOptions close={() => setDropdown(false)} image={props.image}/>}>
          <img
            alt=""
            onClick={() => setDropdown(!showDropdown)}
            src={PopoverImage}
            className="md:w-[0.6rem] lg:w-[1rem] cursor-pointer"
          />
        </Popover>
        {statusTxt && <div className="w-full h-7 flex items-center justify-center bg-black opacity-60 absolute bottom-0 ">
          <AiOutlineInfoCircle color="white" size={18} />
          <span className="text-[9px] text-white mx-1">{statusTxt}</span>
        </div>}
      </span>
    );
  };
  const ImageUploadViewMobile = (props) => {

    return (
      <label onClick={() => setShowUploadImagePopup(true)} className=" col-span-1 mx-2 my-2  h-[9rem]  md:h-[4rem]   lg:h-28  rounded-[16px] bg-[#FFFFFF] md:w-[5rem] lg:w-[8rem] flex justify-center items-center md:rounded-[11px] lg:rounded-[17px] ">
        {fileUploadData.isUploading
          ? <div className="flex flex-col items-center justify-center">
            <img alt="loader" src={LoaderGif} className={`w-16 h-16`} />
            <div className="text-[16px] font-semibold my-2">
               {trans('upload_photos.uploading')}
            </div>
          </div>
          : <><img
            className=" w-[3rem] md:w-[1rem] lg:w-[2rem]"
            src={UploadingImage}
            alt=""
          />
            {/* <input
              style={{ display: "none" }}
              type="file"
              onChange={(event) => {
                //console.log("file", URL.createObjectURL(event.target.files[0]));
                // handleCrop(event.target.files[0], props.index);
                setShowUploadImagePopup(true)
              }}
              accept="image/jpeg, image/png"
            /> */}
          </>
        }
      </label>
    );
  };

  const openImagePopup = (image,isProfile) => {
    setIsProfileImage(isProfile)
    setShowImagePopup({ show: true, image: image.photos })
  }

  const closeImagePopup = () => {
    setShowImagePopup({ show: false, image: "" })
  }

  const closeNotification = ()=>{
    setNotification({show:false,message:"",title:""})
  }

  const isV3User = localStorage.getItem("isV3User")
  const [uploadPhotoSuccessPopup, setUploadPhotoSuccessPopup] = useState(false)

  return (
    <div>
      {showUploadImagePopup ? (
        isV3User ?
        <UploadPhotoPopuplite
          close={() => setShowUploadImagePopup(false)}
          setUploadPhotoSuccessPopup={setUploadPhotoSuccessPopup}

          title={trans('photo_popup.add_photo')} 
          callBack={() => {
          setsuccessMessage(trans('photos.image_uploaded_successfully'))
          setshowSuccessPopup(true)
          }}
          isFrom ={"contentcard"}
          callBackReload={() =>
            props?.callBackReload()
          }
          isFromInitialPopup={props?.isFromInitialPopup}
      />
        :
      <UploadPhotoPopup
          close={() => setShowUploadImagePopup(false)}
          title={trans('my_profile.upload_photo')}
          callBack={() => {
          setsuccessMessage(trans('photos.image_uploaded_successfully'))
          setshowSuccessPopup(true)
          }}
          isFrom ={"contentcard"}
          callBackReload={() =>
            props?.callBackReload()
          }
      />
      ) : (
      <></>
      )}

{uploadPhotoSuccessPopup && (
              <CommonSuccessPopup
                close={() => {
                  setUploadPhotoSuccessPopup(false);
                }}
                title={trans('photos.success')}
                content={trans('photos.image_uploaded_successfully')}
              />
            )}

      {showSuccessPopup && (

      <CommonSuccessPopup
      close={() => setshowSuccessPopup(false)}
      title={trans('photos.status')}
      content={successMessage}
      />
      )}
        
      {
      notification.show
        ? <NotificationPopup close={closeNotification} title={notification.title} content={notification.message} />
        : null
      }
      {showSuccessPopup && (
        
        <CommonSuccessPopup
          close={() => setshowSuccessPopup(false)}
          title={trans('photos.status')}
          content={successMessage}
        />
      )}
      {showImagePopup.show ? (
        <ProfilePicturePopup
          onClose={closeImagePopup}
          title={trans('photo_view_popup__password_pro.view_photo')}
        >
          <div className="w-[80vw] h-[25vh] md:w-[50vw] md:h-[50vh] py-4 flex items-center justify-center px-2 ">
            <img
              alt=""
              src={showImagePopup.image}
              className="max-w-full max-h-full"
            />
          </div>
          < div className="flex justify-end items-center mb-4 space-x-4 px-2 md:pr-5">
            <button
              onClick={() => {
                // callBackSetProfile(showImagePopup.image);
                groupPhotoCheck(showImagePopup.image,closeImagePopup)
                closeImagePopup();
              }}
              className="text-[9px] flex items-center justify-center lg:w-auto lg:h-[1.5rem]  text-white bg-[#D10A11] border border-[#D10A11] px-[1.5rem] py-[0.5rem] md:px-[2.3rem] lg:px-[3rem] whitespace-nowrap rounded-[5px] font-bold  md:py-[1rem] lg:py-5 md:text-[12px] lg:text-[14px]">
                <span className="truncate">
                  {trans('photos.set_as_profile')}
                </span>
            </button>
            <button
              onClick={() => {
                callBackDelete(showImagePopup.image);
                closeImagePopup();
              }}
              className="text-[9px] flex items-center justify-center lg:w-[2.5rem] lg:h-[1.5rem]  text-black bg-white border border-[#D10A11] px-[1.5rem] py-[0.5rem] md:px-[2.3rem] lg:px-[3rem] whitespace-nowrap rounded-[5px] font-bold  md:py-[1rem] lg:py-5 md:text-[12px] lg:text-[14px]">
              {trans('photos.delete')}
            </button>
          </div>

        </ProfilePicturePopup>
      ) : (
        <></>
      )}

      {showCrop
      ?(
        <ImageCropper 
        onCropClick={cropedImage} 
        image={currentimage} 
        onClose={(isCropped) => {
          if(isCropped){
            setShowCrop(false)
          }else{
            setShowCrop(false)
            props.callBackReload();
          }

        }}
        />
          // <ImageCroperComponent
          //   close={() => setShowCrop(false)}
          //   image={currentimage}
          //   imgType={originalImage.type}
          //   callBackCroper={cropedImage}
          // />
        )
      :null}
      
      <div className="hidden md:block ">
        <div className="flex justify-between">
          <p className={`${isV3User ?"text-[#000000] text-[15px] flex justify-center items-center font-semibold":
           " text-[#000000] text-[16px] flex justify-center items-center font-semibold "}`}>{trans('my_profile.image_upload')}</p>
          <button
            
            onClick={() => { 
              handleAddMore(); 
              onClickTrack(EventName.profilePhotoAddMoreButton)
            }}
            className= {`${isV3User ?
                   "flex items-center space-x-2 justify-center  font-bold text-white bg-[#D10A11]  md:px-[1rem] lg:px-[0.5rem] rounded-[4px] py-[0.3rem] md:text-[12px] lg:text-[13px]"
                  :
                  "flex items-center space-x-2 justify-center  font-bold text-white bg-[#D10A11]  md:px-[1rem] lg:px-[2rem] rounded-[4px] py-2 md:text-[12px] lg:text-[13px]"
      }`}>
           {isV3User ? <img
              className="md:w-[1rem] lg:w-[1rem]"
              src={addMoreButton}
              alt=""
            />
            :
            <img
              className="md:w-[1rem] lg:w-[2rem]"
              src={addMoreButton}
              alt=""
            /> 
          }
            <p> {trans('family.add_more')} </p>
          </button>
        </div>
        <div className="mt-[1rem]  bg-[#F1F1F1] rounded-[14px] ">
          <div className="grid grid-flow-row-dense md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 p-[0.5rem] lg:p-[1rem] pt-[1.5rem] pb-[3rem] md:justify-between lg:justify-start gap-4 ">
            {imageList?.map((e, i) =>
              e.photos ? (
                <ImageView
                  image={e.photos}
                  status={e.isverified}
                  isProfile={e?.profileimage}
                  callBackSetProfile={() => {
                    callBackSetProfile(e.photos);
                  }}
                  callBackDelete={() => {
                    setIsProfileImage(e.profileimage);
                    callBackDelete(e.photos);
                  }}
                  onOpenCallback={()=>{
                    if(e?.profileimage == "Y"){
                      props.onOpenCallback(e.photos);
                    }else{
                      openImagePopup(e,e?.profileimage)
                    }
                  }}
                />
              ) : (
                <ImageUploadView index={i} />
              )
            )}

            {/* <ImageUploadView /> */}
            {/* <span className=" relative inline-block">
              <img
                src="/Assets/Images/Mask Group 24@2x.png"
                className="w-24 h-24 md:w-[5rem] md:h-[5rem]  lg:w-28 lg:h-28  "
              />
              <img
                src={PopoverImage}
                className=" md:w-[0.6rem] lg:w-[1rem] absolute md:top-[0.6rem] md:right-[0.6rem] lg:top-[0.8rem] lg:right-[1.3rem] inline-flex items-center justify-center  transform translate-x-1/2 -translate-y-1/2  "
              />
            </span>

            <span className=" relative inline-block">
              <img
                src="/Assets/Images/Mask Group 25@2x.png"
                className="w-24 h-24 md:w-[5rem] md:h-[5rem]  lg:w-28 lg:h-28  "
              />
              <img
                src={PopoverImage}
                className=" md:w-[0.6rem] lg:w-[1rem] absolute md:top-[0.6rem] md:right-[0.6rem] lg:top-[0.8rem] lg:right-[1.3rem] inline-flex items-center justify-center  transform translate-x-1/2 -translate-y-1/2  "
              />
            </span>

            <span className=" relative inline-block">
              <img
                src="/Assets/Images/Mask Group 26@2x.png"
                className="w-24 h-24 md:w-[5rem] md:h-[5rem]  lg:w-28 lg:h-28  "
              />
              <img
                src={PopoverImage}
                className=" md:w-[0.6rem] lg:w-[1rem] absolute md:top-[0.6rem] md:right-[0.6rem] lg:top-[0.8rem] lg:right-[1.3rem] inline-flex items-center justify-center  transform translate-x-1/2 -translate-y-1/2  "
              />
            </span> */}

            {/* <label className="bg-[#FFFFFF] md:w-[5rem] lg:w-[8rem] flex justify-center items-center md:rounded-[11px] lg:rounded-[17px] ">
              <div className=" ">
                <img
                  className=" md:w-[1rem] lg:w-[2rem]"
                  src={UploadingImage}
                />
                <input
                  style={{ display: "none" }}
                  type="file"
                  onChange={(event) => {
                    //console.log(event.target.files[0]);
                  }}
                  accept="image/jpeg, image/png"
                />
              </div>
            </label> */}
          </div>
        </div>
        
      </div>
      <div className="block md:hidden">
        <div className="flex px-[1rem] mt-[1rem] justify-between">
          <p className=" text-[#000000]  text-[16px] font-semibold ">
          {trans('my_profile.image_upload')}
          </p>
          {/* <button
          onClick={handleAddMore}
            className="
                   flex items-center space-x-2 justify-center  font-bold text-white bg-[#D10A11] px-[0.6rem] md:px-[1rem] lg:px-[2rem] rounded-[4px] py-2 text-[11px] md:text-[12px] lg:text-[13px]  
                  "
          >
            <img
              className=" w-[1rem] md:w-[1rem] lg:w-[2rem]"
              src="/Assets/Images/Group 942@2x.png"
              alt=""
            />
            <p> Add More </p>
          </button> */}
        </div>
        <div className="mt-[1rem] mx-[1rem]  bg-[#F1F1F1] rounded-[14px] ">
          <div className="p-[1rem] grid grid-cols-2 pt-[1.5rem] pb-[3rem] md:flex md:justify-between lg:justify-start lg:space-x-14">
          <ImageUploadViewMobile index={0} />
            {imageList?.map((e, i) =>
              e.photos ? (
                <ImageViewMobile
                  status={e.isverified}
                  image={e.photos}
                  isProfile={e?.profileimage}
                  callBackSetProfile={() => {
                    callBackSetProfile(e.photos);
                  }}
                  callBackDelete={() => {
                    setIsProfileImage(e?.profileimage);
                    callBackDelete(e.photos);
                  }}
                  onOpenCallback={()=>{
                    if(e?.profileimage == "Y"){
                      props.onOpenCallback(e.photos);
                    }else{
                      openImagePopup(e,e?.profileimage)
                    }
                  }}
                />
              ) : null
            )}
            {/* <span className=" col-span-1 mt-2 rounded-[16px] bg-[#FFFFFF] md:w-[5rem] lg:w-[8rem] flex justify-center items-center md:rounded-[11px] lg:rounded-[17px] ">
              <div className=" ">
                <img
                  className=" w-[3rem] md:w-[1rem] lg:w-[2rem]"
                  src={UploadingImage}
                />
              </div>
            </span> */}
            {/* 
            <span className="col-span-1 flex justify-center relative ">
              <img
                src="/Assets/Images/Mask Group 24@2x.png"
                className="w-[9rem] h-[9rem] md:w-[4rem] md:h-[4rem]  lg:w-28 lg:h-28  "
              />
              <img
                src={PopoverImage}
                className=" top-[22px] right-[32px] w-[0.8rem] md:w-[0.6rem] lg:w-[1rem] absolute md:top-[0.6rem] md:right-[0.6rem] lg:top-[0.8rem] lg:right-[1.3rem] inline-flex items-center justify-center  transform translate-x-1/2 -translate-y-1/2  "
              />
            </span>

            <span className=" col-span-1 flex justify-center relative ">
              <img
                src="/Assets/Images/Mask Group 25@2x.png"
                className="w-[9rem] h-[9rem] md:w-[4rem] md:h-[4rem]  lg:w-28 lg:h-28  "
              />
              <img
                src={PopoverImage}
                className=" top-[22px] right-[32px] w-[0.8rem] md:w-[0.6rem] lg:w-[1rem] absolute md:top-[0.6rem] md:right-[0.6rem] lg:top-[0.8rem] lg:right-[1.3rem] inline-flex items-center justify-center  transform translate-x-1/2 -translate-y-1/2  "
              />
            </span>

            <span className=" cols-span-1 mt-2 flex justify-center relative ">
              <img
                src="/Assets/Images/Mask Group 26@2x.png"
                className="w-[9rem] h-[9rem] md:w-[4rem] md:h-[4rem]  lg:w-28 lg:h-28  "
              />
              <img
                src={PopoverImage}
                className=" top-[22px] right-[32px] w-[0.8rem] md:w-[0.6rem] lg:w-[1rem] absolute md:top-[0.6rem] md:right-[0.6rem] lg:top-[0.8rem] lg:right-[1.3rem] inline-flex items-center justify-center  transform translate-x-1/2 -translate-y-1/2  "
              />
            </span>
            <span className=" col-span-1 mt-2 rounded-[16px] bg-[#FFFFFF] md:w-[5rem] lg:w-[8rem] flex justify-center items-center md:rounded-[11px] lg:rounded-[17px] ">
              <div className=" ">
                <img
                  className=" w-[3rem] md:w-[1rem] lg:w-[2rem]"
                  src={UploadingImage}
                />
              </div>
            </span> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePhotoComponent;
