import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const HelpSection = (props) => {

  const { t: trans } = useTranslation();
  useEffect(() => {
    //console.log(props.data,"helpcenter");
  }, [props.data]);

  return (
    <div className='space-y-3'>
      <div className="border border-[#E9E9E9]">
        <div className="text-[#575556] font-bold text-[18px] xl:text-[18px]  lg:text-[16px] text-left">
          <p className="inline-block text-center 3xl:w-[31rem] md:w-[15rem] pt-3 lg:w-[14rem] xl:w-[19rem] 2xl:w-[24rem] w-[21rem] 2xl:px-[1rem]">
          {trans('help.call_us__for_match_making_rela')} </p>
        </div>

        <div className=" mb-[2rem] ">
          {/* <p className="text-[#575556] font-normal md:text-[12px] lg:text-[14px] pt-2 flex justify-center  ">
                  For match making related queries.
                </p>  */}
          {/* {props.menu !== "help" ? 
              <p className="text-[#D10A11] font-bold  md:text-[18px] lg:text-[20px] pt-2 flex justify-center ">
               
                  {props?.data?.callcenter}
            </p> 

            : */}
          <p className="text-[#D10A11] font-bold  md:text-[18px] lg:text-[20px] pt-2 flex justify-center ">
            {props?.data?.callcenter}
          </p>

          {/* } */}
          <p className="text-[#575556] font-normal md:text-[12px] lg:text-[14px] pt-2 flex justify-center text-center px-5 ">
          {trans('help.we_are_available_on_all_workin')}
                    </p>
          {/* <p className="text-[#575556] font-normal md:text-[12px] lg:text-[14px]  flex justify-center ">
            between 10AM To 6PM (IST)
          </p> */}
        </div>

      </div>

      <div className="border border-[#E9E9E9]">
      <div className="text-[#575556] font-bold text-[18px] xl:text-[18px]  lg:text-[16px] text-left">
  <p className="inline-block text-center md:w-[12rem] 3xl:w-[32rem] pt-3 lg:w-[16rem] xl:w-[21rem] 2xl:w-[26rem] w-[22rem] 2xl:px-[1rem]">
    {trans('help.call_us__for_wedding_jewellery')}
  </p>
</div>

        <div className=" mt-1 mb-[3rem] ">
          {/* <p className="text-[#575556] font-normal md:text-[12px] lg:text-[14px] pt-2 flex justify-center  text-center">
          For wedding jewellery-related queries and to book appointment.
          </p> */}
          <p className="text-[#D10A11] font-bold md:text-[15px] lg:text-[20px] pt-2 flex justify-center ">
          +91 7338874726
          </p>
          <p className="text-[#575556] font-normal md:text-[12px] lg:text-[14px] pt-2 flex justify-center text-center px-5">
            {/* We are available on all working days */}
            {trans('help.we_are_available_on_all_workin')}
          </p>
          {/* <p className="text-[#575556] font-normal md:text-[12px] lg:text-[14px]  flex justify-center ">
            between 10 AM To 6PM (IST)
          </p> */}
        </div>
      </div>

      <div className="border border-[#E9E9E9]">
        <p className="text-[#575556] font-bold text-[18px] pt-2 pl-4 ">
        {trans('help.write_to_us')}
        </p>
        <div className=" mt-[2rem] mb-[3rem] ">
          <p className="text-[#575556] font-normal md:text-[12px] lg:text-[14px] pt-2 flex justify-center  text-center px-5">
           {trans('help.for_match_making_and_jewellery')}
          </p>
          {/* {props.menu !== "help" ? 
                <p className="text-[#D10A11] font-bold md:text-[15px] lg:text-[20px] pt-2 flex justify-center ">
                  
                   {props?.data?.helpemail}
                </p> 
                : */}
          <p className="text-[#D10A11] font-bold md:text-[15px] lg:text-[20px] pt-2 flex justify-center ">
            {props?.data?.helpemail}
          </p>
          {/* } */}
        </div>
      </div>


      <div className="border border-[#E9E9E9]">
        <p className="text-[#575556] font-bold text-[18px] pt-2 pl-4 ">
        For Grievances:
        </p>
        <div className=" mt-[2rem] mb-[3rem] ">
          <p className="text-[#575556] font-normal md:text-[12px] lg:text-[14px]  flex justify-center  text-center px-4">
          For any grievances related to our service, please contact our Grievance Officer at:
          </p>
          
          <p className="text-[#575556] font-normal md:text-[11px] lg:text-[14px] xl:text-[19px] 2xl:text-[20px] pt-3 flex justify-center text-center">
          Email</p>
          <p className='text-[#D10A11] font-bold  md:text-[11px] lg:text-[14px] xl:text-[17px] 2xl:text-[20px] flex justify-center px-1 '>customercaremanager@kalyanmatrimony.com</p>

         

          <p className="text-[#575556] font-normal  md:text-[11px] lg:text-[14px] xl:text-[19px] 2xl:text-[20px] pt-3 flex justify-center text-center ">
          Phone </p>
          <p className='text-[#D10A11] font-bold  md:text-[11px] lg:text-[14px] xl:text-[17px] 2xl:text-[20px] flex justify-center px-1 '>+91 9884692264</p>
         
          <p className="text-[#575556] font-normal md:text-[12px] lg:text-[14px]  flex justify-center pt-3 text-center px-4">
           We are available on all working days between 10 AM To 6PM (IST)         </p>
        </div>
      </div>
    </div>
  )
}

export default HelpSection