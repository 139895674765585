import React, { useState, useEffect, useContext, useRef } from "react";
import { PreferenceTextView } from "../PartnerPreference/PartnerPreferenceUtils";
import BasicPeference from "../PartnerPreference/BasicPeference";
import { useFormik } from "formik";
import { LoginContext } from "../../../Contexts/LoginContext";
import * as Yup from "yup";
import EndPoints from "../../../Constants/EndPoints";
import { IP_Address } from "../../../Constants/IpAddress";
import { POST, GET } from "../../../Services/api_services";
import { EditButton, SaveCancelButton } from "../EditProfileUtills";
import PhysicalAttributes from "../PartnerPreference/PhysicalAttributes";
import EducationPreference from "../PartnerPreference/EducationPreference";
import AstrologicalPreference from "../PartnerPreference/AstrologicalPreference";
import HabitsPreference from "../PartnerPreference/HabitsPreference";
import config from "../../../config";
import Constants, {
  EventKeys,
  EventName,
} from "../../../Constants/constants";
import { useAnalytics } from "../../../Hooks/usePageChange";
import ActionRestrict from "../../Kalyanlite/Common/ActionRestrict";
import { useTranslation } from "react-i18next";
import { NavBarContext } from "../../../Contexts/NavBarContext";
const PartnerPreferenceComponent = (props) => {
  const [isShowEdit, setEditView] = useState(true);
  const { loginDetail, logout } = useContext(LoginContext);
  const [editbasicinfofreshData, seteditbasicinfofresh] = useState();
  const [editreligioninfofreshData, seteditreligioninfofresh] = useState();
  const [edithoroinfofreshData, setedithoroinfofresh] = useState();
  const [educationinfofreshData, seteducationinfofresh] = useState();
  const [editlocationinfofreshData, seteditlocationinfofresh] = useState();
  const [familyinfofreshData, setfamilyinfofresh] = useState();
  const [submitStatus, setSubmitStatus] = useState(false)
  const {showbottomtab,setShowBottomTab ,showpecout,setShowpeCount ,showprofilebottomtab,setShowProfileBottomTab} = useContext(NavBarContext)

  const { t: trans } = useTranslation();

    
  const [managePreferenceData, setPreferenceData] = useState();
  useEffect(() => {
    // apiCallManagePreference();
    // console.log(props,'bsjibcieub');
    setPreferenceData(props.managePreferenceData)
  }, [props?.managePreferenceData]);

  const formik = useFormik({
    initialValues: {
      maritalStatus: managePreferenceData?.maritalStatus
        ? managePreferenceData?.maritalStatus
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
      agefrom: managePreferenceData?.minage ? managePreferenceData?.minage : "",
      ageTo: managePreferenceData?.maxage ? managePreferenceData?.maxage : "",
      country: managePreferenceData?.country
        ? managePreferenceData?.country
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
      state: managePreferenceData?.state
        ? managePreferenceData?.state
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
      city: managePreferenceData?.city
        ? managePreferenceData?.city
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
      motherTongue: managePreferenceData?.motherTongue
        ? managePreferenceData?.motherTongue
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
      religion: managePreferenceData?.religion
        ? managePreferenceData?.religion
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
        denomination:managePreferenceData?.domain
        ? managePreferenceData?.religion
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
      cast: managePreferenceData?.caste
        ? managePreferenceData?.caste
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
        subcast:managePreferenceData?.subCaste
      ? managePreferenceData?.subCaste
          .filter((e) => e.selected === "Y")
          .map((e, i) => e.id)
      : [],
      minheight: managePreferenceData?.minheightId
        ? managePreferenceData?.minheightId
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
      maxheight: managePreferenceData?.maxheightId
        ? managePreferenceData?.maxheightId
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],

      physicalStatus: managePreferenceData?.physicalStatus
        ? managePreferenceData?.physicalStatus
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
      education: managePreferenceData?.education
        ? managePreferenceData?.education

            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
        occupation: managePreferenceData?.occupation
        ? managePreferenceData?.occupation

            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
      employment: managePreferenceData?.employedIn
        ? managePreferenceData?.employedIn
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
      star: managePreferenceData?.star
        ? managePreferenceData?.star
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
      raasi: managePreferenceData?.raasi
        ? managePreferenceData?.raasi
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
      dosam: managePreferenceData?.dosham
        ? managePreferenceData?.dosham
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
      foodhabits: managePreferenceData?.food
        ? managePreferenceData?.food
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
      smoking: managePreferenceData?.smoking
        ? managePreferenceData?.smoking
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
      drinking: managePreferenceData?.drinking
        ? managePreferenceData?.drinking
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
        income_from: managePreferenceData?.minsalaryId
        ? managePreferenceData?.minsalaryId
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)[0] || ""
        : "",
        income_to: managePreferenceData?.maxsalaryId
        ? managePreferenceData?.maxsalaryId
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)[0] || "" 
        : ""
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({}),

    onSubmit: (values) => {
      setSubmitStatus(true)
      apiCallSubmitPreference(values);
    },
  });

  const isChristian = formik.values.religion?.includes(Constants.christialId)
  useEffect(() => {
    //console.log("editMyProfileData", editbasicinfofreshData);
  }, [
    isShowEdit,
    editbasicinfofreshData,
    editreligioninfofreshData,
    edithoroinfofreshData,
    educationinfofreshData,
    editlocationinfofreshData,
    familyinfofreshData,
  ]);

  const apiCallSubmitPreference = async (value) => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      age_from: value.agefrom,
      age_to: value.ageTo,
      height_from: value.minheight[0],
      height_to: Array.isArray(value.maxheight) ? value.maxheight[0] : value.maxheight,
      maritalStatusId: value.maritalStatus,
      motherTongueId: value.motherTongue,
      religionId: value.religion,
      casteId: value.cast,
      subcasteId: value.subcast,
      stateId:value.state?.map(id=>parseInt(id)),
      countryId: value.country,
      cityId: value.city,
      physicalStatusId: value.physicalStatus,
      educationId: value.education,
      employedInId: value.employment,
      occupationId:value.occupation,
      starId: value.star,
      raasiId: value.raasi,
      doshamId: value.dosam,
      foodId: value.foodhabits,
      drinkingId: value.drinking,
      smokingId: value.smoking,
      income_from: managePreferenceData?.minsalaryId
      ? managePreferenceData?.minsalaryId
          .filter((e) => e.id == value.income_from)
          .map((e, i) => e.name).toString()
      : "" ,
      income_to: managePreferenceData?.maxsalaryId
      ? managePreferenceData?.maxsalaryId
          .filter((e) => e.id == value.income_to)
          .map((e, i) => e.name).toString()
      : "" ,
      "editFrom": props?.isFrom=="preferencedetails" && localStorage.getItem("profiledot") != "true" ?"profile_preview":"Myprofile",

    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.managepreferencessaveUrl()}`,
      request
    );

    if (statusCode === 200) {
      if (data.data.status === "SUCCESS") {
        setSubmitStatus(false)
        props.callBackReload();
        // setEditView(false);
        if(props?.isFrom=="PEDetails"){
          setShowProfileBottomTab(true)
          props?.apicallcount()
        }
        
        onClickTrack(EventName.profilePartnerPrefernceSaveButton , {[EventKeys.profilePrefernce]:value})
      }
    } else if (statusCode === 401) {
      logout();
    }
  };



 

    
const apicallsearchpagenewcount = async (value) => {

  const maritalStatusNames = managePreferenceData?.maritalStatus
  ? managePreferenceData.maritalStatus
      .filter((e) => value.maritalStatus?.includes(e.id))
      .map((e) => e.name)
  : [];
let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      ageFrom: value.agefrom,
      ageTo: value.ageTo,
      heightFrom: value.minheight?.[0],
      heightTo:Array.isArray(value.maxheight) ? value.maxheight?.[0] : value.maxheight,
      marital_status: maritalStatusNames,
      mother_tongue: value.motherTongue,
      religionIdList: value.religion,
      domainIdList:value.religion?.includes(Constants.christialId)?value.denomination:[],
      casteIdList: value.cast,
      subcasteId: value.subcast,
      countryIdList: value.country?.map(id=>parseInt(id)),
      stateIdList:value.state?.map(id=>parseInt(id)),
      cityIdList: value.city?.map(id=>parseInt(id)),
      physicalStatus: value.physicalStatus,
      educationId: value.education,
      employedInIdList: value.employment,
      occupationIdList: value.occupation,
      starIdList: value.star,
      raasiIdList: value.raasi,
      doshamIdList: value.dosam,
      foodIdList: value.foodhabits,
      drinkingIdList: value.drinking,
      smokingIdList: value.smoking,
      minincome: managePreferenceData?.minsalaryId
      ? managePreferenceData?.minsalaryId
          .filter((e) => e.id == value.income_from)
          .map((e, i) => e.name).toString()
      : "" ,
      maxincome: managePreferenceData?.maxsalaryId
      ? managePreferenceData?.maxsalaryId
          .filter((e) => e.id == value.income_to)
          .map((e, i) => e.name).toString()
      : "" ,
    
    };


    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.searchpagenewcountUrl()}`,
      request
    );   

    if (statusCode === 200) {
        
      
        setShowpeCount(data.data.resultCount)
        
    } else if (statusCode === 401) {
      logout();
    }
  };



  useEffect(() => {
    const timer = setTimeout(() => {
      if (formik.values) {
        apicallsearchpagenewcount(formik.values);
      }
    }, 500); // Delay to avoid frequent calls
  
    return () => clearTimeout(timer);
  }, [formik.values]);
  
  

  const { onClickTrack } = useAnalytics()

  console.log(props?.dashboard1Data,isShowEdit,"dashboard1Data");
 
  return ( 
    <div>
      {isShowEdit ? (
        <></>
      ) : (
        <div className="p-5 md:mb-8 md:p-0">
            <ActionRestrict>
              <EditButton
                editCallBack={() => {
                  setEditView(true);
                }}
              />
            </ActionRestrict>
      {props?.data?.partnerPreference ?
      <>
          <div className=" border-b-[1px] border-[#707070] mx-[1rem] pb-[1rem]   ">
            <PreferenceTextView 
              title={trans('view_profile.marital_status')}
              value={props?.data?.partnerPreference[0].MaritalStatus}
            />
            <PreferenceTextView
              title={"Age"}
              value={props?.data?.partnerPreference[0].Age.replace(/(\b\d+\s*yrs)-(\d+\s*yrs\b)/g, '$1 - $2').replace(/(\d+)/g, '$1 ')}

            
            />
            <PreferenceTextView
              title={trans('view_profile.height')}
              value={props?.data?.partnerPreference[0]?.Height?.replace(/(\b\d+\s*ft)-(\d+\s*ft\b)/g, '  $1 - $2')?.replace(/(\d+ft\s*\d+in)-(\d+ft\s*\d+in)/g, '$1 - $2' ,)}
            />
            <PreferenceTextView
              title={trans('view_profile.physical_status')}
              value={props?.data?.partnerPreference[0].PhysicalStatus}
            />
            <PreferenceTextView
              title={trans('view_profile.mother_tongue')}
              value={props?.data?.partnerPreference[0].MotherTongue}
            />
            <PreferenceTextView
              title={trans('view_profile.religion')}
              value={props?.data?.partnerPreference[0].Religion}
            />
            {
              isChristian && <PreferenceTextView
              title={trans('view_profile.denomination')}
              value={props?.data?.partnerPreference[0].Domain}
            />
            }
          </div>
          <div className=" border-b-[1px] border-[#707070] mx-[1rem] pb-[1rem]   ">
            <PreferenceTextView
              title={trans('view_profile.caste')}
              value={props?.data?.partnerPreference[0].Caste}
            />
            <PreferenceTextView
              title={trans('set_your_partner_expectations.subcaste')}
              value={props?.data?.partnerPreference[0].SubCaste}
            />
            <PreferenceTextView
              title={trans('view_profile.star')}
              value={props?.data?.partnerPreference[0].Star}
            />
            <PreferenceTextView
              title={trans('view_profile.raasi')}
              value={props?.data?.partnerPreference[0].Raasi}
            />
            <PreferenceTextView
              title={trans('view_profile.dosham')}
              value={props?.data?.partnerPreference[0].Dosham}
            />
          </div>
          <div className=" border-b-[1px] border-[#707070] mx-[1rem] pb-[1rem]   ">
            <PreferenceTextView
              title={trans('view_profile.education')}
              value={props?.data?.partnerPreference[0].Education}
            />
            <PreferenceTextView
              title={trans('view_profile.occupation')}
              value={props?.data?.partnerPreference[0].Occupation}
            />
            <PreferenceTextView
              title={trans('view_profile.employment_type')}
              value={props?.data?.partnerPreference[0].EmployedIn}
            />
            <PreferenceTextView
              title={trans('view_profile.monthly_income')}
              value={props?.data?.partnerPreference[0].Salary}

            
            />
          </div>
          <div className=" border-b-[1px] border-[#707070] mx-[1rem] pb-[1rem]   ">
            <PreferenceTextView
              title={trans('view_profile.country')}
              value={props?.data?.partnerPreference[0].Country}
            />
            <PreferenceTextView
              title={"State"}
              value={props?.data?.partnerPreference[0].State}
            />
            <PreferenceTextView
              title={trans('set_your_partner_expectations.city')}
              value={props?.data?.partnerPreference[0].City}
            />
          </div>
          <div className="mx-[1rem] ">
            <PreferenceTextView
              title={trans('view_profile.food_habits')}
              value={props?.data?.partnerPreference[0].Food}
            />
            <PreferenceTextView
              title={trans('view_profile.smoking')}
              value={props?.data?.partnerPreference[0].Smoking}
            />
            <PreferenceTextView
              title={trans('view_profile.drinking')}
              value={props?.data?.partnerPreference[0].Drinking}
            />
          </div>
      </>
      :
    <p className="mb-4 w-full mt-2 px-[1rem] md:text-[12px] lg:text-[14px] text-[#7A7A7A] font-semibold break-words text-center">{trans('my_profile.not_specified')}</p>      }
        </div>
      )}
      <form onSubmit={formik.handleSubmit}>
        {/* <!-- edit part  --> */}
        {isShowEdit ? (
          <div className="p-5 md:p-0">
            <div className="border-b-[1px] border-[#707070] pb-[1rem] ">
              <p className="text-black mt-[1rem] md:pl-[1rem] lg:pl-[2rem] font-semibold text-[15px] lg:text-[18px] ">
                {trans('my_profile.basic_expectations')}
              </p>
              <div className="md:pl-[1rem] lg:pl-[2rem] ">
              <BasicPeference 
                data={formik}
                apicallsearchpagenewcount={apicallsearchpagenewcount}
                editbasicinfofreshData={managePreferenceData}
                updateFromData={(e)=>{
                  if(e.target.value > formik.values?.ageTo){
                    setPreferenceData({ ...managePreferenceData, "minage": e.target.value ,"maxage": e.target.value });
                  }else{
                    setPreferenceData({ ...managePreferenceData, "minage": e.target.value });
                  }
                }}
                updateToData={(e)=>{
                  setPreferenceData({ ...managePreferenceData, "minage": formik.values.agefrom ,"maxage": e.target.value });
                }}
                editfromadmin={props.editfromadmin}
                ShowBottomTab={setShowBottomTab}
                isFrom="basicPE"
              />
              </div>
            </div>
            <div className="border-b-[1px] border-[#707070] pb-[1rem] mt-[1rem] ">
              <p className="text-black mt-[1rem] md:pl-[1rem] lg:pl-[2rem] font-semibold text-[15px] lg:text-[18px] ">
              {trans('my_profile.physical_attributes')}
              </p>
              
                <PhysicalAttributes
                  data={formik}
                  apicallsearchpagenewcount={apicallsearchpagenewcount}
                  editbasicinfofreshData={managePreferenceData}
                  updateData={(e)=>{
                    setPreferenceData({ ...managePreferenceData, selected: e.selected === "Y" ? "N" : "Y" });
                  }}
                  editfromadmin={props.editfromadmin}
                  ShowBottomTab={setShowBottomTab}
                  isFrom="basicPE"
                />              
            </div>
            <div className="border-b-[1px] border-[#707070] pb-[1rem] mt-[1rem] ">            
              <EducationPreference
                data={formik}
                educationinfofreshData={managePreferenceData}
                editfromadmin={props.editfromadmin}
                apicallsearchpagenewcount={apicallsearchpagenewcount}
                ShowBottomTab={setShowBottomTab}
                 isFrom="basicPE"
              />              
            </div>
            <div className="border-b-[1px] border-[#707070] pb-[1rem] mt-[1rem] ">
              <p className="text-black mt-[1rem] md:pl-[1rem] lg:pl-[2rem] font-semibold text-[15px] lg:text-[18px]   ">
                {trans('my_profile.astrological_information')}
              </p>
              <div className="md:pl-[1rem] lg:pl-[2rem] ">
              <AstrologicalPreference
                data={formik}
                edithoroinfofreshData={managePreferenceData}
                editfromadmin={props.editfromadmin}
                apicallsearchpagenewcount={apicallsearchpagenewcount}
                ShowBottomTab={setShowBottomTab}
                 isFrom="basicPE"
              />
              </div>
            </div>
            <div className="mt-[1rem] ">
              <p className="text-black mt-[1rem] md:pl-[1rem] lg:pl-[2rem] font-semibold text-[15px] lg:text-[18px] ">
                {trans('my_profile.habits')}
              </p>
              <div className="md:pl-[1rem] lg:pl-[2rem] ">
              <HabitsPreference
                data={formik}
                managePreferenceData={managePreferenceData}
                editfromadmin={props.editfromadmin}
                apicallsearchpagenewcount={apicallsearchpagenewcount}
                ShowBottomTab={setShowBottomTab}
                 isFrom="basicPE"
              />
              </div>
            </div>
            <SaveCancelButton
              saveCallBack={(e) => {
                e.preventDefault();
                props?.apicallcount()
              }}
              cancelCallback={() => {
                // setEditView(false);
                // props.refresh();
                formik.resetForm()
                onClickTrack(EventName.profilePartnerPrefernceCancelButton)
              }}
              submitStatus={submitStatus}
              editfromadmin={props.editfromadmin}
              isFrom="MyProfile"
            />
          </div>
        ) : (
          <></>
        )}
        {/* <div className=" flex justify-center space-x-3  items-center mt-[1rem] mb-[2rem] ">
        <button className=" flex items-center justify-center  h-[1.5rem]  text-white bg-[#D10A11] border border-[#D10A11] md:px-[2.3rem] lg:px-[3rem] whitespace-nowrap rounded-[5px] font-bold  md:py-[1rem] lg:py-5 md:text-[12px] lg:text-[14px]">
          Save
        </button>
        <button className=" flex items-center justify-center  h-[1.5rem]  text-[#575556] bg-white border border-[#707070] md:px-[2.3rem] lg:px-[3rem] whitespace-nowrap rounded-[5px] font-bold  md:py-[1rem] lg:py-5 md:text-[12px] lg:text-[14px]">
          Cancel
        </button>
      </div> */}
      </form>
    </div>
  );
};

export default PartnerPreferenceComponent;
