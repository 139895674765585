import React, { useEffect, useState, useContext, useMemo } from "react";
import {
  BasicEditTextView,
  BasicTextView,
  BasiSelectView,
  EditButton,
  SaveCancelButton,SelectViewWithHeading
} from "../EditProfileUtills";
import { useFormik } from "formik";
import { LoginContext } from "../../../Contexts/LoginContext";
import * as Yup from "yup";
import EndPoints from "../../../Constants/EndPoints";
import { IP_Address } from "../../../Constants/IpAddress";
import { POST, GET } from "../../../Services/api_services";
import config from "../../../config";
import { validateNumber } from "../../../Utils";
import Constants, {
  EventKeys,
  EventName,
} from "../../../Constants/constants";
import { useAnalytics } from "../../../Hooks/usePageChange";
import ActionRestrict from "../../Kalyanlite/Common/ActionRestrict";
import { getDeviceInfo, getGroupAuthId } from "../../Kalyanlite/utils";
import { useTranslation } from "react-i18next";
import { educationicon, employmentin, incomicon, occupation } from "../../Kalyanlite/assets/images";
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";
import AfterregistrationSelectInput from "./AfterregistrationSelectInput";

const ProfessionalComponent = (props) => {
  const [isShowEdit, setEditView] = useState(true);
  const { loginDetail, logout } = useContext(LoginContext);
  const [eduactionDropDown, seteduactionDropDown] = useState([]);
  const [employmentDropDown, setemploymentDropDown] = useState([]);
  const [occupationDropDown, setoccupationDropDown] = useState([]);
  const [currencyDropDown, setcurrencyDropDown] = useState([]);
  const [submitStatus, setSubmitStatus] = useState(false)
  const [toastmsg,setToastmsg]=useState(false)
  const [disablesave,setDisableSave] = useState(true)
  const isMobile = useMediaQuery({ maxWidth: 678 });

  const { t: trans } = useTranslation();

console.log(props.editfromadmin,'props.editfromadmin');

  const formik = useFormik({
    initialValues: {
      eduaction: props?.data?.education ? props?.data?.education[0]?.id : "",
      employment: props?.data?.employedIn ? props?.data?.employedIn[0]?.id : "",
      occupation: props?.data?.occupation ? props?.data?.occupation[0]?.id : "",
      income: props?.data?.monthlyIncome ? props?.data?.monthlyIncome : "",
      currency: props?.data?.currency ? props?.data?.currency : "",
      descriptionEdit:props?.data?.descriptionEdit,
      descriptionContentId:props?.data?.descriptionContentId,
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      eduaction: Yup.string().required("Please Select Education"),
      employment: Yup.string().required("Please Select Employment Type"),
      occupation: Yup.string().required("Please Select Occupation"),
      income: Yup.string(),
    }),

    onSubmit: (values) => {
      setSubmitStatus(true)
      apiCallProfessional(values);
    },
  });
  // //console.log(formik.values.income,"sub");
  // //console.log(props?.data?.monthlyIncome ,"sub2");
  const SubmitCall = (value) => {
    //console.log(value);
  };
  useEffect(() => {
    seteduactionDropDown(props?.dropDown?.educationData);
    setemploymentDropDown(props?.dropDown?.employedInData);
    setoccupationDropDown(props?.dropDown?.occupationData);
    setcurrencyDropDown(props?.dropDown?.currency);
  }, [props?.dropDown]);


  
  const apiCallProfessional = async (value) => {
    
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      education: value.eduaction,
      occupationId: value.occupation ,
      employedInId: value.employment,
      currencyName: value.currency,
      monthly: value.income ,
      descriptionEdit:value?.descriptionEdit,
      descriptionContentId:value?.descriptionContentId,
      registerfrom:"Desktop",
      ...(value.income != "" && {monthlyincomerequesttypeid:20}),
      requestfulfilledfrom:getDeviceInfo(),
      requestfulfilledvia: props?.isFrom=="professionaldetails" && localStorage.getItem("profiledot") != "true" ?"profile_preview":props?.isFrom=="editmyprofile" ?"Myprofile" :
      localStorage.getItem("profiledot") == "true" ?"profile_preview":"chat",
      recieverprofileid:getGroupAuthId() ,
      "editFrom": props?.isFrom=="professionaldetails" && localStorage.getItem("profiledot") != "true" ?"profile_preview":props?.isFrom=="editmyprofile" ?"Myprofile" :
      localStorage.getItem("profiledot") == "true" ?"profile_preview_three_dots":"Myprofile",

      loginFrom: localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice)

    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.editeducationinfosaveUrl()}`,
      request
    );

    if (statusCode === 200) {
      if (data.data.status === "SUCCESS") {
        setSubmitStatus(false)
        setDisableSave(true)
        props.callBackReload();
        // setEditView(false);
        if(toastmsg){
          toast.success(
            <div className='flex items-center'>
              {"Detail added Successfully. The added detail will reflect in your profile after verification by Kalyan Matrimony."}
            </div>,
          
          );
}

        onClickTrack(EventName.profileProfessionalSaveButton , {[EventKeys.profileProfessional]:value})
      }
    } else if (statusCode === 401) {
      logout();
    }
  };

  const { onClickTrack } = useAnalytics()



  const formattedEducationOptions = useMemo(() => {
    const groupedOptions = eduactionDropDown?.reduce((acc, option) => {
      if (option.heading === "Y") {
        acc.push({
          label: option.name,
          options: []
        });
      } else if (acc.length > 0) {
        acc[acc.length - 1].options.push({
          label: option.name,
          value: option.id
        });
      }
      return acc;
    }, []);
    return groupedOptions ?? [];
  }, [eduactionDropDown]);

  const selectedEducation = useMemo(() => {
    return formattedEducationOptions
      ?.flatMap(group => group.options)
      ?.find(option => option.value == formik.values.eduaction);
  }, [formattedEducationOptions, formik.values.eduaction]);


  const formattedOccupationOptions = useMemo(() => {
    const groupedOptions = occupationDropDown?.reduce((acc, option) => {
      if (option.heading === "Y") {
        acc.push({
          label: option.name,
          options: []
        });
      } else if (acc.length > 0) {
        acc[acc.length - 1].options.push({
          label: option.name,
          value: option.id
        });
      }
      return acc;
    }, []);
    return groupedOptions ?? [];
  }, [occupationDropDown]);

  const selectedOccupation = useMemo(() => {
    return formattedOccupationOptions
      ?.flatMap(group => group.options)
      ?.find(option => option.value == formik.values.occupation);
  }, [formattedOccupationOptions, formik.values.occupation]);

  console.log({formattedEducationOptions});


  return (
    <div>
      {isShowEdit ? (
        <></>
      ) : (
        <div className="md:mb-8 p-5 md:p-0">
          <ActionRestrict>
          <EditButton
            editCallBack={() => {
              setEditView(true);
            }}
          />
          </ActionRestrict>

          <BasicTextView
            title={trans('view_profile.education')}
            value={
              props?.data?.education
                ? props?.data?.education[0].name
                : trans('my_profile.not_specified')
            }
          />
          <BasicTextView
            title={"Employment in"}
            value={
              props?.data?.employedIn
                ? props?.data?.employedIn[0].name
                : trans('my_profile.not_specified')
            }
          />
          <BasicTextView
            title={trans('view_profile.occupation')}
            value={
              props?.data?.occupation
                ? props?.data?.occupation[0].name
                : trans('my_profile.not_specified')
            }
          />
          <BasicTextView
            title={trans('view_profile.monthly_income')}
            value={
              props?.data?.monthlyIncome && props?.data?.currency
                ? (props?.data?.currency)+" "+(props?.data?.monthlyIncome)
                : trans('my_profile.not_specified')
            }
          />
        </div>
      )}

      {/* <!-- edit part  --> */}
      {props?.isFrom=="professionaldetails"?
       <form onSubmit={formik.handleSubmit}>
       {isShowEdit ? (
         <div className="p-0 lg:px-4">
           <AfterregistrationSelectInput
             title={trans('my_profile.highest_education')}
             inputID={"eduaction"}
             inputName={"eduaction"}
             value={selectedEducation}
             onChange={(selected) => {
              formik.setFieldValue('eduaction', selected?.value || '');
              setDisableSave(false);
            }}
             error={formik.errors.eduaction}
             options={formattedEducationOptions}
             required={true}
             disabled={props.editfromadmin == 'Y'}
             isFrom="profesdetails"
             icon={educationicon}
             customClassName={"mt-[1rem]"}
           />
         
         <BasiSelectView
             title={trans('view_profile.employment_type')}
             inputID={"employment"}
             inputName={"employment"}
             value={formik.values.employment}
             onChange={(e)=>{formik.handleChange(e)
              setDisableSave(false)
             }}
               error={formik.errors.employment}
             options={employmentDropDown}
             required={true}
             isDisabled={props.editfromadmin == 'Y'}
              isFrom="profesdetails"
              
              icon={employmentin}
           />
           <AfterregistrationSelectInput
             title={trans('view_profile.occupation')}
             inputID={"occupation"}
             inputName={"occupation"}
             value={selectedOccupation}
             onChange={(selected)=>{
              formik.setFieldValue('occupation',selected?.value)
              setDisableSave(false)
             }}
             error={formik.errors.occupation}
             options={formattedOccupationOptions}
             required={true}
             disabled={props.editfromadmin == 'Y'}
              isFrom="profesdetails"
              icon={occupation}
           />
          
           <div className=" md:grid grid-cols-9 mt-[2rem] ">
             <div className="lg:col-span-3 md:col-span-3 xl:col-span-2 col-span-2 hidden md:flex items-center  md:pl-[1rem] lg:pl-[2rem] ">
               <p className=" md:text-[12px] lg:text-[14px] flex text-[#7A7A7A] font-semibold flex gap-3 items-center ">
                <img alt="" src={incomicon} className="w-[2.5rem] h-[2.5rem]"/> {trans('view_profile.monthly_income')}
                 {/* <span className="text-[#D10A11]">*</span> */}
               </p>
             </div>
             {/* <div className="md:col-span-4 col-span-9 md:pr-[1rem] lg:pr-[4rem]  md:pl-[7rem]">                
                 <div className="flex lg:grid lg:grid-cols-9 space-x-3 ">
                   <div className="lg:col-span-2"> */}

             <div className="col-span-9  lg:col-span-6 xl:col-span-4 md:col-span-6    md:pr-[1rem] xl:pr-[4rem] md:pl-[4rem]  lg:pr-[10rem] lg:pl-[3rem] xl:pl-[7rem]">                
                 <div className="flex">
                    <img alt="" src={incomicon} className="w-[2.5rem] h-[2.5rem] block md:hidden"/>
                   <div className="pl-[12px] md:pl-0 pr-[12px] ">
                    <label className="select font-bold text-[#575556]  ">
                     <select
                       name="currency"
                       id="currency"
                       value={formik.values.currency}
                       onChange={(e)=>{formik.handleChange(e)
                        setDisableSave(false)
                        setToastmsg(true)
                       }}
                       disabled={props.editfromadmin == 'Y'}
                       className={`w-[5rem] md:p-[0.4rem] lg:p-2.5  border border-[#8F8F8F] font-semibold text-[#575556] md:text-[12px] lg:text-[14px] bg-white rounded-[8px]  ${props.editfromadmin == 'Y' ? 'cursor-not-allowed' : ''} `}
                     >
                       {currencyDropDown?.map((e, i) => (
                         <option key={i} value={e.currency}>
                           {e.currency}
                         </option>
                       ))} 
                     </select>
                   </label></div>
                   <div className="w-full items-center pt-[5px] RegPhone relative">
                    <label
                      className={`absolute -top-2 left-3 bg-white px-1 text-[11px] text-[#575556] transition-all duration-200 
                      peer-placeholder-shown:top-2.5 peer-placeholder-shown:left-4 peer-placeholder-shown:bg-transparent 
                      peer-placeholder-shown:text-gray-400 peer-placeholder-shown:text-base 
                      peer-focus:-top-3 peer-focus:left-3 peer-focus:bg-white peer-focus:text-[#8F8F8F] md:hidden`}
                    >
                      {trans('view_profile.monthly_income')}
                    </label>
                   <p className={`${isMobile ? "pepeer block w-full bg-transparent text-gray-900" : ""} items-center   RegPhone`}>
                     <input
                       type="tel"
                       id="income"
                       name="income"
                       className={`RegPhone  border border-[#8F8F8F] w-[100%] md:w-[11.5rem]  text-sm  rounded-[3px] h-[35px]  block lg:w-full p-[0.4rem] lg:p-2.5 font-semibold text-[#575556] ${props.editfromadmin == 'Y' ? 'cursor-not-allowed' : ''}`}
                       value={formik.values.income}
                       onChange={(e)=>{
                         if(validateNumber(e.target.value)){
                           formik.handleChange(e)
                           setDisableSave(false)
                           setToastmsg(true)
                         }
                       }}
                       disabled={props.editfromadmin == 'Y'}
                     />
                   </p>
                 </div>
                 {formik.errors.income && (
                   <p className="text-[red] text-[12px]">
                     {formik.errors.income}
                   </p>
                 )}       
                 </div>        
             </div>
           </div>
           <SaveCancelButton
             saveCallBack={(e) => {
              if(props.editfromadmin || !disablesave){
                e.preventDefault();
                  setToastmsg(true)
                  console.log("Toastmsg", toastmsg); // Check if the state is being set correctly


              }
             }}
             cancelCallback={() => {
               // setEditView(false);
               formik.resetForm();
               setDisableSave(true)
               props.refresh();
               onClickTrack(EventName.profileProfessionalCancelButton);
             }}
             submitStatus={submitStatus}
             editfromadmin = {props.editfromadmin}
            //  disableSave={!formik.dirty}
             setDisableSave={setDisableSave}
             disablesave={disablesave}
             isFrom="MyProfile"
             isFroms="profileaddDetails"           />
         </div>
       ) : (
         <></>
       )}
     </form>
      :
      <form onSubmit={formik.handleSubmit}>
        {isShowEdit ? (
          <div className="p-5 md:p-0">
            <SelectViewWithHeading
              title={trans('my_profile.highest_education')}
              inputID={"eduaction"}
              inputName={"eduaction"}
              value={formik.values.eduaction}
              onChange={(e)=>{formik.handleChange(e) 
                props?.setShowBottom(false)
              }}
              error={formik.errors.eduaction}
              options={eduactionDropDown}
              required={true}
              disabled={props.editfromadmin == 'Y'}
            />
            {/* <SelectInput
              name="employment"
              title="Employment Type"
              value={selectedCountry}
              onChange={(selected, ev) => {
                formik.setFieldValue("employment", selected.id)
              }}
              error={formik.errors.employment}
              defaultValue={formik.values.employment}
              options={[{ id: "", name: "Select", isDisabled: true  }, ...countryDropDown]}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
            /> */}
            <BasiSelectView
              title={trans('view_profile.employment_type')}
              inputID={"employment"}
              inputName={"employment"}
              value={formik.values.employment}
               onChange={(e)=>{formik.handleChange(e) 
                props?.setShowBottom(false)
              }}
              error={formik.errors.employment}
              options={employmentDropDown}
              required={true}
              isDisabled={props.editfromadmin == 'Y'}
            />
            <SelectViewWithHeading
              title={trans('view_profile.occupation')}
              inputID={"occupation"}
              inputName={"occupation"}
              value={formik.values.occupation}
               onChange={(e)=>{formik.handleChange(e) 
                props?.setShowBottom(false)
              }}
              error={formik.errors.occupation}
              options={occupationDropDown}
              required={true}
              disabled={props.editfromadmin == 'Y'}
            />
            {/* <BasiSelectView
              title={trans('view_profile.monthly_income')}
              inputID={"height"}
              inputName={"height"}
              value={formik.values.income}
               onChange={(e)=>{formik.handleChange(e) 
                props?.setShowBottom(false)
              }}
              error={formik.errors.income}
              options={[
                "4ft 6in - 137 cms",
                "4ft 7in - 137 cms",
                "4ft 8in - 137 cms",
                "5ft 6in - 137 cms",
              ]}
            /> */}
            <div className=" grid grid-cols-9 mt-[2rem] ">
              <div className="col-span-4 flex items-center  md:pl-[1rem] lg:pl-[2rem] ">
                <p className=" md:text-[12px] lg:text-[14px] flex text-[#7A7A7A] font-semibold  ">
                  {trans('view_profile.monthly_income')}
                  {/* <span className="text-[#D10A11]">*</span> */}
                </p>
              </div>
              <div className="md:col-span-5 col-span-9 md:pr-[1rem] lg:pr-[4rem]  md:pl-[1rem]">                
                  <div className="flex space-x-3 ">
                    <label className="select font-bold text-[#575556] w-[8rem] ">
                      <select
                        name="currency"
                        id="currency"
                        value={formik.values.currency}
                         onChange={(e)=>{formik.handleChange(e) 
                props?.setShowBottom(false)
              }}
                        disabled={props.editfromadmin == 'Y'}
                        className={`md:p-[0.4rem] lg:p-2.5  border border-[#8F8F8F] font-semibold text-[#575556] md:text-[12px] lg:text-[14px] bg-white rounded-[8px]  ${props.editfromadmin == 'Y' ? 'cursor-not-allowed' : ''} `}
                      >
                        {currencyDropDown?.map((e, i) => (
                          <option key={i} value={e.currency}>
                            {e.currency}
                          </option>
                        ))}
                      </select>
                    </label>
                    <p className=" flex items-center pt-[5px] col-span-4 RegPhone">
                      <input
                        type="tel"
                        id="income"
                        name="income"
                        className={`RegPhone  border border-[#8F8F8F]   text-sm  rounded-[3px] h-[35px]  block w-full p-[0.4rem] lg:p-2.5 font-semibold text-[#575556] ${props.editfromadmin == 'Y' ? 'cursor-not-allowed' : ''}`}
                        value={formik.values.income}
                        onChange={(e)=>{
                          if(validateNumber(e.target.value)){
                            formik.handleChange(e)
                          }
                        }}
                        disabled={props.editfromadmin == 'Y'}
                      />
                    </p>
                  </div>
                  {formik.errors.income && (
                    <p className="text-[red] text-[12px]">
                      {formik.errors.income}
                    </p>
                  )}               
              </div>
            </div>
            <SaveCancelButton
              saveCallBack={(e) => {
                e.preventDefault();
              }}
              cancelCallback={() => {
                // setEditView(false);
                formik.resetForm();
                props.refresh();
                onClickTrack(EventName.profileProfessionalCancelButton);
              }}
              submitStatus={submitStatus}
              editfromadmin = {props.editfromadmin}
              isFrom="MyProfile"
            />
          </div>
        ) : (
          <></>
        )}
      </form>}
    </div>
  );
};

export default ProfessionalComponent;
