import React, { useContext, useEffect, useState } from 'react'
import closeBtn from '../../Images/CloseIconRed.svg'
import { SelectHobbyInput } from '../Edit Profile/EditProfileUtills'
import { useFormik } from 'formik'
import EndPoints from '../../Constants/EndPoints'
import { LoginContext } from '../../Contexts/LoginContext'
import { POST } from '../../Services/api_services'
import config from '../../config'
import Loader from '../Loader'
import { useAnalytics } from '../../Hooks/usePageChange'
import Constants, { EventKeys, EventName } from '../../Constants/constants'
import { useDispatch, useSelector } from 'react-redux'
import { getDeviceInfo } from '../Kalyanlite/utils';
import { getGroupAuthId } from "../../Components/Kalyanlite/utils";
import { getConversationList } from '../../Store/Chats/Action'
import LoaderGif from '../../Gif/loader.gif'
import CloseIcon from '../../Images/Group 761@2x.png'
import { useTranslation } from "react-i18next";
import { directors, favourite, games, hobbiesicon, language, languageknow, music, musicicon, readbook, watchmovie } from './assets/images'
import { toast } from 'react-toastify'
import { NavBarContext } from '../../Contexts/NavBarContext'


const HobbyEdit = ({setShowBottom,showdatas,ShowpopupDataNotification, message, onClose, isFrom, editviatrialpack, setCompleteNow, postData,notificationId,editMyProfileData,apiCallEditProfile,editfromadmin,isFromMyProfile,isFromSms,callBackReload,KMNotificationId}) => {
    const [hobbiesInterests, setHobbiesInterests] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const { loginDetail, logout } = useContext(LoginContext);
    const {onClickTrack} = useAnalytics()
    const [submitStatus,setSubmitStatus] = useState(false)
    const [commonError, setCommonError] = useState("");
    const { t: trans } = useTranslation();
    const [toastmsg,setToastmsg]=useState(false)
    const [disablesave,setDisableSave] = useState(true)
    const {setIsRequestKey} = useContext(NavBarContext)

    const userData = useSelector(
    (state) => state?.DashBoardAPI?.ProfileData?.data,
    );
    const dispatch = useDispatch()
    
    const loggedInProfileStatus = userData?.status
    const receiverId = useSelector((state)=> state?.Chats?.currentChat?.receiverId)

    useEffect(() => {
        apiCallProfileHobbies();
    }, []);
    
    const apiCallProfileHobbies = async () => {
        if(isFrom=="hobbiesdetails"){
            setIsLoading(false)
          }
    // setIsLoading(true)
    try {
        const request = {
        email: loginDetail()[0],
        userId: loginDetail()[1],
        };

        const { statusCode, data } = await POST(
        `${config.api.API_URL}${EndPoints.getprofilehobbiesUrl()}`,
        request
        );
        if (statusCode === 200) {
            setHobbiesInterests(data?.data);
        } else if (statusCode === 401) {
        logout();
        }
    } catch (error) {
        console.error("Error fetching hobbies data:", error);
    }
    setIsLoading(false)
    };

    const formik = useFormik({
        initialValues: {
          hobbiesInterest: hobbiesInterests?.hobbiesAndInterest
            ? hobbiesInterests?.hobbiesAndInterest
                .filter((e) => e.selected === "Y")
                .map((e, i) => e.id)
            : [],
          musicListen: hobbiesInterests?.musicListen
            ? hobbiesInterests?.musicListen
                .filter((e) => e.selected === "Y")
                .map((e, i) => e.id)
            : [],
          musicDirectors: hobbiesInterests?.favoriteMusicDirectors
            ? hobbiesInterests?.favoriteMusicDirectors
                .filter((e) => e.selected === "Y")
                .map((e, i) => e.id)
            : [],
          booksRead: hobbiesInterests?.hobbiesAndInterest
            ? hobbiesInterests?.TypeofBooksYouRead.filter(
                (e) => e.selected === "Y"
              ).map((e, i) => e.id)
            : [],
          moviesWatch: hobbiesInterests?.TypeOfMoviesYouWatch
            ? hobbiesInterests?.TypeOfMoviesYouWatch.filter(
                (e) => e.selected === "Y"
              ).map((e, i) => e.id)
            : [],
          movieDirectors: hobbiesInterests?.FavoriteMovieDirectors
            ? hobbiesInterests?.FavoriteMovieDirectors.filter(
                (e) => e.selected === "Y"
              ).map((e, i) => e.id)
            : [],
          sportsFitnessActivitiesGames:
            hobbiesInterests?.SportsAndFitnessActivitiesAndGames
              ? hobbiesInterests?.SportsAndFitnessActivitiesAndGames.filter(
                  (e) => e.selected === "Y"
                ).map((e, i) => e.id)
              : [],
          favouriteCuisine: hobbiesInterests?.FavouriteCuisine
            ? hobbiesInterests?.FavouriteCuisine.filter(
                (e) => e.selected === "Y"
              ).map((e, i) => e.id)
            : [],
          languagesKnow: hobbiesInterests?.languagesYouKnow
            ? hobbiesInterests?.languagesYouKnow
                .filter((e) => e.selected === "Y")
                .map((e, i) => e.id)
            : [],
          languagesWishToKnow: hobbiesInterests?.languagesThatIwishToKnow
            ? hobbiesInterests?.languagesThatIwishToKnow
                .filter((e) => e.selected === "Y")
                .map((e, i) => e.id)
            : [],
        },
        enableReinitialize: true,
    
        onSubmit: (values) => {
            if(isFrom == 'trailpack' && countFilledFields() == 0){
                return '';
            }else{
                saveHobbiesandInterests(values);
            }
            onClickTrack(EventName.Chat_Request_fulfillment_popup_Save_Hobbies_And_Interest_Button,{[EventKeys.requestHobbiesDetails] : values})
            setIsRequestKey('')
        },
      });
    // const NotificationId = localStorage.getItem(Constants.loginLocalStorageKeys.trailPack) == 'Y' ? 
    // sessionStorage.getItem(Constants.loginLocalStorageKeys.notificationId) : userData?.popup[0]?.notificationId


    const notificationsId = sessionStorage.getItem(Constants.loginLocalStorageKeys.notificationId);
    const Notificationsid = userData?.popup[0]?.notificationId;
    
    const NotificationId =
    localStorage.getItem(Constants.loginLocalStorageKeys.trailPack) === 'Y'
        ? sessionStorage.getItem(Constants.loginLocalStorageKeys.notificationId) ||
          showdatas ||
          message ||
          ShowpopupDataNotification
        : userData?.popup[0]?.notificationId ||
          message ||
          ShowpopupDataNotification;


          console.log(NotificationId,'NotificationIdNotificationIdhap');
   
   
    const saveHobbiesandInterests = async (values) => {
      console.log(values,'snfhkfhnfdjkhdfdf');
     
      setSubmitStatus(true)
      const request = {
        hobbiesInterest : values?.hobbiesInterest,
        musicListen : values?.musicListen,
        musicDirectors : values?.musicDirectors,
        booksRead : values?.booksRead,
        moviesWatch : values?.moviesWatch,
        movieDirectors : values?.movieDirectors,
        sportsFitnessActivitiesGames : values?.sportsFitnessActivitiesGames,
        favouriteCuisine : values?.favouriteCuisine,
        languagesKnow : values?.languagesKnow,
        languagesWishToKnow : values?.languagesWishToKnow,
        // request ids
        ...(values?.hobbiesInterest?.length > 0 && {hobbiesInterestrequesttypeid:45}),
        ...(values?.musicListen?.length > 0 && {musicListenrequesttypeid:46}),
        ...(values?.musicDirectors?.length > 0 && {musicDirectorsrequesttypeid:47}),
        ...(values?.booksRead?.length > 0 &&  {booksReadrequesttypeid:48}),
        ...(values?.moviesWatch?.length > 0 &&  {moviesWatchrequesttypeid:49}),
        ...(values?.movieDirectors?.length > 0 && {movieDirectorsrequesttypeid:50}),
        ...(values?.sportsFitnessActivitiesGames?.length > 0 && {sportsrequesttypeid:51}),
        ...(values?.favouriteCuisine?.length > 0 && {cuisinerequesttypeid:52}),
        ...(values?.languagesKnow?.length > 0 && {languagesKnowrequesttypeid:63}),
        ...(values?.languagesWishToKnow?.length > 0 && {languagesWishrequesttypeid:64}),
        requestfulfilledfrom: getDeviceInfo(),
        requestfulfilledvia: isFromSms == "yes" ? "sms" : isFrom=="hobbiesdetails" && localStorage.getItem("profiledot") != "true" ?"profile_preview":isFrom=="editmyprofile" ?"Myprofile" :
        localStorage.getItem("profiledot") == "true" ?"profile_preview":  "chat",
        recieverprofileid: getGroupAuthId(),
        ...(isFrom =='trailpack' ? { editviatrialpack: editviatrialpack} : ''),
        ...(isFrom == 'trailpack' ? { notificationId: NotificationId ? NotificationId :KMNotificationId } : ''),
        ...((localStorage.getItem("chatprofileCompletion") === "true" || localStorage.getItem("profilepromptCompletion") === "true") && { actionFrom: "Profile Completion Popup" }),
        loginFrom: localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
        "editFrom": isFrom=="hobbiesdetails" && localStorage.getItem("profiledot") != "true" ?"profile_preview":isFrom=="editmyprofile" ?"Myprofile" :
        localStorage.getItem("profiledot") == "true" ?"profile_preview_three_dots":"Myprofile",

      }
        let { statusCode, data } = await POST(
          `${config.api.API_URL}${EndPoints.getprofilehobbiesSaveUrl()}`,
          request
        );
    
        if (statusCode === 200) {
            // apiCallProfileHobbies();
            if(isFromMyProfile=='MyProfile' ){
                callBackReload();
            }
            setSubmitStatus(false)
            setDisableSave(true)
            apiCallProfileHobbies()
    //         if(isFrom=="hobbiesdetails"){
    //             toast(
    //               <div className='flex items-center'>
    //                 {"Detail added Successfully. The added detail will reflect in your profile after verification by Kalyan Matrimony."}
    //               </div>,
    //               {
    //                 hideProgressBar: true, // Hides the progress bar
    //               }
    //             );
    //   }
    
            // apiCallEditProfile()
            
            // setTimeout(() => {
            //     dispatch(
            //         getConversationList({
            //           receiver_id: receiverId,
            //           receiver_status: loggedInProfileStatus,
            //         }),
            //       );
            // }, 2000);
            if(isFrom =='trailpack'){
                apiCallEditProfile()
                const filledFieldsCount = Object.values(formik.values).filter(arr => arr.length > 0).length;
                const Count = (editMyProfileData?.motherTongue?.at(0)?.id  == 33 ? filledFieldsCount == 10 : filledFieldsCount == 8)
                const atLeastOneFieldFilled = Object.values(values).some(field => field.length);
                console.log(atLeastOneFieldFilled,filledFieldsCount,'formik123');
                if (Count !== atLeastOneFieldFilled) {
                    setCommonError('*Kindly select at least one from each category');
                    return;
                }else{
                    setTimeout(() => {
                        setCompleteNow(true)
                        postData()
                    }, 2000);
                }
            }
            onClose()
        } else if (statusCode === 401) {
          logout();
        }
        else{
            setSubmitStatus(false)
            console.log("error on save hobbies");
        }
    };

    useEffect(() => {}, [hobbiesInterests]);

    const countFilledFields = () => {
        const values = formik.values;
        let count = 0;
        console.log(count,"count123");

        for (const key in values) {
            if (Array.isArray(values[key])) {
                count += values[key].length; // Count the number of selected items in arrays
            }
        }
    
        return count;

        
    };

  return (
    <>
        <div className={`hidden md:flex flex-col xl:h-full 2xl:h-full lg:h-full md:h-full h-[70vh] relative bg-white ${isFrom == 'trailpack' ? 'lg:rounded-md' : '' }`}>
            {isFromMyProfile=='MyProfile' ? '' :
                <div className={`flex items-center justify-between  px-5 py-3 ${isFrom == 'trailpack'? '' : 'bg-[#F0F2F5]'} `}>
                    <p className={` text-[#000000]  text-[12px]  ${isFrom == 'trailpack' ? 'lg:pt-[1rem] lg:px-4 font-bold text-[18px]' : 'font-semibold custom-font-family lg:text-[14px] xl:text-[16px]' }`}>{trans('trial_pack_popup__hobbies__int.add_hobbies__interests')}</p>
                    {isFrom == 'trailpack' ? 
                        <div className="">
                            <button className="hidden md:block bg-transparent z-40 border-0 text-black absolute top-[-2px] right-[-6px]"
                            onClick={() => {
                                onClose();
                                setCompleteNow(true);
                            }}
                            >
                            <img className="w-6 h-6" src={CloseIcon} alt="" />
                            </button>
                        </div>
                    :
                        <img onClick={()=>{
                            onClose();
                            setIsRequestKey('')
                            onClickTrack(EventName.Chat_Request_fulfillment_popup_Cancel_Hobbies_And_Interest_Button)
                        }} className='w-5 h-5 cursor-pointer' src={closeBtn} />
                    }
                </div>
            }
            {isLoading ?
            <Loader />
            :
            <div className={`overflow-y-auto lite-scrollbar ${isFrom == 'trailpack' ? 'md:px-5 lg:pb-5 lg:pt-0' : ''}`}>
                <form onSubmit={formik.handleSubmit}>
                    {isFrom == 'trailpack' ? 
                        <div className='grid gap-4 md:mx-5 lg:mx-0 md:grid-cols-2'> 
                                <SelectHobbyInput
                                    title={trans('trial_pack_popup__hobbies__int.hobbies__interests')}
                                    inputID={"hobbiesInterest"}
                                    inputName={"hobbiesInterest"}
                                    placeholder={isFrom == 'trailpack' ? trans('trial_pack_popup__hobbies__int.select_one_or_more') : trans('trial_pack_popup__hobbies__int.select') }
                                    value={formik.values?.hobbiesInterest}
                                    defalutValue={[formik.values?.hobbiesInterest]}
                                    onChange={(e) => {
                                        formik.setFieldValue("hobbiesInterest", Array.from(e));  
                                        setShowBottom(false)                         
                                    }}
                                    error={formik.errors.hobbiesInterest}
                                    options={hobbiesInterests?.hobbiesAndInterest}
                                    isFrom ={isFrom}
                                />

                                  <SelectHobbyInput
                                    title={trans('trial_pack_popup__hobbies__int.music_genres_you_listen_to')}
                                    inputID={"musicListen"}
                                    inputName={"musicListen"}
                                    placeholder={isFrom == 'trailpack' ? trans('trial_pack_popup__hobbies__int.select_one_or_more') : trans('trial_pack_popup__hobbies__int.select') }
                                    value={formik.values?.musicListen}
                                    defalutValue={[formik.values?.musicListen]}
                                    onChange={(e) => {
                                        formik.setFieldValue("musicListen", Array.from(e));    
                                        setShowBottom(false)                       
                                    }}
                                    error={formik.errors.musicListen}
                                    options={hobbiesInterests?.musicListen}
                                    isFrom ={isFrom}
                                />

                                <SelectHobbyInput
                                    title={trans('trial_pack_popup__hobbies__int.favorite_music_directors')}
                                    inputID={"musicDirectors"}
                                    inputName={"musicDirectors"}
                                    placeholder={isFrom == 'trailpack' ? trans('trial_pack_popup__hobbies__int.select_one_or_more') : trans('trial_pack_popup__hobbies__int.select') }
                                    value={formik.values?.musicDirectors}
                                    defalutValue={[formik.values?.musicDirectors]}
                                    onChange={(e) => {
                                        formik.setFieldValue("musicDirectors", Array.from(e));    
                                        setShowBottom(false)                       
                                    }}
                                    error={formik.errors.musicDirectors}
                                    options={hobbiesInterests?.favoriteMusicDirectors}
                                    isFrom ={isFrom}
                                />
                                <SelectHobbyInput
                                    title={trans('trial_pack_popup__hobbies__int.type_of_books_you_read')}
                                    inputID={"booksRead"}
                                    inputName={"booksRead"}
                                    placeholder={isFrom == 'trailpack' ? trans('trial_pack_popup__hobbies__int.select_one_or_more') : trans('trial_pack_popup__hobbies__int.select') }
                                    value={formik.values?.booksRead}
                                    defalutValue={[formik.values?.booksRead]}
                                    onChange={(e) => {
                                        formik.setFieldValue("booksRead", Array.from(e));  
                                        setShowBottom(false)                         
                                    }}
                                    error={formik.errors.booksRead}
                                    options={hobbiesInterests?.TypeofBooksYouRead}
                                    isFrom ={isFrom}
                                />

                                <SelectHobbyInput
                                    title={trans('trial_pack_popup__hobbies__int.type_of_movies_you_watch')}
                                    inputID={"moviesWatch"}
                                    inputName={"moviesWatch"}
                                    placeholder={isFrom == 'trailpack' ? trans('trial_pack_popup__hobbies__int.select_one_or_more') : trans('trial_pack_popup__hobbies__int.select')}
                                    value={formik.values?.moviesWatch}
                                    defalutValue={[formik.values?.moviesWatch]}
                                    onChange={(e) => {
                                        formik.setFieldValue("moviesWatch", Array.from(e));
                                        setShowBottom(false)                           
                                    }}
                                    error={formik.errors.moviesWatch}
                                    options={hobbiesInterests?.TypeOfMoviesYouWatch}
                                    isFrom ={isFrom}
                                />
                                 <SelectHobbyInput
                                    title={trans('trial_pack_popup__hobbies__int.favorite_movie_directors')}
                                    inputID={"movieDirectors"}
                                    inputName={"movieDirectors"}
                                    placeholder={isFrom == 'trailpack' ? trans('trial_pack_popup__hobbies__int.select_one_or_more') : trans('trial_pack_popup__hobbies__int.select') }
                                    value={formik.values?.movieDirectors}
                                    defalutValue={[formik.values?.movieDirectors]}
                                    onChange={(e) => {
                                        formik.setFieldValue("movieDirectors", Array.from(e));   
                                        setShowBottom(false)                        
                                    }}
                                    error={formik.errors.movieDirectors}
                                    options={hobbiesInterests?.FavoriteMovieDirectors}
                                    isFrom ={isFrom}
                                />

                                <SelectHobbyInput
                                    title={trans('trial_pack_popup__hobbies__int.sports_fitness_activities_game')}
                                    inputID={"sportsFitnessActivitiesGames"}
                                    inputName={"sportsFitnessActivitiesGames"}
                                    placeholder={isFrom == 'trailpack' ? trans('trial_pack_popup__hobbies__int.select_one_or_more') : trans('trial_pack_popup__hobbies__int.select') }
                                    value={formik.values?.sportsFitnessActivitiesGames}
                                    defalutValue={[formik.values?.sportsFitnessActivitiesGames]}
                                    onChange={(e) => {
                                        formik.setFieldValue("sportsFitnessActivitiesGames", Array.from(e));  
                                        setShowBottom(false)                         
                                    }}
                                    error={formik.errors.sportsFitnessActivitiesGames}
                                    options={hobbiesInterests?.SportsAndFitnessActivitiesAndGames}
                                    isFrom ={isFrom}
                                />

                                 <SelectHobbyInput
                                    title={trans('trial_pack_popup__hobbies__int.favourite_cuisine')}
                                    inputID={"favouriteCuisine"}
                                    inputName={"favouriteCuisine"}
                                    placeholder={isFrom == 'trailpack' ? trans('trial_pack_popup__hobbies__int.select_one_or_more') : trans('trial_pack_popup__hobbies__int.select') }
                                    value={formik.values?.favouriteCuisine}
                                    defalutValue={[formik.values?.favouriteCuisine]}
                                    onChange={(e) => {
                                        formik.setFieldValue("favouriteCuisine", Array.from(e));   
                                        setShowBottom(false)                        
                                    }}
                                    error={formik.errors.favouriteCuisine}
                                    options={hobbiesInterests?.FavouriteCuisine}
                                    isFrom ={isFrom}
                                />
                                {hobbiesInterests?.languagesYouKnow &&
                                    <SelectHobbyInput
                                        title={trans('my_profile.languages_you_know')}
                                        inputID={"languagesKnow"}
                                        inputName={"languagesKnow"}
                                        placeholder={isFrom == 'trailpack' ? trans('trial_pack_popup__hobbies__int.select_one_or_more') :trans('trial_pack_popup__hobbies__int.select')}
                                        value={formik.values?.languagesKnow}
                                        defalutValue={[formik.values?.languagesKnow]}
                                        onChange={(e) => {
                                            formik.setFieldValue("languagesKnow", Array.from(e));      
                                            setShowBottom(false)                     
                                        }}
                                        error={formik.errors.languagesKnow}
                                        options={hobbiesInterests?.languagesYouKnow}
                                        isFrom ={isFrom}
                                    />
                                }
 
                    
                               
                                {hobbiesInterests?.languagesThatIwishToKnow &&
                                    <SelectHobbyInput
                                        title={trans('my_profile.languages_that_you_wish_to_kno')}
                                        inputID={"languagesWishToKnow"}
                                        inputName={"languagesWishToKnow"}
                                        placeholder={isFrom == 'trailpack' ? trans('trial_pack_popup__hobbies__int.select_one_or_more') : trans('trial_pack_popup__hobbies__int.select') }
                                        value={formik.values?.languagesWishToKnow}
                                        defalutValue={[formik.values?.languagesWishToKnow]}
                                        onChange={(e) => {
                                            formik.setFieldValue("languagesWishToKnow", Array.from(e));   
                                            setShowBottom(false)                        
                                        }}
                                        error={formik.errors.languagesWishToKnow}
                                        options={hobbiesInterests?.languagesThatIwishToKnow}
                                        isFrom ={isFrom}
                                    />
                                }
                            </div>
                    :
                    isFrom=="hobbiesdetails" && isFromMyProfile=='MyProfile' ?

                    <div className='p-0 lg:px-7'>
                    <SelectHobbyInput
                        title={trans('trial_pack_popup__hobbies__int.hobbies__interests')}
                        inputID={"hobbiesInterest"}
                        inputName={"hobbiesInterest"}
                        placeholder={isFrom == 'trailpack' ? trans('trial_pack_popup__hobbies__int.select_one_or_more') : trans('trial_pack_popup__hobbies__int.select') }
                        value={formik.values?.hobbiesInterest}
                        defalutValue={[formik.values?.hobbiesInterest]}
                        onChange={(e) => {
                            formik.setFieldValue("hobbiesInterest", Array.from(e));    
                            setDisableSave(false)                      
                        }}
                        error={formik.errors.hobbiesInterest}
                        options={hobbiesInterests?.hobbiesAndInterest}
                        disabled={editfromadmin == 'Y'}
                        isFrom ={"hobbies"} 
                        icon ={hobbiesicon}
                />

                    <SelectHobbyInput
                    title={trans('trial_pack_popup__hobbies__int.music_genres_you_listen_to')}
                    inputID={"musicListen"}
                    inputName={"musicListen"}
                    placeholder={isFrom == 'trailpack' ? trans('trial_pack_popup__hobbies__int.select_one_or_more') : trans('trial_pack_popup__hobbies__int.select') }
                    value={formik.values?.musicListen}
                    defalutValue={[formik.values?.musicListen]}
                    onChange={(e) => {
                        formik.setFieldValue("musicListen", Array.from(e));     
                        setDisableSave(false)                     
                    }}
                    error={formik.errors.musicListen}
                    options={hobbiesInterests?.musicListen}
                    disabled={editfromadmin == 'Y'}
                    isFrom ={"hobbies"}
                    icon={musicicon}
                    />

                    <SelectHobbyInput
                    title={trans('trial_pack_popup__hobbies__int.favorite_music_directors')}
                    inputID={"musicDirectors"}
                    inputName={"musicDirectors"}
                    placeholder={isFrom == 'trailpack' ? trans('trial_pack_popup__hobbies__int.select_one_or_more') : trans('trial_pack_popup__hobbies__int.select') }
                    value={formik.values?.musicDirectors}
                    defalutValue={[formik.values?.musicDirectors]}
                    onChange={(e) => {
                        formik.setFieldValue("musicDirectors", Array.from(e));   
                        setDisableSave(false)                       
                    }}
                    error={formik.errors.musicDirectors}
                    options={hobbiesInterests?.favoriteMusicDirectors}
                    disabled={editfromadmin == 'Y'}
                    isFrom ={"hobbies"}
                    icon={music}
                    />

                    <SelectHobbyInput
                    title={trans('trial_pack_popup__hobbies__int.type_of_books_you_read')}
                    inputID={"booksRead"}
                    inputName={"booksRead"}
                    placeholder={isFrom == 'trailpack' ? trans('trial_pack_popup__hobbies__int.select_one_or_more') : trans('trial_pack_popup__hobbies__int.select') }
                    value={formik.values?.booksRead}
                    defalutValue={[formik.values?.booksRead]}
                    onChange={(e) => {
                        formik.setFieldValue("booksRead", Array.from(e));       
                        setDisableSave(false)                   
                    }}
                    error={formik.errors.booksRead}
                    options={hobbiesInterests?.TypeofBooksYouRead}
                    disabled={editfromadmin == 'Y'}
                    isFrom ={"hobbies"}
                    icon={readbook}
                    />

                    <SelectHobbyInput
                    title={trans('trial_pack_popup__hobbies__int.type_of_movies_you_watch')}
                    inputID={"moviesWatch"}
                    inputName={"moviesWatch"}
                    placeholder={isFrom == 'trailpack' ? trans('trial_pack_popup__hobbies__int.select_one_or_more') : trans('trial_pack_popup__hobbies__int.select') }
                    value={formik.values?.moviesWatch}
                    defalutValue={[formik.values?.moviesWatch]}
                    onChange={(e) => {
                        formik.setFieldValue("moviesWatch", Array.from(e)); 
                        setDisableSave(false)                         
                    }}
                    error={formik.errors.moviesWatch}
                    options={hobbiesInterests?.TypeOfMoviesYouWatch}
                    disabled={editfromadmin == 'Y'}
                    isFrom ={"hobbies"}
                    icon={watchmovie}
                    />

                    <SelectHobbyInput
                    title={trans('trial_pack_popup__hobbies__int.favorite_movie_directors')}
                    inputID={"movieDirectors"}
                    inputName={"movieDirectors"}
                    placeholder={isFrom == 'trailpack' ? trans('trial_pack_popup__hobbies__int.select_one_or_more') : trans('trial_pack_popup__hobbies__int.select') }
                    value={formik.values?.movieDirectors}
                    defalutValue={[formik.values?.movieDirectors]}
                    onChange={(e) => {
                        formik.setFieldValue("movieDirectors", Array.from(e));  
                        setDisableSave(false)                        
                    }}
                    error={formik.errors.movieDirectors}
                    options={hobbiesInterests?.FavoriteMovieDirectors}
                    disabled={editfromadmin == 'Y'}
                    isFrom ={"hobbies"}
                    icon={directors}
                    />

                    <SelectHobbyInput
                    title={trans('trial_pack_popup__hobbies__int.sports_fitness_activities_game')}
                    inputID={"sportsFitnessActivitiesGames"}
                    inputName={"sportsFitnessActivitiesGames"}
                    placeholder={isFrom == 'trailpack' ? trans('trial_pack_popup__hobbies__int.select_one_or_more') : trans('trial_pack_popup__hobbies__int.select') }
                    value={formik.values?.sportsFitnessActivitiesGames}
                    defalutValue={[formik.values?.sportsFitnessActivitiesGames]}
                    onChange={(e) => {
                        formik.setFieldValue("sportsFitnessActivitiesGames", Array.from(e)); 
                        setDisableSave(false)                         
                    }}
                    error={formik.errors.sportsFitnessActivitiesGames}
                    options={hobbiesInterests?.SportsAndFitnessActivitiesAndGames}
                    disabled={editfromadmin == 'Y'}
                    isFrom ={"hobbies"}
                    icon={games}
                    />

                    <SelectHobbyInput
                    title={trans('trial_pack_popup__hobbies__int.favourite_cuisine')}
                    inputID={"favouriteCuisine"}
                    inputName={"favouriteCuisine"}
                    placeholder={isFrom == 'trailpack' ? trans('trial_pack_popup__hobbies__int.select_one_or_more') : trans('trial_pack_popup__hobbies__int.select') }
                    value={formik.values?.favouriteCuisine}
                    defalutValue={[formik.values?.favouriteCuisine]}
                    onChange={(e) => {
                        formik.setFieldValue("favouriteCuisine", Array.from(e));       
                        setDisableSave(false)                   
                    }}
                    error={formik.errors.favouriteCuisine}
                    options={hobbiesInterests?.FavouriteCuisine}
                    disabled={editfromadmin == 'Y'}
                    isFrom ={"hobbies"}
                    icon={favourite}
                    />

                    {hobbiesInterests?.languagesYouKnow &&
                    <SelectHobbyInput
                    title={trans('my_profile.languages_you_know')}
                    inputID={"languagesKnow"}
                    inputName={"languagesKnow"}
                    placeholder={isFrom == 'trailpack' ? trans('trial_pack_popup__hobbies__int.select_one_or_more') : trans('trial_pack_popup__hobbies__int.select') }
                    value={formik.values?.languagesKnow}
                    defalutValue={[formik.values?.languagesKnow]}
                    onChange={(e) => {
                        formik.setFieldValue("languagesKnow", Array.from(e));    
                        setDisableSave(false)                      
                    }}
                    error={formik.errors.languagesKnow}
                    options={hobbiesInterests?.languagesYouKnow}
                    disabled={editfromadmin == 'Y'}
                    isFrom ={"hobbies"}
                    icon={language}
                    />
                    }

                    {hobbiesInterests?.languagesThatIwishToKnow &&
                    <SelectHobbyInput
                    title={trans('my_profile.languages_that_you_wish_to_kno')}
                    inputID={"languagesWishToKnow"}
                    inputName={"languagesWishToKnow"}
                    placeholder={isFrom == 'trailpack' ? trans('trial_pack_popup__hobbies__int.select_one_or_more') : trans('trial_pack_popup__hobbies__int.select') }
                    value={formik.values?.languagesWishToKnow}
                    defalutValue={[formik.values?.languagesWishToKnow]}
                    onChange={(e) => {
                        formik.setFieldValue("languagesWishToKnow", Array.from(e));  
                        setDisableSave(false)                        
                    }}
                    error={formik.errors.languagesWishToKnow}
                    options={hobbiesInterests?.languagesThatIwishToKnow}
                    disabled={editfromadmin == 'Y'}
                    isFrom ={"hobbies"}
                    icon={languageknow}
                    />
                    }
                    </div>
                    :

                    <>
                    <SelectHobbyInput
                        title={trans('trial_pack_popup__hobbies__int.hobbies__interests')}
                        inputID={"hobbiesInterest"}
                        inputName={"hobbiesInterest"}
                        placeholder={isFrom == 'trailpack' ? trans('trial_pack_popup__hobbies__int.select_one_or_more') : trans('trial_pack_popup__hobbies__int.select') }
                        value={formik.values?.hobbiesInterest}
                        defalutValue={[formik.values?.hobbiesInterest]}
                        onChange={(e) => {
                            formik.setFieldValue("hobbiesInterest", Array.from(e));   
                            setShowBottom(false)                        
                        }}
                        error={formik.errors.hobbiesInterest}
                        options={hobbiesInterests?.hobbiesAndInterest}
                        disabled={editfromadmin == 'Y'}
                        isFrom ={isFrom}
                />

                    <SelectHobbyInput
                    title={trans('trial_pack_popup__hobbies__int.music_genres_you_listen_to')}
                    inputID={"musicListen"}
                    inputName={"musicListen"}
                    placeholder={isFrom == 'trailpack' ? trans('trial_pack_popup__hobbies__int.select_one_or_more') : trans('trial_pack_popup__hobbies__int.select') }
                    value={formik.values?.musicListen}
                    defalutValue={[formik.values?.musicListen]}
                    onChange={(e) => {
                        formik.setFieldValue("musicListen", Array.from(e));  
                        setShowBottom(false)                         
                    }}
                    error={formik.errors.musicListen}
                    options={hobbiesInterests?.musicListen}
                    disabled={editfromadmin == 'Y'}
                    isFrom ={isFrom}
                    />

                    <SelectHobbyInput
                    title={trans('trial_pack_popup__hobbies__int.favorite_music_directors')}
                    inputID={"musicDirectors"}
                    inputName={"musicDirectors"}
                    placeholder={isFrom == 'trailpack' ? trans('trial_pack_popup__hobbies__int.select_one_or_more') : trans('trial_pack_popup__hobbies__int.select') }
                    value={formik.values?.musicDirectors}
                    defalutValue={[formik.values?.musicDirectors]}
                    onChange={(e) => {
                        formik.setFieldValue("musicDirectors", Array.from(e));  
                        setShowBottom(false)                         
                    }}
                    error={formik.errors.musicDirectors}
                    options={hobbiesInterests?.favoriteMusicDirectors}
                    disabled={editfromadmin == 'Y'}
                    isFrom ={isFrom}
                    />

                    <SelectHobbyInput
                    title={trans('trial_pack_popup__hobbies__int.type_of_books_you_read')}
                    inputID={"booksRead"}
                    inputName={"booksRead"}
                    placeholder={isFrom == 'trailpack' ? trans('trial_pack_popup__hobbies__int.select_one_or_more') : trans('trial_pack_popup__hobbies__int.select') }
                    value={formik.values?.booksRead}
                    defalutValue={[formik.values?.booksRead]}
                    onChange={(e) => {
                        formik.setFieldValue("booksRead", Array.from(e));   
                        setShowBottom(false)                        
                    }}
                    error={formik.errors.booksRead}
                    options={hobbiesInterests?.TypeofBooksYouRead}
                    disabled={editfromadmin == 'Y'}
                    isFrom ={isFrom}
                    />

                    <SelectHobbyInput
                    title={trans('trial_pack_popup__hobbies__int.type_of_movies_you_watch')}
                    inputID={"moviesWatch"}
                    inputName={"moviesWatch"}
                    placeholder={isFrom == 'trailpack' ? trans('trial_pack_popup__hobbies__int.select_one_or_more') : trans('trial_pack_popup__hobbies__int.select') }
                    value={formik.values?.moviesWatch}
                    defalutValue={[formik.values?.moviesWatch]}
                    onChange={(e) => {
                        formik.setFieldValue("moviesWatch", Array.from(e));     
                        setShowBottom(false)                      
                    }}
                    error={formik.errors.moviesWatch}
                    options={hobbiesInterests?.TypeOfMoviesYouWatch}
                    disabled={editfromadmin == 'Y'}
                    isFrom ={isFrom}
                    />

                    <SelectHobbyInput
                    title={trans('trial_pack_popup__hobbies__int.favorite_movie_directors')}
                    inputID={"movieDirectors"}
                    inputName={"movieDirectors"}
                    placeholder={isFrom == 'trailpack' ? trans('trial_pack_popup__hobbies__int.select_one_or_more') : trans('trial_pack_popup__hobbies__int.select') }
                    value={formik.values?.movieDirectors}
                    defalutValue={[formik.values?.movieDirectors]}
                    onChange={(e) => {
                        formik.setFieldValue("movieDirectors", Array.from(e));     
                        setShowBottom(false)                      
                    }}
                    error={formik.errors.movieDirectors}
                    options={hobbiesInterests?.FavoriteMovieDirectors}
                    disabled={editfromadmin == 'Y'}
                    isFrom ={isFrom}
                    />

                    <SelectHobbyInput
                    title={trans('trial_pack_popup__hobbies__int.sports_fitness_activities_game')}
                    inputID={"sportsFitnessActivitiesGames"}
                    inputName={"sportsFitnessActivitiesGames"}
                    placeholder={isFrom == 'trailpack' ? trans('trial_pack_popup__hobbies__int.select_one_or_more') : trans('trial_pack_popup__hobbies__int.select') }
                    value={formik.values?.sportsFitnessActivitiesGames}
                    defalutValue={[formik.values?.sportsFitnessActivitiesGames]}
                    onChange={(e) => {
                        formik.setFieldValue("sportsFitnessActivitiesGames", Array.from(e)); 
                        setShowBottom(false)                          
                    }}
                    error={formik.errors.sportsFitnessActivitiesGames}
                    options={hobbiesInterests?.SportsAndFitnessActivitiesAndGames}
                    disabled={editfromadmin == 'Y'}
                    isFrom ={isFrom}
                    />

                    <SelectHobbyInput
                    title={trans('trial_pack_popup__hobbies__int.favourite_cuisine')}
                    inputID={"favouriteCuisine"}
                    inputName={"favouriteCuisine"}
                    placeholder={isFrom == 'trailpack' ? trans('trial_pack_popup__hobbies__int.select_one_or_more') : trans('trial_pack_popup__hobbies__int.select') }
                    value={formik.values?.favouriteCuisine}
                    defalutValue={[formik.values?.favouriteCuisine]}
                    onChange={(e) => {
                        formik.setFieldValue("favouriteCuisine", Array.from(e));       
                        setShowBottom(false)                    
                    }}
                    error={formik.errors.favouriteCuisine}
                    options={hobbiesInterests?.FavouriteCuisine}
                    disabled={editfromadmin == 'Y'}
                    isFrom ={isFrom}
                    />

                    {hobbiesInterests?.languagesYouKnow &&
                    <SelectHobbyInput
                    title={trans('my_profile.languages_you_know')}
                    inputID={"languagesKnow"}
                    inputName={"languagesKnow"}
                    placeholder={isFrom == 'trailpack' ? trans('trial_pack_popup__hobbies__int.select_one_or_more') : trans('trial_pack_popup__hobbies__int.select') }
                    value={formik.values?.languagesKnow}
                    defalutValue={[formik.values?.languagesKnow]}
                    onChange={(e) => {
                        formik.setFieldValue("languagesKnow", Array.from(e));     
                        setShowBottom(false)                      
                    }}
                    error={formik.errors.languagesKnow}
                    options={hobbiesInterests?.languagesYouKnow}
                    disabled={editfromadmin == 'Y'}
                    isFrom ={isFrom}
                    />
                    }

                    {hobbiesInterests?.languagesThatIwishToKnow &&
                    <SelectHobbyInput
                    title={trans('my_profile.languages_that_you_wish_to_kno')}
                    inputID={"languagesWishToKnow"}
                    inputName={"languagesWishToKnow"}
                    placeholder={isFrom == 'trailpack' ? trans('trial_pack_popup__hobbies__int.select_one_or_more') : trans('trial_pack_popup__hobbies__int.select') }
                    value={formik.values?.languagesWishToKnow}
                    defalutValue={[formik.values?.languagesWishToKnow]}
                    onChange={(e) => {
                        formik.setFieldValue("languagesWishToKnow", Array.from(e));   
                        setShowBottom(false)                        
                    }}
                    error={formik.errors.languagesWishToKnow}
                    options={hobbiesInterests?.languagesThatIwishToKnow}
                    disabled={editfromadmin == 'Y'}
                    isFrom ={isFrom}
                    />
                    }
                    </>}
                    {commonError && (
                        <div className="text-red-500 text-xs mt-2 mx-[2rem]">{commonError}</div>
                    )}

                    <div className={`my-5 w-full flex justify-center items-center ${isFrom == 'trailpack' ? 'gap-3' : isFromMyProfile=='MyProfile' ? 'space-x-3' :''}`}>
                        {isFrom == 'trailpack' ?
                        <>
                            <button className=' items-center justify-center  border border-[#707070] lg:w-[9rem] w-[8rem] whitespace-nowrap rounded-[5px] font-bold py-1.5 md:text-[13px] lg:text-[15px] truncate ' 
                            onClick={() => {
                                onClose(); 
                                setCompleteNow(true);
                            }}>
                                {trans('my_profile.cancel')}
                            </button> 
                            <button className={`items-center justify-center text-white lg:w-[9rem] w-[8rem] whitespace-nowrap rounded-[5px] font-bold py-1.5 md:text-[13px] lg:text-[15px]
                             ${countFilledFields() == 0 && isFrom == 'trailpack' ? 'bg-[#8E8E8E] cursor-not-allowed' : 'bg-[#D10A11] border border-[#D10A11]'} truncate`} 
                                onClick={() => {
                                    if(isFrom == 'trailpack'){
                                        onClickTrack(EventName.Trial_Pack_popup_Add_HobbiesInterests_Submit_Button)
                                    }
                                }}
                            >
                            {submitStatus ? trans('my_profile.save') : countFilledFields() == 0 ? trans('my_profile.save'): `${trans('my_profile.save')} (${countFilledFields()})` }
                            </button>
                            </>
                        :isFromMyProfile=='MyProfile'&&isFrom=="hobbiesdetails" ? 
                        <>
                            <button  disabled={editfromadmin == 'Y' || disablesave}
                                className={`items-center flex justify-center text-white bg-[#D10A11] border border-[#D10A11] whitespace-nowrap rounded-[5px] font-bold lg:w-[6rem] w-[8rem] py-1 md:text-[12px] md:text-[12px] lg:text-[14px]
                                     ${editfromadmin == 'Y' || disablesave? 'cursor-not-allowed bg-[#E78387]' : ''}`}
                            >
                                 
                            {submitStatus ? <><img src={LoaderGif} className="brightness-0 invert-[1] w-5 h-5" alt=""/><span>{trans('my_profile.saving')}</span></> : <span>{trans('my_profile.save')}</span>}
                            </button>
                            <button  type='reset'
                                onClick={(e)=>{
                                        e.preventDefault();
                                        formik.resetForm();
                                        setDisableSave(true)

                                }} disabled={editfromadmin == 'Y' }
                                className={`items-center justify-center text-[#575556] bg-white border border-[#707070] whitespace-nowrap rounded-[5px] font-bold lg:w-[8rem] w-[8rem] py-1 md:text-[12px] lg:text-[14px]
                                     ${editfromadmin == 'Y' ? 'cursor-not-allowed bg-[#E78387]' : ''} truncate px-2`}
                            >
                            {trans('my_profile.discard_changes')}
                            </button>
                        </> :
                        isFromMyProfile=='MyProfile' ? 
                        <>
                            <button  disabled={editfromadmin == 'Y'}
                                className={`items-center justify-center text-white bg-[#D10A11] border border-[#D10A11] whitespace-nowrap rounded-[5px] font-bold lg:w-[8rem] w-[8rem] py-1 md:text-[12px] lg:text-[14px] ${editfromadmin == 'Y' ? 'cursor-not-allowed' : ''} truncate`}
                            >
                                
                            {submitStatus ? <><img src={LoaderGif} className="brightness-0 invert-[1] w-5 h-5" alt=""/><span>{trans('my_profile.saving')}</span></> : <span>{trans('my_profile.save')}</span>}
                            </button>
                            <button  type='reset'
                                onClick={(e)=>{
                                    e.preventDefault();
                                    formik.resetForm();
                                }} disabled={editfromadmin == 'Y'}
                                className={`items-center justify-center text-[#575556] bg-white border border-[#707070] whitespace-nowrap rounded-[5px] font-bold lg:w-[8rem] w-[8rem] py-1 md:text-[12px] lg:text-[14px] ${editfromadmin == 'Y' ? 'cursor-not-allowed' : ''} truncate px-2`}
                            >
                            {trans('my_profile.discard_changes')}
                            </button>
                        </> :
                        <div className='flex items-center justify-center w-full my-5'>
                            <button className='flex items-center justify-center text-white bg-[#D10A11] border border-[#D10A11] lg:w-[9rem] w-[8rem] whitespace-nowrap rounded-[5px] font-bold py-1.5 md:text-[13px] lg:text-[15px]'>
                                {formik.isSubmitting ? <div className='flex items-center justify-center p-1'><img src={LoaderGif} className="brightness-0 invert-[1] w-5 h-5" alt=""/><span>{trans('my_profile.saving')}</span></div> : trans('my_profile.save')}
                            </button>
                        </div>}
                    </div>

                </form>
            </div>
            }
        </div>
        
        {/* mobile popup */}
        {isFromMyProfile=='MyProfile' ? 
        <form onSubmit={formik.handleSubmit} className={`md:p-3 md:hidden lg:hidden`}>
            <SelectHobbyInput
                title={trans('trial_pack_popup__hobbies__int.hobbies__interests')}
                inputID={"hobbiesInterest"}
                inputName={"hobbiesInterest"}
                placeholder={trans('trial_pack_popup__hobbies__int.select')}
                value={formik.values?.hobbiesInterest}
                defalutValue={[formik.values?.hobbiesInterest]}
                onChange={(e) => {
                    formik.setFieldValue("hobbiesInterest", Array.from(e));  
                setShowBottom(false)                        
                }}
                error={formik.errors.hobbiesInterest}
                options={hobbiesInterests?.hobbiesAndInterest}
                disabled={editfromadmin == 'Y'}
                isFrom={isFrom == "hobbiesdetails" && "hobbiesdetails"}
                icon={isFrom == "hobbiesdetails" && hobbiesicon}
                
            />

            <SelectHobbyInput
            title={trans('trial_pack_popup__hobbies__int.music_genres_you_listen_to')}
            inputID={"musicListen"}
            inputName={"musicListen"}
            placeholder={trans('trial_pack_popup__hobbies__int.select')}
            value={formik.values?.musicListen}
            defalutValue={[formik.values?.musicListen]}
            onChange={(e) => {
                formik.setFieldValue("musicListen", Array.from(e));    
            setShowBottom(false)                      
            }}
            error={formik.errors.musicListen}
            options={hobbiesInterests?.musicListen}
            disabled={editfromadmin == 'Y'}
            isFrom={isFrom == "hobbiesdetails" && "hobbiesdetails"}
            icon={isFrom == "hobbiesdetails" && musicicon}
            />

            <SelectHobbyInput
            title={trans('trial_pack_popup__hobbies__int.favorite_music_directors')}
            inputID={"musicDirectors"}
            inputName={"musicDirectors"}
            placeholder={trans('trial_pack_popup__hobbies__int.select')}
            value={formik.values?.musicDirectors}
            defalutValue={[formik.values?.musicDirectors]}
            onChange={(e) => {
                formik.setFieldValue("musicDirectors", Array.from(e));    
            setShowBottom(false)                      
            }}
            error={formik.errors.musicDirectors}
            options={hobbiesInterests?.favoriteMusicDirectors}
            disabled={editfromadmin == 'Y'}
            isFrom={isFrom == "hobbiesdetails" && "hobbiesdetails"}
            icon={isFrom == "hobbiesdetails" && music}
            />

            <SelectHobbyInput
            title={trans('trial_pack_popup__hobbies__int.type_of_books_you_read')}
            inputID={"booksRead"}
            inputName={"booksRead"}
            placeholder={trans('trial_pack_popup__hobbies__int.select')}
            value={formik.values?.booksRead}
            defalutValue={[formik.values?.booksRead]}
            onChange={(e) => {
                formik.setFieldValue("booksRead", Array.from(e));       
            setShowBottom(false)                   
            }}
            error={formik.errors.booksRead}
            options={hobbiesInterests?.TypeofBooksYouRead}
            disabled={editfromadmin == 'Y'}
            isFrom={isFrom == "hobbiesdetails" && "hobbiesdetails"}
            icon={isFrom == "hobbiesdetails" && readbook}
            />

            <SelectHobbyInput
            title={trans('trial_pack_popup__hobbies__int.type_of_movies_you_watch')}
            inputID={"moviesWatch"}
            inputName={"moviesWatch"}
            placeholder={trans('trial_pack_popup__hobbies__int.select')}
            value={formik.values?.moviesWatch}
            defalutValue={[formik.values?.moviesWatch]}
            onChange={(e) => {
                formik.setFieldValue("moviesWatch", Array.from(e));   
            setShowBottom(false)                       
            }}
            error={formik.errors.moviesWatch}
            options={hobbiesInterests?.TypeOfMoviesYouWatch}
            disabled={editfromadmin == 'Y'}
            isFrom={isFrom == "hobbiesdetails" && "hobbiesdetails"}
            icon={isFrom == "hobbiesdetails" && watchmovie}
            />

            <SelectHobbyInput
            title={trans('trial_pack_popup__hobbies__int.favorite_movie_directors')}
            inputID={"movieDirectors"}
            inputName={"movieDirectors"}
            placeholder={trans('trial_pack_popup__hobbies__int.select')}
            value={formik.values?.movieDirectors}
            defalutValue={[formik.values?.movieDirectors]}
            onChange={(e) => {
                formik.setFieldValue("movieDirectors", Array.from(e));   
            setShowBottom(false)                       
            }}
            error={formik.errors.movieDirectors}
            options={hobbiesInterests?.FavoriteMovieDirectors}
            disabled={editfromadmin == 'Y'}
            isFrom={isFrom == "hobbiesdetails" && "hobbiesdetails"}
            icon={isFrom == "hobbiesdetails" && directors}
            />

            <SelectHobbyInput
            title={trans('trial_pack_popup__hobbies__int.sports_fitness_activities_game')}
            inputID={"sportsFitnessActivitiesGames"}
            inputName={"sportsFitnessActivitiesGames"}
            placeholder={trans('trial_pack_popup__hobbies__int.select')}
            value={formik.values?.sportsFitnessActivitiesGames}
            defalutValue={[formik.values?.sportsFitnessActivitiesGames]}
            onChange={(e) => {
                formik.setFieldValue("sportsFitnessActivitiesGames", Array.from(e));    
            setShowBottom(false)                      
            }}
            error={formik.errors.sportsFitnessActivitiesGames}
            options={hobbiesInterests?.SportsAndFitnessActivitiesAndGames}
            disabled={editfromadmin == 'Y'}
            isFrom={isFrom == "hobbiesdetails" && "hobbiesdetails"}
            icon={isFrom == "hobbiesdetails" && games}
            />

            <SelectHobbyInput
            title={trans('trial_pack_popup__hobbies__int.favourite_cuisine')}
            inputID={"favouriteCuisine"}
            inputName={"favouriteCuisine"}
            placeholder={trans('trial_pack_popup__hobbies__int.select')}
            value={formik.values?.favouriteCuisine}
            defalutValue={[formik.values?.favouriteCuisine]}
            onChange={(e) => {
                formik.setFieldValue("favouriteCuisine", Array.from(e));   
            setShowBottom(false)                       
            }}
            error={formik.errors.favouriteCuisine}
            options={hobbiesInterests?.FavouriteCuisine}
            disabled={editfromadmin == 'Y'}
            isFrom={isFrom == "hobbiesdetails" && "hobbiesdetails"}
            icon={isFrom == "hobbiesdetails" && favourite}
            />
            <div className=" flex justify-center space-x-3  items-center mt-[2rem] mb-[2rem] ">
                <button disabled={editfromadmin == 'Y'}
                    className={`flex items-center justify-center text-white bg-[#D10A11] border border-[#D10A11] lg:w-[8rem] w-[9rem] whitespace-nowrap rounded-[5px] font-bold py-1 md:text-[13px] lg:text-[15px] ${editfromadmin == 'Y' ? 'cursor-not-allowed' : ''} truncate`}
                >
                    
                {submitStatus ? <><img src={LoaderGif} className="brightness-0 invert-[1] w-5 h-5" alt=""/><span>{trans('my_profile.saving')}</span></> : <span>{trans('my_profile.save')}</span>}
                </button>
                <button  type='reset'
                    onClick={(e)=>{
                        e.preventDefault();
                        formik.resetForm();
                    }}  disabled={editfromadmin == 'Y'}
                    className={` items-center justify-center text-[#575556] bg-white border border-[#707070] whitespace-nowrap rounded-[5px] font-bold lg:w-[8rem] w-[9rem] py-1 md:text-[12px] lg:text-[14px] ${editfromadmin == 'Y' ? 'cursor-not-allowed' : ''} truncate px-2`}
                >
                    <span className="truncate">
                        {trans('my_profile.discard_changes')}
                    </span>
                </button>
            </div> 
        </form>
        : 
        <div className="fixed inset-0 z-50 flex items-end justify-center overflow-x-hidden overflow-y-auto outline-none md:hidden bg-opacityblack75 md:items-center focus:outline-none">
            <div
            className="relative w-[100%] md:w-[55%] lg:w-[36%] modal-container md:my-6 md:mx-6"
             >
                <div className="flex items-center justify-between rounded-tr-[35px] rounded-tl-[35px] bg-white px-5 py-3">
                    <p className={`custom-font-family font-semibold text-[18px] lg:text-[14px] xl:text-[16px] ${isFrom == 'trailpack' ? 'text-[#000000] pt-2' : 'text-[#D10A11]'}`}>{trans('trial_pack_popup__hobbies__int.add_hobbies__interests')}</p>
                    <img onClick={() => {
                            onClose();
                            if(isFrom == 'trailpack'){
                                setCompleteNow(true);
                            }
                        }} className='w-5 h-5 cursor-pointer' src={closeBtn} alt=''/>
                </div>
                {isLoading ?
                <div className='h-[80vh] my-auto bg-white'>
                <Loader />
                </div>
                :
                <div className='h-[80vh] overflow-y-auto lite-scrollbar bg-white px-4'>
                    <form onSubmit={formik.handleSubmit}>
                        <SelectHobbyInput
                            title={trans('trial_pack_popup__hobbies__int.hobbies__interests')}
                            inputID={"hobbiesInterest"}
                            inputName={"hobbiesInterest"}
                            placeholder={isFrom == 'trailpack' ? trans('trial_pack_popup__hobbies__int.select_one_or_more') : trans('trial_pack_popup__hobbies__int.select') }
                            value={formik.values?.hobbiesInterest}
                            defalutValue={[formik.values?.hobbiesInterest]}
                            onChange={(e) => {
                                formik.setFieldValue("hobbiesInterest", Array.from(e));  
                                setShowBottom(false) 

                            }}
                            error={formik.errors.hobbiesInterest}
                            options={hobbiesInterests?.hobbiesAndInterest}
                            isFrom ={isFrom}
                        />

                        <SelectHobbyInput
                        title={trans('trial_pack_popup__hobbies__int.music_genres_you_listen_to')}
                        inputID={"musicListen"}
                        inputName={"musicListen"}
                        placeholder={isFrom == 'trailpack' ? trans('trial_pack_popup__hobbies__int.select_one_or_more') : trans('trial_pack_popup__hobbies__int.select') }
                        value={formik.values?.musicListen}
                        defalutValue={[formik.values?.musicListen]}
                        onChange={(e) => {
                            formik.setFieldValue("musicListen", Array.from(e));  
                            setShowBottom(false)                         
                        }}
                        error={formik.errors.musicListen}
                        options={hobbiesInterests?.musicListen}
                        isFrom ={isFrom}
                        />

                        <SelectHobbyInput
                        title={trans('trial_pack_popup__hobbies__int.favorite_music_directors')}
                        inputID={"musicDirectors"}
                        inputName={"musicDirectors"}
                        placeholder={isFrom == 'trailpack' ? trans('trial_pack_popup__hobbies__int.select_one_or_more') : trans('trial_pack_popup__hobbies__int.select') }
                        value={formik.values?.musicDirectors}
                        defalutValue={[formik.values?.musicDirectors]}
                        onChange={(e) => {
                            formik.setFieldValue("musicDirectors", Array.from(e)); 
                            setShowBottom(false)                          
                        }}
                        error={formik.errors.musicDirectors}
                        options={hobbiesInterests?.favoriteMusicDirectors}
                        isFrom ={isFrom}
                        />

                        <SelectHobbyInput
                        title={trans('trial_pack_popup__hobbies__int.type_of_books_you_read')}
                        inputID={"booksRead"}
                        inputName={"booksRead"}
                        placeholder={isFrom == 'trailpack' ? trans('trial_pack_popup__hobbies__int.select_one_or_more') : trans('trial_pack_popup__hobbies__int.select') }
                        value={formik.values?.booksRead}
                        defalutValue={[formik.values?.booksRead]}
                        onChange={(e) => {
                            formik.setFieldValue("booksRead", Array.from(e));   
                            setShowBottom(false)                        
                        }}
                        error={formik.errors.booksRead}
                        options={hobbiesInterests?.TypeofBooksYouRead}
                        isFrom ={isFrom}
                        />

                        <SelectHobbyInput
                        title={trans('trial_pack_popup__hobbies__int.type_of_movies_you_watch')}
                        inputID={"moviesWatch"}
                        inputName={"moviesWatch"}
                        placeholder={isFrom == 'trailpack' ? trans('trial_pack_popup__hobbies__int.select_one_or_more') : trans('trial_pack_popup__hobbies__int.select') }
                        value={formik.values?.moviesWatch}
                        defalutValue={[formik.values?.moviesWatch]}
                        onChange={(e) => {
                            formik.setFieldValue("moviesWatch", Array.from(e));   
                            setShowBottom(false)                        
                        }}
                        error={formik.errors.moviesWatch}
                        options={hobbiesInterests?.TypeOfMoviesYouWatch}
                        isFrom ={isFrom}
                        />

                        <SelectHobbyInput
                        title={trans('trial_pack_popup__hobbies__int.favorite_movie_directors')}
                        inputID={"movieDirectors"}
                        inputName={"movieDirectors"}
                        placeholder={isFrom == 'trailpack' ? trans('trial_pack_popup__hobbies__int.select_one_or_more') : trans('trial_pack_popup__hobbies__int.select') }
                        value={formik.values?.movieDirectors}
                        defalutValue={[formik.values?.movieDirectors]}
                        onChange={(e) => {
                            formik.setFieldValue("movieDirectors", Array.from(e)); 
                            setShowBottom(false)                          
                        }}
                        error={formik.errors.movieDirectors}
                        options={hobbiesInterests?.FavoriteMovieDirectors}
                        isFrom ={isFrom}
                        />

                        <SelectHobbyInput
                        title={trans('trial_pack_popup__hobbies__int.sports_fitness_activities_game')}
                        inputID={"sportsFitnessActivitiesGames"}
                        inputName={"sportsFitnessActivitiesGames"}
                        placeholder={isFrom == 'trailpack' ? trans('trial_pack_popup__hobbies__int.select_one_or_more') : trans('trial_pack_popup__hobbies__int.select') }
                        value={formik.values?.sportsFitnessActivitiesGames}
                        defalutValue={[formik.values?.sportsFitnessActivitiesGames]}
                        onChange={(e) => {
                            formik.setFieldValue("sportsFitnessActivitiesGames", Array.from(e));    
                            setShowBottom(false)                       
                        }}
                        error={formik.errors.sportsFitnessActivitiesGames}
                        options={hobbiesInterests?.SportsAndFitnessActivitiesAndGames}
                        isFrom ={isFrom}
                        />

                        <SelectHobbyInput
                        title={trans('trial_pack_popup__hobbies__int.favourite_cuisine')}
                        inputID={"favouriteCuisine"}
                        inputName={"favouriteCuisine"}
                        placeholder={isFrom == 'trailpack' ? trans('trial_pack_popup__hobbies__int.select_one_or_more') : trans('trial_pack_popup__hobbies__int.select') }
                        value={formik.values?.favouriteCuisine}
                        defalutValue={[formik.values?.favouriteCuisine]}
                        onChange={(e) => {
                            formik.setFieldValue("favouriteCuisine", Array.from(e));    
                            setShowBottom(false)                       
                        }}
                        error={formik.errors.favouriteCuisine}
                        options={hobbiesInterests?.FavouriteCuisine}
                        isFrom ={isFrom}
                        />

                        {hobbiesInterests?.languagesYouKnow &&
                        <SelectHobbyInput
                        title={trans('my_profile.languages_you_know')}
                        inputID={"languagesKnow"}
                        inputName={"languagesKnow"}
                        placeholder={isFrom == 'trailpack' ? trans('trial_pack_popup__hobbies__int.select_one_or_more') : trans('trial_pack_popup__hobbies__int.select') }
                        value={formik.values?.languagesKnow}
                        defalutValue={[formik.values?.languagesKnow]}
                        onChange={(e) => {
                            formik.setFieldValue("languagesKnow", Array.from(e));      
                            setShowBottom(false)                     
                        }}
                        error={formik.errors.languagesKnow}
                        options={hobbiesInterests?.languagesYouKnow}
                        isFrom ={isFrom}
                        />
                        }

                        {hobbiesInterests?.languagesThatIwishToKnow &&
                        <SelectHobbyInput
                        title={trans('my_profile.languages_that_you_wish_to_kno')}
                        inputID={"languagesWishToKnow"}
                        inputName={"languagesWishToKnow"}
                        placeholder={isFrom == 'trailpack' ? trans('trial_pack_popup__hobbies__int.select_one_or_more') : trans('trial_pack_popup__hobbies__int.select') }
                        value={formik.values?.languagesWishToKnow}
                        defalutValue={[formik.values?.languagesWishToKnow]}
                        onChange={(e) => {
                            formik.setFieldValue("languagesWishToKnow", Array.from(e));  
                            setShowBottom(false)                         
                        }}
                        error={formik.errors.languagesWishToKnow}
                        options={hobbiesInterests?.languagesThatIwishToKnow}
                        isFrom ={isFrom}
                        />
                        }

                        {commonError && (
                            <div className="px-1 mt-2 text-xs text-red-500">{commonError}</div>
                        )}

                        <div className={`my-5 w-full flex justify-center items-center ${isFrom == 'trailpack' ? 'gap-3' : ''}`}>
                            {isFrom == 'trailpack' ?
                            <>
                                <button className='flex items-center justify-center  border border-[#707070] lg:w-[9rem] w-[8rem] whitespace-nowrap rounded-[5px] font-bold py-1.5 md:text-[13px] lg:text-[15px]' 
                                onClick={() => {
                                    onClose(); 
                                    setCompleteNow(true);
                                }}>
                                    {trans('my_profile.cancel')}
                                </button> 
                                <button className={`flex items-center justify-center text-white lg:w-[9rem] w-[8rem] whitespace-nowrap rounded-[5px] font-bold py-1.5 md:text-[13px] lg:text-[15px] ${countFilledFields() == 0 ? 'bg-[#8E8E8E]' : 'bg-[#D10A11] border border-[#D10A11]'}`}
                                type="submit"
                                onClick={() => {
                                    if(isFrom == 'trailpack'){
                                        onClickTrack(EventName.Trial_Pack_popup_Add_HobbiesInterests_Submit_Button)
                                    }
                                }}>
                                    {submitStatus ? trans('my_profile.save') : countFilledFields() == 0 ? trans('my_profile.save') : `${trans('trial_pack_popup__hobbies__int.save')} (${countFilledFields()})` }
                                </button>
                            </> : 
                            <button className={`flex items-center justify-center text-white lg:w-[9rem] w-[8rem] whitespace-nowrap rounded-[5px] font-bold py-1.5 md:text-[13px] lg:text-[15px] ${countFilledFields() == 0 ? 'bg-[#8E8E8E]' : 'bg-[#D10A11] border border-[#D10A11]'}`}
                            >
                                {formik.isSubmitting ? <div className='flex items-center justify-center p-1'><img src={LoaderGif} className="brightness-0 invert-[1] w-5 h-5" alt=""/><span>{trans('my_profile.saving')}</span></div> 
                                : isFrom == 'trailpack' ? `${trans('trial_pack_popup__hobbies__int.save')} (${countFilledFields()})` : trans('my_profile.save')}
                            </button>
                            }
                    </div>

                    </form>
                </div>
                }
            </div>
        </div>}
    </>
  )
}

export default HobbyEdit