import { Badge } from "antd";
import React, { useState ,useEffect, useContext} from "react";
import { NavBarContext } from "../../Contexts/NavBarContext";

const AccordianComponents = (props) => {
  const [isShowBody, setShow] = useState(props.defaultView??false);

  useEffect(()=>{
setShow(props.defaultView??false)
  },[props.defaultView])

  const AccordinOnlick =()=>{
    setShow(!isShowBody);
    if(props.onClick){
    props.onClick() }
  }
  return (
    <>
      <div
//         className={`${props.titleClassName} ${isShowBody ?"text-[#D10A11] font-semibold":"text-[#575556] font-normal"} `}
//         onClick={AccordinOnlick}

        className={`${props.titleClassName} ${props?.defaultView ?"text-[#D10A11] font-semibold":"text-[#575556] font-normal"} `}
        onClick={() => {
          // setShow(!isShowBody);
          props?.onClick()
        }}
      >
        {props.title}
        <span className="flex items-center justify-center w-6 h-6">
          {props?.defaultView  ? props.titleIcon1 : props.titleIcon2}
        </span>
      </div>
      <div className={`${props.bodyClassName} ${props?.defaultView  ? "" : "hidden"} transition ease-in-out delay-150`}>
        {props.children}
      </div>
    </>
  );
};
const ProfileAccordianComponent = (props) => {
  const [isShowBody, setShow] = useState(props.defaultView??false);
  const {showbottomtab,setShowBottomTab} =useContext(NavBarContext)
  const isV3User = localStorage.getItem("isV3User")
  useEffect(() => {
    setShow(props.defaultView);
  }, [props.defaultView]);
  return ( 
    <div className={`${isV3User ? "border-l-2 border-r-2 border-b-2" : ""}`}>
      {isV3User ?
       <div
       className={`${props.titleClassName} ${isShowBody ?`${props.title} `:`${props.title} `}`}
       onClick={() => {
         setShow(!isShowBody);
         if(!isShowBody){
         props.Callback()
       }
       }}
     >
       {props.title}
       {!showbottomtab && (
  <p className="flex items-center text-[9px] md:text-[14px] lg:text-[16px]  ">
    {props?.showPEcout >= 0 && (
      <>
        <Badge 
          style={{ color: "#000000" }}  
          overflowCount={99999} 
          color="#FDC300" 
          count={props?.showPEcout}  
          showZero 
          className="mr-2  filtercount pebadge !text-[#000000]" 
        /> 
        Matches Matching Your Expectations
      </>
    )}
  </p>
)}

       <span className="flex items-center justify-center w-5 h-5">
         {isShowBody ? props.titleIcon1 : props.titleIcon2}
       </span>
     </div>
      :
      <div
        className={`${props.titleClassName} ${isShowBody ?`${props.title} rounded-t-[9px] md:rounded-t-[17px]`:`${props.title} rounded-[9px] md:rounded-[17px]`}`}
        onClick={() => {
          setShow(!isShowBody);
          if(!isShowBody){
          props.Callback()
        }
        }}
      >
        {props.title}
        <span className="flex items-center justify-center w-6 h-6">
          {isShowBody ? props.titleIcon1 : props.titleIcon2}
        </span>
      </div>
}
      <div className={`${props.bodyClassName} ${isShowBody ? "" : "hidden"} transition ease-in-out delay-150`}>
        {props.children}
      </div>
    </div>
  );
} 
export {ProfileAccordianComponent}

const RegisterAccordianComponent = (props) => {
  const [isShowBody, setShow] = useState(props.defaultView??false);
  return (
    <>
      <div
        className={`${props.titleClassName} ${isShowBody ?"rounded-t-[9px] md:rounded-t-[17px]":" rounded-[9px] md:rounded-[17px]"}`}
        onClick={() => {
          setShow(!isShowBody);
        }}
      >
        {props.title}
        <span className="flex items-center justify-center w-6 h-6">
          {isShowBody ? props.titleIcon1 : props.titleIcon2}
        </span>
      </div>
      <div className={`${props.bodyClassName} ${isShowBody ? "" : "hidden"} transition ease-in-out delay-150`}>
      {props.children}
      </div>
    </>
  );
} 
export {RegisterAccordianComponent}


const HelpProfileAccordianComponent = (props) => {
  const [isShowBody, setShow] = useState(props.defaultView??false);
  

  return (
    <>
      <div
        className={`${props.titleClassName} ${isShowBody ?"border-t border-[#707070] border-l border-[#707070] border-r border-[#707070]    rounded-t-[9px] md:rounded-t-[6px] ":" rounded-[9px] md:rounded-[6px]"}`}
        
        onClick={() => {
          setShow(!isShowBody);
          if(!isShowBody){
          props.Callback()
        }
        }}
      >
        {props.title}
        {/* <span className="flex items-center justify-center w-6 h-6">
          {isShowBody ? props.titleIcon1 : props.titleIcon2}
        </span> */}
      </div>
      <div className={`${props.bodyClassName} ${isShowBody ? "border-b border-[#707070] border-l border-[#707070] border-r border-[#707070] rounded-b-[9px] md:rounded-b-[6px]" : "hidden"} transition ease-in-out delay-150`}>
        {props.children}
      </div>
    </>
  );
} 
export {HelpProfileAccordianComponent}

const MessageAccordianComponent = (props) => {
  const [isShowBody, setShow] = useState(props.defaultView??false);
  useEffect(()=>{
setShow(props.defaultView??false)
  },[props.defaultView])
  return (
    <>
      {/* <div
        className={`${props.titleClassName} ${isShowBody ? "font-semibold":"text-[#575556] font-normal"} mb-1`}
        onClick={() => {
          setShow(!isShowBody);
        }}
      >
        <input
          aria-labelledby="label1"
          id={props.id}
          checked={props.selected}
          type="radio"
          value={props.name}
          onChange={(e) => props.onChange(e)}
          name={props.name}
          className=" accent-[#D10A11] border rounded-full border-gray-400 absolute cursor-pointer"
        />
        <span className="pl-4 font-normal">{props.title.substring(0, 25).concat('...')}</span>
        <span className="flex items-center justify-center w-6 h-6">
          {isShowBody ? props.titleIcon1 : props.titleIcon2}
        </span>
      </div>
      <div className={`${props.bodyClassName} ${isShowBody ? "" : "hidden"} transition ease-in-out delay-150 text-[12px]`}>
        {props.children}
      </div> */}
    <div
      className={`${props.titleClassName} ${
        isShowBody ? 'font-semibold' : 'text-[#575556] font-normal'
      } mb-1`}
      onClick={() => {
        setShow(!isShowBody);
      }}
    >
      <input
        aria-labelledby="label1"
        id={props.id}
        // checked={props.selected === "Y" ? true : false}
        checked={props.selected}
        type="radio"
        value={props.name}
        onChange={(e) => {
          props.onChange(e)
          setShow(false);
        }}
        name={props.name}
        className="accent-[#D10A11] border rounded-full border-gray-400 absolute cursor-pointer"
      />
      {isShowBody ? (
        <span className="pl-4 font-normal">{""}</span>
      ) : (
        <span className="pl-4 font-normal text-[15px]">{props.title.substring(0, 25).concat('...')}</span>
      )}
      <span className="flex items-center justify-center w-6 h-6">
        {isShowBody ? "" : props.titleIcon2}
      </span>
      <div className={`${props.bodyClassName} ${isShowBody ? '' : 'hidden'} transition ease-in-out delay-150 text-[12px]`}>
        {props.children}
      </div>
    </div>
    </>
  );
};
export {MessageAccordianComponent}

export default AccordianComponents;
