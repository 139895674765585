import React, { Fragment, useCallback, useContext, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import img1 from '../../../Images/left-arrow.svg';
import img2 from '../../../Images/right-arrow.svg';
import closeIcon from '../../../Images/DrawerBackButton.svg'
import { CurrentContactContext } from "../context/CurrentContactContext";
import {  EditProfileMenu, EventKeys, EventName } from "../../../Constants/constants";
import { Dialog, Transition } from "@headlessui/react";
import { IoMdClose } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import * as KalyanLiteSelector from "../../../Store/KalyanLite/selectors";
import { Link } from "react-router-dom";
import { RouteHelper } from "../../../Routes/RouteSegment";
import config from "../../../config";
import EndPoints from "../../../Constants/EndPoints";
import { POST } from "../../../Services/api_services";
import { LoginContext } from "../../../Contexts/LoginContext";
import { ViewPhotoPasswordPopup } from "../../CommonPopup";
import { useAnalytics } from "../../../Hooks/usePageChange";
import ProcessImage from "./ProcessImage";

function CustomNextArrow(props) {
  const { className, style, onClick, currentSlide, slideCount,from} = props;
  const {onClickTrack} = useAnalytics();
  const hasNextCard = currentSlide < slideCount - props?.count;
  const handleNextClick = () => {
    if (hasNextCard && typeof onClick === 'function') {
      onClick();
      if(from=='image-viewer'){
       onClickTrack(EventName.View_Profile_Photo_Right_Arrow_Button)
      }
    }
  };
  return hasNextCard ? (
    <>
      <img alt="" src={img2} className="custom-right-arrow cursor-pointer" onClick={handleNextClick}/>
    </>
  ) :( 
      <>
        <img alt="" src={img2} className="custom-right-arrow opacity-75 cursor-not-allowed" />
      </>
);
}
function CustomNextArrows(props) {
  const { className, style, onClick, currentSlide, slideCount,from } = props;
  const {onClickTrack} = useAnalytics();
  const hasNextCard = currentSlide < slideCount - props?.count;
  const handleNextClick = () => {
    if (hasNextCard && typeof onClick === 'function') {
      onClick();
      if(from=='image-viewer'){
        onClickTrack(EventName.View_Profile_Photo_Right_Arrow_Button)
       }
    }
  };
  return hasNextCard ? (
    <>
      <img alt="" src={img2} className="custom-right-arrows cursor-pointer" onClick={handleNextClick}/>
    </>
  ) :( 
      <>
        <img alt="" src={img2} className="custom-right-arrows opacity-75 cursor-not-allowed" />
      </>
);
}
function CustomPrevArrow(props) {
  const { className, style, onClick, currentSlide,from } = props;
  const {onClickTrack} = useAnalytics();
  console.log("clssName=props", props);
  const hasPrevCard = currentSlide > 0;

  const handlePrevClick = () => {
    if (hasPrevCard && typeof onClick === 'function') {
      
      onClick();
      if(from=='image-viewer'){
        onClickTrack(EventName.View_Profile_Photo_Left_Arrow_Button)
      }

    }
  };

  
  return hasPrevCard ? (  
    <> 
      <img alt="" src={img1} className={`custom-left-arrow cursor-pointer my-auto`} onClick={handlePrevClick} />
      </>
  ) : (
      <img alt="" src={img1} className={`custom-left-arrow opacity-75 cursor-not-allowed`} />
  )
}

function CustomPrevArrows(props) {
  const { className, style, onClick, currentSlide,from } = props;
  const {onClickTrack} = useAnalytics();
  const hasPrevCard = currentSlide > 0;

  const handlePrevClick = () => {
    if (hasPrevCard && typeof onClick === 'function') {
      onClick();
      if(from=='image-viewer'){
        onClickTrack(EventName.View_Profile_Photo_Left_Arrow_Button)
      }
    }
  };

  
  return hasPrevCard ? (  
    <> 
      <img alt="" src={img1} className={`custom-left-arrows cursor-pointer my-auto`} onClick={handlePrevClick} />
      </>
  ) : (
      <img alt="" src={img1} className={`custom-left-arrows opacity-75 cursor-not-allowed`} />
  )
}

const AsNavFor = () => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const slider1 = useRef(null);
  const slider2 = useRef(null);
  const {sliderImages,setSliderShow} = useContext(CurrentContactContext)
  console.log(sliderImages,'sliderImages');


  useEffect(() => {
    setNav1(slider1.current);
    setNav2(slider2.current);
  }, []);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    asNavFor: nav2,
    draggable:true,
    rows: 1,
    prevArrow: <CustomPrevArrow count={1} />,
    nextArrow: <CustomNextArrow count={1} />,    
  };

  const settings1 = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6, // Set to 1 to show only one slide at a time    
    slidesToScroll: 1,
    swipeToSlide: true,
    focusOnSelect: true,
    // asNavFor: nav1,
    centerMode:true,
    draggable:true,
    rows: 1,
    prevArrow: <CustomPrevArrow count={1} />,
    nextArrow: <CustomNextArrow count={1} />,
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowRight') {
        slider1.current.slickNext();
      } else if (event.key === 'ArrowLeft') {
        slider1.current.slickPrev();
      }
    };

    // Attach the event listener when the component mounts
    document.addEventListener('keydown', handleKeyDown);

    // Detach the event listener when the component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);


  return (
    <div className="w-[90%] mx-auto relative">
      <img className="image-slider-close brightness-0 invert-[1] absolute right-0 w-[2.5rem] h-[2.5rem] top-[1rem] cursor-pointer"  onClick={()=>setSliderShow(false)} src={closeIcon} alt="closeIcon" />
      <div className="w-[80%] mx-auto mt-6 space-y-5 block image-Slider">
        <Slider {...settings} ref={slider1}>
          {sliderImages?.length >0 && 
          sliderImages?.map((src,i)=>{
            return(
          <div className="w-full max-h-[23rem] h-[23rem] flex items-center justify-center" key={i}>
            <img src={src} className="max-w-full max-h-full mx-auto" alt={`Slide ${i + 1}`}/>
          </div>)
          })
          }         
        </Slider>
      </div>
      <hr className="bg-black mb-0 mt-3" />
      <div className="relative">
        <div className="w-[80%] mx-auto fixed bottom-0 left-[12%]">
          <Slider {...settings1} ref={slider2}>
            {sliderImages?.length > 0 &&
              sliderImages.map((src, i) => (
                <div
                  className="h-[6rem] max-h-[6rem] w-[6rem] max-w-[6rem] custom-slide bg-[#E2E8F0] p-1 flex items-center justify-center mx-auto"
                  key={i}
                >
                  <img src={src} className="max-w-full max-h-full h-full w-full mx-auto" alt={`Slide ${i + 1}`} />
                </div>
              ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export const ImageViewer = ({ images, isOpen, closeModal, initialIndex = 0, from, profileId, showGroup = true, imageStatus }) => {
  const { loginDetail, logout } = useContext(LoginContext);
  const [showPasswordPopup, setShowPasswordpopup] = useState(false);
  const [pswErrorMsg,setPswErrorMsg] =useState("");
  const [photoview,setPhotoview] = useState();
  const [isPasswordProtected, setPasswordProtected] = useState(false);
  const [isPhotoBlur, setPhotoBlur] = useState(false);
  const [showImagePopup, setShowImagePopup] = useState(false);
  const isMobileOrTab = window.innerWidth <= 767;
  const [slider1, setSlider1] = useState(null)
  const slider2 = useRef(null)
  const {onClickTrack} = useAnalytics();
  const dispatch = useDispatch()
  const handleThumbnailClick = (index) => {
    // Use the slickGoTo method to navigate to the selected image in the main slider
    slider1?.slickGoTo(index);
  };

  const onSlider1Ref = useCallback(
    (ref) => {
      if (ref) {
        setSlider1(ref);
        if (isOpen && initialIndex > 0) {
          ref.slickGoTo(initialIndex);
        }
      }
    },
    [initialIndex, isOpen],
  )

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    // asNavFor: nav2,
    draggable: true,
    rows: 1,
    prevArrow: <CustomPrevArrow count={1} sliderRef={slider1} from={from}/>,
    nextArrow: <CustomNextArrow count={1} sliderRef={slider1} from={from}/>,
  };

  const settings2 = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: isMobileOrTab ? 3 : 4,
    slidesToScroll: 1,
      focusOnSelect: true,
    swipeToSlide: true,
    rows: 1,
    // asNavFor: slider1.current,
    arrows: true,
    prevArrow: <CustomPrevArrows count={1} sliderRef={slider2} from={from}/>,
    nextArrow: <CustomNextArrows count={4} sliderRef={slider2} from={from}/>,
  };
  const userData = useSelector(
    (state) => state?.DashBoardAPI?.ProfileData?.data,
  );
  const matchprofileData = useSelector(KalyanLiteSelector.getMatchProfileById);
  const [profileData, setProfileData] = useState(matchprofileData)
  const isProfilePicAvailable = !profileData?.profiledetails?.isAvatarPic;
  const loggedInUserPaid = userData?.isPaid === "Y"

  const apicallPhotoPassword = async (value) => {
    // const profileId = 1676459;

      let request = {
        email: loginDetail()[0],
        userId: loginDetail()[1],
        // toProfileId:profileId ,
        toProfileId: `${profileData?.profiledetails?.profileid}`,
        // password: data?.data?.isPasswordMatches ==="Y"?value:"invalid psw"
        password: value
      };

      let { statusCode, data } = await POST(
        `${config.api.API_URL}${EndPoints.checkPhotoPassword()}`,
        request
      );

      // api/checkpasswordmatch

      if (statusCode === 200)  { 
        setPhotoview(data?.data?.isPasswordMatches)

        setProfileData(prev => ({
          ...prev,
          fromPasswordProtected: "N",
          blurPhoto: "N"
        }))

        if (data?.data?.isPasswordMatches === "Y") {
          setShowPasswordpopup(false);
          setPasswordProtected(false);
          setPhotoBlur(false);
          setShowImagePopup(true)
        } else if (data?.data?.isPasswordMatches === "N") {
        
         
          setPswErrorMsg(data?.data?.statusmessage)
          

          //console.log(pswErrorMsg,"pswErrorMsg");

        }
      } else if (statusCode === 401) {
        logout();
      }
     

  };

  return (
    <>
  {showPasswordPopup && (
    <ViewPhotoPasswordPopup
      callBackApply={(val) => {
        // setPhotoPassword(val);
        apicallPhotoPassword(val);
        
        

      }}
      errorMsg={pswErrorMsg}
      close={() => setShowPasswordpopup(false)}
    />
  )}
      <Transition appear show={isOpen && !showPasswordPopup} >
    <Dialog as="div" className="relative z-10" onClose={closeModal}>
      <Transition.Child
        // as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-black opacity-[0.75]" />
      </Transition.Child>

      <div className="fixed inset-0 overflow-y-auto overflow-x-hidden">
        <div className="flex min-h-full items-center justify-center p-4 text-center">
          <Transition.Child
            // as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="w-[70rem] lg:w-[55rem] xl:w-[65rem] relative">
              <IoMdClose onClick={closeModal} color="#A6A6A6" size={52} className="absolute top-[-74px] md:-top-[41px]  lg:top-[-37px] right-[375px] md:right-[210px] lg:right-[95px] xl:right-[120px] cursor-pointer" />
              <div className="w-[23%] md:w-[53%] lg:w-[62%] mx-auto mt-6 space-y-5 block image-Slider h-[24rem] md:h-4/5">
                {/* slider1 */}
                <Slider {...settings}
                  className="preview-slider-full"
                      ref={onSlider1Ref}>
                  {images?.length > 0 &&
                    images?.map((src, i) => {
                      let imgStatus = from == "profilePreviewPage" ? ( imageStatus?.[i] ?? 'default') : null; 
                      return <div> 
                        <>
                          <ProcessImage
                          onContextMenu={(e) => e.preventDefault()} // Disable right-click
                          onMouseDown={(e) => {
                            if (e.button === 2) e.preventDefault(); // Disable right-click on mouse
                          }}
                            src={src}
                            imgStatus = {imgStatus}
                            isFrom="profilesectionimage"
                            alt=""
                            imageClassName="before:content-['kalyanmatrimony.com'] 
before:absolute before:top-[75%] before:left-2 before:-translate-y-1/2
before:rotate-[-90deg] before:text-white before:text-[1.5vw] 
md:before:text-[22px] sm:before:text-[0.8vw] before:font-bold 
before:opacity-90 before:px-2 before:py-1 before:rounded 
before:pointer-events-none before:tracking-wide before:origin-left
lg:before:tracking-[4px] before:w-max before:z-10"

                            blurPhoto={profileData?.myphoto == "" || profileData?.myphoto == "N"
                              ? (i == 0 && profileData?.blurPhoto == "N")
                                ? "N" : "Y"
                              : profileData?.blurPhoto}
                            onClick={() => {
                              if (profileData?.fromPasswordProtected == "Y") {
                                setShowPasswordpopup(true)
                              }
                            }}
                            alreadyContacted={profileData?.alreadyContected}
                            isAvatarPic={profileData?.profiledetails?.isAvatarPic}
                            passwordProtected={profileData?.fromPasswordProtected}
                            containerClassName={`lg:h-[60vh] md:h-[60vh] h-[40vh] flex items-center justify-center md:mx-9 image preview-slider-full-item  ${!isProfilePicAvailable ? "flex pt-16 justify-center bg-[#7A7A7A] lg:rounded-br-[40px]" : ""}`}
                            className={`${isProfilePicAvailable ? " lg:w-[100%] h-[100%] md:w-[100%] w-[15rem]  object-contain " : "w-[9rem] h-[9rem]"}`}
                            lockClassName="h-[5rem] w-[5rem]"
                          >
                            {
                              profileData?.fromPasswordProtected == "Y" || (profileData?.blurPhoto == "Y" && profileData?.alreadyContected == "Y")
                           ? null
                           : profileData?.blurPhoto == "Y" ?
                              <p className="py-5 lg:top-[40%] absolute  text-[14px] lg:w-[30rem] xl:w-[35.5rem] md:w-[33rem] bg-[#E6E6E6] w-[15rem] h-[4rem]">
                                <center>
                                  {profileData?.statusMessagePhoto}
                                </center>
                              </p>
                              :
                              profileData?.myphoto == "" || profileData?.myphoto == "N" ?
                                i == 0 ?
                                  ""
                                  :
                                  <p className="py-5 lg:top-[40%] absolute   text-[14px] text-center bg-[#E6E6E6]  h-[4rem]">
                                    <Link to={RouteHelper.getEditProfileUrl(EditProfileMenu[2].urlPath)} className="  lg:text-[18px] md:text-[16px] relative text-xs text-center]">
                                      Upload your photo to view more photos
                                    </Link>
                                  </p>
                                : ""

                          }
                          </ProcessImage>


                        </>
                      </div>
                      // return ( 
                      //   <div>
                      //     <>
                      //           <div key={i} className={`lg:h-[60vh] md:h-[60vh] h-[40vh] flex items-center justify-center md:mx-9 image preview-slider-full-item  ${!isProfilePicAvailable ? "flex pt-16 justify-center bg-[#7A7A7A] lg:rounded-br-[40px]" : ""}`}>
                      //             <img
                      //               // key={i}
                      //               onClick={() => {
                      //                 if (
                      //                   !isProfilePicAvailable
                      //                 ) {
                      //                   setShowImagePopup(true);
                      //                 }
                      //               }}
                      //               className={`${isProfilePicAvailable?
                      //                 profileData?.blurPhoto == "Y" ? 
                      //               profileData?.alreadyContected == "Y" 
                      //               ? "blur-[2px]" :profileData?.alreadyContected == "N"  ? "blur-[2px]" :"blur-[2px]" 
                      //               : profileData?.alreadyContected == "N" && (profileData?.myphoto == "" || profileData?.myphoto == "N") && i!=0 
                                    
                      //               ? "blur-[2px]"
                                  
                      //               : profileData?.alreadyContected == "N" 
                      //               && profileData?.myphoto == "Y" 
                                    
                      //               ?"b"
                      //               :
                      //               profileData?.myphoto == "Y" && 
                      //               profileData?.status == "open" ? // view all photo
                      //               "c"
                      //               :profileData?.myphoto == "" || profileData?.myphoto == "N"?"g"                                   
                      //               :"d"
                      //               :"e"
                                    
                      //                 } ${isProfilePicAvailable? " lg:w-[100%] h-[100%] md:w-[100%] w-[15rem]  object-contained":"w-[9rem] h-[9rem]"}`}
                      //               src={src}
                      //               // className="md:w-[35rem] mx-auto"
                      //               alt={`Slide ${i + 1}`}
                      //             />

                      //             {profileData?.fromPasswordProtected =="Y" && profileData?.blurPhoto == "Y" && profileData?.profiledetails?.isAvatarPic === false ?
                      //             "":
                      //             profileData?.blurPhoto == "Y"?
                      //               <p className="py-5 lg:top-[40%] absolute  text-[14px] lg:w-[30rem] xl:w-[35.5rem] md:w-[33rem] bg-[#E6E6E6] w-[15rem] h-[4rem]">
                      //                   <center> 
                      //                     {profileData?.statusMessagePhoto}
                      //                   </center>
                      //                 </p>
                      //             :
                      //             profileData?.myphoto == "" || profileData?.myphoto == "N"  ?
                      //             i==0?
                      //                 ""
                      //             :
                      //               <p className="py-5 lg:top-[40%] absolute  text-[14px] lg:w-[30rem] xl:w-[35.5rem] md:w-[33rem] text-center bg-[#E6E6E6] w-[15rem] h-[4rem]">
                      //                 <center> <Link to={RouteHelper.getEditProfileUrl(EditProfileMenu[2].urlPath)}  className="  lg:text-[18px] md:text-[16px] relative text-xs text-center]">
                      //                       Upload your photo to view more photos
                      //                       </Link></center>
                      //               </p>
                      //             :""

                      //             } 
                      //                </div>
                      //                {profileData?.fromPasswordProtected =="Y" && profileData?.blurPhoto == "Y" && profileData?.profiledetails?.isAvatarPic === false && (                
                      //     <div
                      //         onClick={() => setShowPasswordpopup(true)}
                      //         className="blur-[2px] absolute top-0 flex justify-center items-center h-[15rem] md:h-[16rem] lg:h-[17.45rem] md:max-w-[100%] lg:max-w-none lg:w-[25%] md:w-[25%] cursor-pointer"
                      //       >
                      //         {/* profilerequestcheck */}
                      //         {/* profilefromPasswordProtected */}
                      //         <img
                      //           className="h-[5rem] w-[5rem]"
                      //           src="/Assets/Images/lockicon.svg"
                      //           alt="Person"
                      //         />
                      //       </div>
                      //     )}
                      //              </>
                      //   </div>)
                    })
                  }
                </Slider>
              </div>
                  {showGroup && <div className="h-full mt-[0.25rem] md:mt-5 w-full flex justify-center">
                <div className="w-[19rem] md:w-[29rem]">
                   {/* slider2 */}
                  <Slider {...settings2}
                        // asNavFor={slider1}
                    ref={slider2}
                    // onClick={() => slider1.current.slickGoTo(i)}
                    className={`preview-slider ${images?.length <= 4 ? "preview-slider-center" : ""}`}
                    >
                        {images?.length > 0 && images.map((src, i) => {
                          return <ProcessImage
                            src={src}
                            alt=""
                            blurPhoto={profileData?.myphoto == "" || profileData?.myphoto == "N"
                              ? (i == 0 && profileData?.blurPhoto == "N")
                              ? "N" : "Y"
                            :profileData?.blurPhoto} 
                            onClick={() => { onClickTrack(EventName.View_Profile_Photo_Select_Photo_Button, { [EventKeys.View_Profile_Photo_Select_Photo_Src]: src }); handleThumbnailClick(i) }}
                            alreadyContacted={profileData?.alreadyContected}
                            isAvatarPic={profileData?.profiledetails?.isAvatarPic}
                            passwordProtected={profileData?.fromPasswordProtected}
                            containerClassName={`h-[6rem] max-h-[6rem] w-[6rem] max-w-[6rem] bg-[#E2E8F0] p-1 flex items-center justify-center`}
                            className={`max-w-full max-h-full h-full w-full cursor-pointer`}
                            lockClassName="h-[2rem] w-[2rem]"
                          />
                          //     return <div
                          //   className="h-[6rem] max-h-[6rem] w-[6rem] max-w-[6rem] bg-[#E2E8F0] p-1 flex items-center justify-center"
                          //   key={i}
                          //   onClick={() =>{onClickTrack(EventName.View_Profile_Photo_Select_Photo_Button,{[EventKeys. View_Profile_Photo_Select_Photo_Src]: src}); handleThumbnailClick(i)}}
                          // >
                          //   <img src={src} className={`${i!=0?profileData?.myphoto == "" || profileData?.myphoto == "N" || profileData?.fromPasswordProtected =="Y" || profileData?.blurPhoto == "Y"?"blur-[5px]":"":""} max-w-full max-h-full h-full w-full cursor-pointer`} alt={`Slide ${i + 1}`} />
                          // </div>
                        }
                    )}
                  </Slider>
                </div>
                {/* <Slider {...settings1}>
                  {images?.length > 0 &&
                    images.map((src, i) => (
                      <div
                        className="h-[6rem] max-h-[6rem] w-[6rem] max-w-[6rem] bg-[#E2E8F0] p-1 flex items-center justify-center mx-auto"
                        key={i}
                      >
                        <img src={src} className="max-w-full max-h-full h-full w-full mx-auto" alt={`Slide ${i + 1}`} />
                      </div>
                    ))}
                </Slider> */}
                  </div>}
            </div>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition>
  </>
  )
  

}

export const ImageViewerPasswordRevealed = ({ images, isOpen, closeModal, initialIndex = 0 ,from }) => {
  const { loginDetail, logout } = useContext(LoginContext);
  const [showPasswordPopup, setShowPasswordpopup] = useState(false);
  const [pswErrorMsg,setPswErrorMsg] =useState("");
  const [photoview,setPhotoview] = useState();
  const [isPasswordProtected, setPasswordProtected] = useState(false);
  const [isPhotoBlur, setPhotoBlur] = useState(false);
  const [showImagePopup, setShowImagePopup] = useState(false);
  const isMobileOrTab = window.innerWidth <= 767;
  const slider1 = useRef(null)
  const slider2 = useRef(null)
  const { onClickTrack } = useAnalytics()

  console.log(from ,"from chjcn");
  const handleThumbnailClick = (index) => {
    // Use the slickGoTo method to navigate to the selected image in the main slider
    slider1.current.slickGoTo(index);
  };

  const onSlider1Ref = useCallback(
    (ref) => {
      if (ref) {
        slider1.current = ref;
        if (isOpen && initialIndex > 0) {
          slider1.current.slickGoTo(initialIndex);
        }
      }
    },
    [initialIndex, isOpen],
  )

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    // asNavFor: nav2,
    draggable: true,
    rows: 1,
    prevArrow: <CustomPrevArrow count={1} sliderRef={slider1} from ={from}/>,
    nextArrow: <CustomNextArrow count={1} sliderRef={slider1} from ={from}/>,
  };

  const settings2 = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: isMobileOrTab ? 3 : 4,
    slidesToScroll: 1,
      focusOnSelect: true,
    swipeToSlide: true,
    rows: 1,
    asNavFor: slider1.current,
    arrows: true,
    prevArrow: <CustomPrevArrows count={1} sliderRef={slider2} from ={from}/>,
    nextArrow: <CustomNextArrows count={4} sliderRef={slider2} from ={from}/>,
  };
  const userData = useSelector(
    (state) => state?.DashBoardAPI?.ProfileData?.data,
  );
  const profileData = useSelector(KalyanLiteSelector.getMatchProfileById);
  const isProfilePicAvailable = !profileData?.profiledetails?.isAvatarPic;
  const loggedInUserPaid = userData?.isPaid === "Y"

  const apicallPhotoPassword = async (value) => {
    // const profileId = 1676459;
    {
      let request = {
        email: loginDetail()[0],
        userId: loginDetail()[1],
        // toProfileId:profileId ,
        toProfileId: `${profileData?.profiledetails?.profileid}`,
        // password: data?.data?.isPasswordMatches ==="Y"?value:"invalid psw"
        password: value
      };

      let { statusCode, data } = await POST(
        `${config.api.API_URL}${EndPoints.checkPhotoPassword()}`,
        request
      );

      // api/checkpasswordmatch

      if (statusCode === 200)  { 
        setPhotoview(data?.data?.isPasswordMatches)

        if (data?.data?.isPasswordMatches === "Y") {
          setShowPasswordpopup(false);
          setPasswordProtected(false);
          setPhotoBlur(false);
          setShowImagePopup(true)
        } else if (data?.data?.isPasswordMatches === "N") {
        
         
          setPswErrorMsg(data?.data?.statusmessage)
          

          //console.log(pswErrorMsg,"pswErrorMsg");

        }
      } else if (statusCode === 401) {
        logout();
      }
     
    }
  };

  return (
    <>
  {/* {showPasswordPopup && (
    <ViewPhotoPasswordPopup
      callBackApply={(val) => {
        // setPhotoPassword(val);
        apicallPhotoPassword(val);
        
        

      }}
      errorMsg={pswErrorMsg}
      close={() => setShowPasswordpopup(false)}
    />
  )} */}
  <Transition appear show={isOpen} >
    <Dialog as="div" className="relative z-10" onClose={closeModal}>
      <Transition.Child
        // as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-black opacity-[0.75]" />
      </Transition.Child>

      <div className="fixed inset-0 overflow-y-auto overflow-x-hidden">
        <div className="flex min-h-full items-center justify-center p-4 text-center">
          <Transition.Child
            // as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
          <div className="w-[70rem] lg:w-[55rem] xl:w-[65rem] relative">
              <IoMdClose onClick={closeModal} color="#A6A6A6" size={52} className="absolute top-[-74px] md:-top-[41px]  lg:top-[-37px] right-[375px] md:right-[210px] lg:right-[95px] xl:right-[120px] cursor-pointer" />
              <div className="w-[23%] md:w-[53%] lg:w-[62%] mx-auto mt-6 space-y-5 block image-Slider h-[24rem] md:h-4/5">
                {/* slider1 */}
                <Slider {...settings}
                  className="preview-slider-full"
                      ref={onSlider1Ref}>
                  {images?.length > 0 &&
                    images?.map((src, i) => {
                      return ( 
                        <div>
                          <>
                                <div key={i} className={`lg:h-[60vh] md:h-[60vh] h-[40vh] flex items-center justify-center md:mx-9 image preview-slider-full-item  ${!isProfilePicAvailable ? "flex pt-16 justify-center bg-[#7A7A7A] lg:rounded-br-[40px]" : ""}`}>
                                  <img
                                    // key={i}
                                    onClick={() => {
                                      if (
                                        !isProfilePicAvailable
                                      ) {
                                        setShowImagePopup(true);
                                      }
                                    }}
                                    className={`${i!=0
                                      ?"blur-[2px]"
                                      :""
                                    
                                      } ${isProfilePicAvailable? " lg:w-[100%] h-[100%] md:w-[100%] w-[15rem]  object-contained":"w-[9rem] h-[9rem]"}`}
                                    src={src}
                                    // className="md:w-[35rem] mx-auto"
                                    alt={`Slide ${i + 1}`}
                                  />

                                  {profileData?.fromPasswordProtected =="Y" && profileData?.blurPhoto == "Y" && profileData?.profiledetails?.isAvatarPic === false ?
                                  "":
                                  profileData?.blurPhoto == "Y"?
                                  i==0?
                                    <p className="py-5 lg:top-[40%] absolute  text-[14px] lg:w-[30rem] xl:w-[35.5rem] md:w-[33rem] bg-[#E6E6E6] w-[15rem] h-[4rem]">
                                        <center> 
                                          {profileData?.statusMessagePhoto}
                                        </center>
                                      </p>
                                      :""
                                  :
                                  profileData?.myphoto == "" || profileData?.myphoto == "N"  ?
                                  i==0?
                                      ""
                                  :
                                    <p className="py-5 lg:top-[40%] absolute  text-[14px] lg:w-[30rem] xl:w-[35.5rem] md:w-[33rem] text-center bg-[#E6E6E6] w-[15rem] h-[4rem]">
                                      <center> <Link to={RouteHelper.getEditProfileUrl(EditProfileMenu[2].urlPath)}  className="  lg:text-[18px] md:text-[16px] relative text-xs text-center]">
                                            Upload your photo to view more photos
                                            </Link></center>
                                    </p>
                                  :""

                                  } 
                                     </div>
                                     
                                   </>
                        </div>)
                    })
                  }
                </Slider>
              </div>
              <div className="h-full mt-[0.25rem] md:mt-5 w-full flex justify-center">
                <div className="w-[19rem] md:w-[29rem]">
                   {/* slider2 */}
                  <Slider {...settings2}
                   asNavFor={slider1.current}
                    ref={slider2}
                    // onClick={() => slider1.current.slickGoTo(i)}
                    className={`preview-slider ${images?.length <= 4 ? "preview-slider-center" : ""}`}
                    >
                    {images?.length > 0 && images.map((src, i) => (
                      <div
                        className="h-[6rem] max-h-[6rem] w-[6rem] max-w-[6rem] bg-[#E2E8F0] p-1 flex items-center justify-center"
                        key={i}
                        onClick={() => handleThumbnailClick(i)}
                      >
                        <img src={src} className={`${i!=0?profileData?.myphoto == "" || profileData?.myphoto == "N" || profileData?.fromPasswordProtected =="Y" || profileData?.blurPhoto == "Y"?"blur-[5px]":"":""} max-w-full max-h-full h-full w-full cursor-pointer`} alt={`Slide ${i + 1}`} />
                      </div>
                    ))}
                  </Slider>
                </div>
                {/* <Slider {...settings1}>
                  {images?.length > 0 &&
                    images.map((src, i) => (
                      <div
                        className="h-[6rem] max-h-[6rem] w-[6rem] max-w-[6rem] bg-[#E2E8F0] p-1 flex items-center justify-center mx-auto"
                        key={i}
                      >
                        <img src={src} className="max-w-full max-h-full h-full w-full mx-auto" alt={`Slide ${i + 1}`} />
                      </div>
                    ))}
                </Slider> */}
              </div>
            </div>

            
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition>
  </>
  )
  

}


export const SingleImageView = ({ imageSrc, close }) => {
  return <Transition appear show={true} >
    <Dialog as="div" className="relative z-10" onClose={close}>
      <Transition.Child
        // as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-black opacity-[0.75]" />
      </Transition.Child>

      <div className="fixed inset-0 overflow-y-auto overflow-x-hidden">
        <div className="flex min-h-full items-center justify-center p-4 text-center">
          <Transition.Child
            // as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="w-full h-full relative p-4">
              <IoMdClose onClick={close} color="#A6A6A6" size={52} className="absolute -top-[32px] -right-[14px] md:right-[32px] md:top-[32px] cursor-pointer" />
              <div className="w-[80vw] h-[80vh] flex items-center justify-center">
                <img src={imageSrc} alt="" className="max-h-full max-w-full" />
              </div>
            </div>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition>
}

export default AsNavFor;
