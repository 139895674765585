import { Dropdown, Menu } from 'antd'
import React from 'react'
import kalyanLogo from '../../Images/KM logo@2x.png'
import HamburgerIcon from '../../Images/hamburgerIcon.svg'

const PdfHeader = ({ className, setShowSharedProfiles, setSelectedData, isKPCustomer }) => {

    const menu = (
        <Menu className='!mr-[1.8rem] md:!mr-[3rem] lg:!mr-[3.5rem] w-max'>
          <Menu.Item key="1" onClick={()=>{
            setShowSharedProfiles(true)
            setSelectedData("allShared")
            }}>
              All Shared Profiles
          </Menu.Item>
          <div className="border-t border-[#707070] my-1"></div>
          <Menu.Item key="2" onClick={()=>{
            setShowSharedProfiles(true)
            setSelectedData("yetToResponse")
            }}>
              Yet to Respond Profiles
          </Menu.Item>
        </Menu>
    );

  return (
        <header className={`sticky top-0 !z-50 w-full flex items-center justify-between bg-[#FEFEFE] shadow-[0px_4px_6px_rgba(0,0,0,0.1)] ${className}`}>
            <img src={kalyanLogo} className="h-12 md:h-16 object-contain" alt='kalyan logo' />
            {isKPCustomer == "Y" &&
            <>
              <h1 className='font-bold text-[16px] md:text-[20px]'><span className='text-[#D10A11]'>KALYAN</span> PREMIER</h1>
              <Dropdown className='w-[5rem] md:w-[8rem] lg:w-[10rem]' overlay={menu} placement="bottomLeft" trigger={["click"]}>
                  <img src={HamburgerIcon} className="h-6 w-6 cursor-pointer flex items-center justify-end" />
              </Dropdown>    
            </>
            }
        </header>                
  )
}

export default PdfHeader
