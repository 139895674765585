//LOGIN
export const LOGIN = "/login"; 
export const LOGIN_VIA = "/loginViaAPI";
export const ADMIN_LOGIN = "/loginfromadmin"; 
export const PARTIAL_ADMIN_LOGIN = "/loginfrompartial"; 
export const SMS_LOGIN = "/loginfromsms"; 
export const EMAIL_LOGIN = "/loginfromemail"; 
export const AKHAYATRITIYA = "/akhayatritiya"; 
export const OFFLINE_LOGIN = "/offline-profile"; 
export const VIEW_PROFILE_LOGIN = "/"; 

export const COMMUNITY_DETAIL = "/communitysite"; 

export const UPDATE_NOTIFICATION_STATUS = "/updateNotificationStatus"
// family login api
export const FAMAILYCODELOGIN = "/loginFamilyCode"

export const CHANGE_MOBILE_NO = "/changemobilenumber"; 
export const SEND_OTP = "/sendotp"; 
export const OTP_VALIDATION = "/otpvalidation";
export const MOBILE_VERIFICATION = "/mobileverification";
export const MOBILE_VERIFICATION_FRESH = "/mobileinfofresh";
export const REQUEST_AGAIN = "/requestagain";
export const BASIC_DATA = "/basicdata";
export const RESET_PASSWORD = "/resetPassword"
export const MORE_LINK_CONTENT = ""
export const REGISTER = "/register"
export const REGISTER_EMAIL_CHECK = "/emailcheck"
export const REGISTER_PHONE_CHECK = "/phonecheck"
export const VALIDATE_NAME_API = "/validatename"
export const REGISTER_DETAIL = "/registerdetails"
export const CASTE_LIST = "/getcaste"
export const SUB_CASTE_LIST = "/getsubcaste"
export const SUB_CASTE_PROFILE_LIST = "/getprofilesubcaste"
export const STAR_LIST = "/astroinfofresh"
export const RAASI_LIST = "/getraasi"
export const DENOMINATION_LIST = "/getdomain"
export const COUNTRY_LIST= "/getisdcodebycountryid"
export const STATE_LIST = "/getstates"
export const CITY_LIST = "/getcities"
export const PINCODE_LIST = "/getpincode"
export const REGISTER_SUBMIT = "/profiledatainsert"
export const KJBANNER_DETAIL = "/muhuratbanner"



//payment
export const MEMBERSHIP_DETAILS = "/membershipdetails"
export const CALLBACK_DETAIL = "/requestcallback"
export const APPLY_COUPEN = "/applycoupencode"
export const PAY_NOW = "/getpaymentrequestweb"

//FAQ
export const HELP_DATA_DETAIL_BEFORE_LOGIN = "/helpdatasite"
export const HELP_DATA_DETAIL_BEFORE_LOGIN_SUBMIT = "/savehomehelp"

//Conversation
export const MESSAGE_THREAD = "/messagethread"
export const SENT_MESSAGE = "/sentmessage"
export const BLOCK_PROFILE = "/blockprofile"
export const UNBLOCK_PROFILE = "/unblockprofile"

//filter section
export const FILTER_REQUEST = "/searchpagenew"

//book appointment
export const STATE_LIST_APPOINTMENT = "/muhuratbanner"
export const STORE_LIST_APPOINTMENT = "/muhuratstorebystate"
export const BOOK_APPOINTMENT = "/muhuratstoresubmit"

//matching horoscope
export const ASTRO_BALANCE = "/getAstroBalance"
export const HOROSCOPE_MATCH = "/matchingHoroscope"

//searchbyid
export const SEARCH_BY_ID = "/searchbyid"

//explore
export const SEARCH_BY_CITY = "/searchbycity"
export const SEARCH_BY_EDUCATION = "/searchbyeducation"
export const SEARCH_BY_OCCUPATION = "/searchbyoccupation"
export const SEARCH_BY_FILTER = "/searchbyfilter"


export const HELP_SAVE = "/helpsave"

//dynamic pixel
export const DYNAMIC_PIXEL_DATA = "/dynamicpixel"

//search page check
export const SEARCH_PAGE_CHECK_REQUEST = "/searchpageresultset"

//content card onclick track
export const CONTENT_CARD_CLICK_REQUEST = "/updateContentCardstatus"
//store registration
export const STORE_REGISTER_DETAIL = "/getstoredetails"
export const STORE_REGISTER_FORM_SUBMIT = "/storedatainsert"

//dashboard optimization
export const DASHBOARD_MATCHES_LIST_API = "/getweeklyrecomendmatches"
export const DASHBOARD_LEFT_PANEL_API = "/getdashboardcounts"
export const DASHBOARD_PROFILE_DATA_API = "/getprofiledata"


export const CONVERSATION_STATUS = "/getConversationStatus";


//recommended data
export const INITIAL_MATCHES = "/matchesliteapp";

//selected matches
export const SELECTED_MATCHES = "/selectedprofilesloadmore";

//match request
export const MULTI_REQUEST_TYPE = "/multirequesttype"

//profile data
export const PROFILE = "/matchviewprofile";

export const MATCH_SCORE = "/gettwowaymatchscourcesection"

//Chats list
export const CONVERSATION = "/getConversation";

//group Chats list
export const GROUP_CONVERSATION = "/groupConversation";

//send message api
export const CHAT_SEND_MESSAGE = "/sendMessage";

//send message api
export const CHAT_SEND_GROUP_MESSAGE = "/sendGroupMessage";

//send message api
export const CHAT_SEND_CUSTOMER_SERVICE_MESSAGE = "/kmc/sendMessage";

//chat list
export const CHAT_LIST = "/getChatList";
export const GROUP_CHAT_LIST = "/getGroupChatList";

//get bulk request api
export const GET_BULK_REQUEST = "/getBulkRequestChatList"

//search chat list
export const SEARCH_CHAT_LIST = "/searchChatList";

//delete message
export const DELETE_MESSAGE = "/deleteMessage";

//chat media 
export const GET_MEDIA_FILES = "/getMediaFiles"

export const SET_CHAT_MUTE = "/manageMute"


export const VIEW_DOCUMENT = "/viewdocumentliteapp"

export const STAR_MESSAGE = "/starMessage"
export const OFFLINE_PROFILE = "/desktopViewProfile"


//search by name
export const SEARCH_BY_NAME = "/searchbynameliteapp"


//matches data
export const DASHBOARD = "/dashboard";

export const SEARCH_LIST = "/searchpagenew"

//family chat list
export const FAMILIY_CHAT_LIST = "/getGroupChatList";

export const FAMILIY_CHAT_DEATAIL = "/groupConversation";

export const FAMILY_CHAT_REACTIONS = "/groupMessageReaction"

//delete message
export const DELETE_GROUP_MESSAGE = "/deleteGroupMessage";
//family chat start message
export const FAMILY_STAR_MESSAGE = "/starGroupMessage"

export const READ_GROUP_MESSAGE_API = "/read/groupMessage"

// CustomerService Conversation 
export const CUSTOMER_SERVICE_CONVERSATION = "/kmc/getConversation";

export const KMC_CREATE_CONVERSATION = "/kmc/createConversation";

export const CHAT_UNREAD_MSG_COUNT = "/kmc/unreadMessageCount";

export const RANKING_POPUP = "/kmc/conversationRating";

export const UNREAD_MSG_COUNT = "/kmc/readMessage";

//set family code
export const FAMILY_SET_FAMILY_CODE = "/setfamilycode";
export const ACTIVITY_SCORE_LIST = "/activityScoreList"

export const STRORE_ACTIVITY_SCORE_LIST = "/storeActivityScoreList"

export const GET_TOTAL_POINTS = "/getTotalPoints"

export const ACTIVITY_POPUP_COUNT = "/activitypopupcount"

// PROFILE chat send message 
export const PROFILE_CHAT_SEND_MESSAGE = "/profileToChatSendMessage";

// add btn tracking
export const NOTIFICATION_CLICKED_TRACKING = "/notificationclickedtracking";

export const USER_PDF =  "userPdf";


export const GET_CUSTOMER_PROFILE_LIST = "getCustomerProfileList"

